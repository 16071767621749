import React, { useEffect, useState } from 'react';
import { Button, Table, message, Divider, Popconfirm, Input, Icon } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
// utils
import { ApiWithToken } from '../../utils/api';
import { addIdKeyToList } from '../../utils';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
import RolesTags from '../../components/roles-tags';
// interfaces
import { User } from '../../interfaces/user';
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UsersPageProps extends RouteComponentProps {}

export default function UsersPage({ history }: UsersPageProps): JSX.Element {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState('');

  async function getAllUsers(): Promise<any> {
    setLoading(true);
    const query = [`page=${currentPage}`, `pageSize=${pageSize}`];

    if (extraQueries.length > 0) {
      query.push(...extraQueries);
    }

    if (searchText && searchText.length > 0 && searchField.length > 0) {
      query.push(`searchBy=${searchField}`, `searchFor=${searchText}`);
    }

    try {
      const { data } = await ApiWithToken.get('/users?' + query.join('&'));
      const newUsers = addIdKeyToList(data.data);
      setUsers(newUsers);
      setTotalUsers(data.pagination.total);
    } catch (e) {
      message.error('An error occurred fetching the users. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    getAllUsers();
  }, [extraQueries, pageSize, currentPage, searchText, searchField]);

  async function deleteUser(id: string): Promise<any> {
    try {
      const resp = await ApiWithToken.delete(`/users/${id}`);
      if (resp.status === 200) {
        message.success('User was successfully deleted');
        setLoading(true);
        getAllUsers();
      } else {
        message.error('There was an error deleting the user.');
      }
    } catch (e) {
      message.error("You don't have permission to perform this action.");
    }
  }

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any): void {
    setSearchText(selectedKeys[0]);
    setSearchField(dataIndex);
    setCurrentPage(1);
    confirm();
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setCurrentPage(1);
            setSearchField('');
            setSearchText('');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean => {
      if (dataIndex === 'brand.name') {
        return (
          record['brand'] &&
          record['brand']['name']
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      }

      return (
        record[dataIndex] &&
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    },
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const links = [createLink('Users', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Users</h1>
        <Table
          scroll={{ x: 'scroll' }}
          loading={loading}
          onChange={handleSorter}
          pagination={{
            current: currentPage,
            size: pageSize.toString(),
            showTotal: (total: number): string => `${total} users`,
            total: totalUsers,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
            onShowSizeChange: (page, size): void => setPageSize(size),
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              sorter: true,
              ...getColumnSearchProps('name'),
              render: (text, record): JSX.Element => (
                <Link to={`/app/users/view/${record._id}`}>
                  <span>{record.name}</span>
                </Link>
              ),
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
              ...getColumnSearchProps('email'),
            },
            {
              title: 'Phone number',
              dataIndex: 'phone',
              key: 'phone',
              ...getColumnSearchProps('phone'),
            },
            {
              title: 'Roles',
              dataIndex: 'roles',
              key: 'roles',
              render: (roles): JSX.Element => <RolesTags roles={roles} />,
            },
            {
              title: 'Brand',
              dataIndex: 'brand.name',
              key: 'brand.name',
              ...getColumnSearchProps('brand.name'),
              render: (text: string, record: User): JSX.Element | false =>
                record.brand &&
                record.roles.includes('brand') && (
                  <Link to={`/app/brands/view/${record.brand.brandCode}`}>
                    <span>{text}</span>
                  </Link>
                ),
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (text, record): JSX.Element => (
                <span>
                  <Button
                    size="small"
                    icon="edit"
                    onClick={(): void => history.push(`/app/users/edit/${record._id}`)}
                  >
                    Edit
                  </Button>
                  <Divider type="vertical" />
                  <Popconfirm
                    placement="left"
                    title={`Are you sure you want to delete user ${record.name}?`}
                    onConfirm={(): Promise<any> => deleteUser(record._id)}
                  >
                    <Button size="small" icon="delete" type="danger">
                      Delete
                    </Button>
                  </Popconfirm>
                </span>
              ),
            },
          ]}
          dataSource={users}
        />
      </div>
    </>
  );
}
