import React from 'react';
import { Alert, Typography, Form, Upload, Icon, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import QRCode from 'qrcode.react';
// interfaces
import {
  ReplenishmentRequest,
  ReplenishmentShipment,
} from '../../../../interfaces/replenishment-request';
// config
import { env } from '../../../../config/client';

const { Text, Paragraph } = Typography;

interface BillOfLadingFormProps extends FormComponentProps {
  replenishment: ReplenishmentRequest;
  shipment: ReplenishmentShipment;
  shipmentId: string;
}

function BillOfLadingForm(props: BillOfLadingFormProps): JSX.Element | null {
  const {
    form: { getFieldDecorator },
    replenishment,
    shipment,
    shipmentId,
  } = props;

  if (shipment.stampedBillOfLading) {
    return (
      <Alert
        showIcon
        type="success"
        message={
          <Text strong>Thank you for submitting the stamped version of the Bill Of Lading!</Text>
        }
      />
    );
  }

  const uploadPictureURL = `${env.appUrl}/upload-bol-picture/${replenishment._id}/shipment/${shipmentId}`;

  return (
    <>
      <Alert
        type="info"
        showIcon
        message={
          <Text>
            Please upload a photo of the stamped version of the Bill Of Lading once the pickup is
            completed to finish this shipment
          </Text>
        }
      />
      <Paragraph style={{ marginTop: 25 }}>
        <Text strong>Bill of Lading:</Text>
        <br />
        <a target="_blank" rel="noopener noreferrer" href={shipment.billOfLading}>
          {shipment.billOfLading}
        </a>
      </Paragraph>
      <Row style={{ marginTop: 15, minHeight: 200 }} type="flex" align="middle" justify="center">
        <Col xs={24} md={8}>
          <Form>
            <Form.Item label="Upload photos">
              <div className="dropbox">
                {getFieldDecorator('dragger', {
                  rules: [{ required: true }],
                })(
                  <Upload.Dragger
                    name="file"
                    action={`${env.apiUrl}/replenishments/upload-bol-picture/${replenishment._id}/shipment/${shipmentId}`}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click this area to upload photos</p>
                  </Upload.Dragger>,
                )}
              </div>
            </Form.Item>
          </Form>
        </Col>
        <Col
          xs={24}
          md={8}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          OR
        </Col>
        <Col
          xs={24}
          md={8}
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paragraph>Scan this QR code from your phone and upload the photos</Paragraph>
          <QRCode value={uploadPictureURL} size={160} />
          <a style={{ marginTop: 15, marginBottom: 10 }} href={uploadPictureURL}>
            {uploadPictureURL}
          </a>
        </Col>
      </Row>
    </>
  );
}

export default Form.create<BillOfLadingFormProps>({ name: 'bill-of-lading-form' })(
  BillOfLadingForm,
);
