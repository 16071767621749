export interface MarketplaceOptions {
  text: string;
  countryCode: string;
  mwsEndpoint: string;
  marketplaceId: string;
}

export const marketPlaceOptions: MarketplaceOptions[] = [
  {
    text: 'US',
    countryCode: 'US',
    mwsEndpoint: 'https://mws.amazonservices.com',
    marketplaceId: 'ATVPDKIKX0DER',
  },
  {
    text: 'Australia',
    countryCode: 'AU',
    mwsEndpoint: 'https://mws.amazonservices.com.au',
    marketplaceId: 'A39IBJ37TRP1C6',
  },
  {
    text: 'Brazil',
    countryCode: 'BR',
    mwsEndpoint: 'https://mws.amazonservices.com',
    marketplaceId: 'A2Q3Y263D00KWC',
  },
  {
    text: 'Canada',
    countryCode: 'CA',
    mwsEndpoint: 'https://mws.amazonservices.ca',
    marketplaceId: 'A2EUQ1WTGCTBG2',
  },
  {
    text: 'Egypt',
    countryCode: 'EG',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'ARBP9OOSHTCHU',
  },
  {
    text: 'France',
    countryCode: 'FR',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A13V1IB3VIYZZH',
  },
  {
    text: 'Germany',
    countryCode: 'DE',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A1PA6795UKMFR9',
  },
  {
    text: 'India',
    countryCode: 'IN',
    mwsEndpoint: 'https://mws.amazonservices.in',
    marketplaceId: 'A21TJRUUN4KGV',
  },
  {
    text: 'Italy',
    countryCode: 'IT',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'APJ6JRA9NG5V4',
  },
  {
    text: 'Japan',
    countryCode: 'JP',
    mwsEndpoint: 'https://mws.amazonservices.jp',
    marketplaceId: 'A1VC38T7YXB528',
  },
  {
    text: 'Mexico',
    countryCode: 'MX',
    mwsEndpoint: 'https://mws.amazonservices.com.mx',
    marketplaceId: 'A1AM78C64UM0Y8',
  },
  {
    text: 'Netherlands',
    countryCode: 'NL',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A1805IZSGTT6HS',
  },
  {
    text: 'Saudi Arabia',
    countryCode: 'SA',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A17E79C6D8DWNP',
  },
  {
    text: 'Singapore',
    countryCode: 'SG',
    mwsEndpoint: 'https://mws-fe.amazonservices.com',
    marketplaceId: 'A19VAU5U5O7RUS',
  },
  {
    text: 'Spain',
    countryCode: 'ES',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A1RKKUPIHCS9HS',
  },
  {
    text: 'Turkey',
    countryCode: 'TR',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A33AVAJ2PDY3EV',
  },
  {
    text: 'UK',
    countryCode: 'GB',
    mwsEndpoint: 'https://mws-eu.amazonservices.com',
    marketplaceId: 'A1F83G8C2ARO7P',
  },
  {
    text: 'United Arab Emirates (U.A.E.)',
    countryCode: 'AE',
    mwsEndpoint: 'https://mws.amazonservices.ae',
    marketplaceId: 'A2VIGQ35RCS4UG',
  },
];

export interface PageType {
  value: string;
  label: string;
  allowedCountries: string[];
}

export const labelOptions: PageType[] = [
  { value: 'PackageLabel_Plain_Paper', label: '1 per sheet (Letter)', allowedCountries: [] },
  { value: 'PackageLabel_Thermal_NonPCP', label: 'Thermal', allowedCountries: [] },
  {
    value: 'PackageLabel_Letter_2',
    label: '2 per sheet (Letter)',
    allowedCountries: ['US', 'CA'],
  },
  {
    value: 'PackageLabel_A4_2',
    label: '2 per sheet (A4)',
    allowedCountries: ['FR', 'DE', 'IT', 'ES', 'GB'],
  },
  {
    value: 'PackageLabel_A4_4',
    label: '4 per sheet (A4)',
    allowedCountries: ['FR', 'DE', 'IT', 'ES', 'GB'],
  },
  {
    value: 'PackageLabel_Letter_6',
    label: '6 per sheet (Letter)',
    allowedCountries: ['US', 'CA'],
  },
];
