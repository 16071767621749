import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { message } from 'antd';
import { getDataDecrypt } from './encryption';
import { env } from '../config/client';

const Api = axios.create({
  baseURL: env.apiUrl,
});

export const ApiWithToken = (function(): AxiosInstance {
  const access = getDataDecrypt('access');

  if (!access) return Api;

  const { accessToken } = access;

  return axios.create({
    baseURL: env.apiUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
})();

ApiWithToken.interceptors.response.use(
  (response): AxiosResponse => {
    return response;
  },
  (error): any => {
    if (error.response && error.response.status === 401) {
      message.error('You session expired, please log back in.', 3, (): void => {
        localStorage.clear();
        window.location.replace('/');
      });
    }

    return Promise.reject(error);
  },
);

export default Api;
