import React, { useState, useEffect } from 'react';
import { Table, Tag, Input, Button, Icon, message, Modal, Alert, Typography, Form } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { FormComponentProps } from 'antd/lib/form';
// interfaces
import { Email } from '../../interfaces/email';
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';
// utils
import { ApiWithToken } from '../../utils/api';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';

const { Text } = Typography;

function EmailsPage(props: FormComponentProps): JSX.Element {
  const [emails, setEmails] = useState<Email[]>([]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [inputCCs, setInputCCs] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [retryEmail, setRetryEmail] = useState<null | Email>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalEmails, setTotalEmails] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState('');

  const {
    form: { validateFields, getFieldDecorator },
  } = props;

  useEffect((): void => {
    async function fetchEmails(): Promise<void> {
      setLoading(true);
      const query = [`page=${currentPage}`, `pageSize=${pageSize}`];

      if (extraQueries.length > 0) {
        query.push(...extraQueries);
      }

      if (searchText && searchText.length > 0 && searchField.length > 0) {
        query.push(`searchBy=${searchField}`, `searchFor=${searchText}`);
      }

      try {
        const { data } = await ApiWithToken.get('/emails?' + query.join('&'));
        setEmails(data.data);
        setTotalEmails(data.pagination.total);
      } catch (e) {
        message.error('An error occurred fetching the emails. Please try again later!');
      } finally {
        setLoading(false);
      }
    }
    fetchEmails();
  }, [pageSize, currentPage, extraQueries]);

  async function sendEmail(): Promise<void> {
    validateFields(
      async (err, values): Promise<void> => {
        if (err) return;
        setSendingEmail(true);

        const newCCs = values.ccs.filter((cc: string): boolean => cc.trim().length > 0).join(',');

        try {
          const { data } = await ApiWithToken.post(
            `emails/send-email/${retryEmail && retryEmail._id}`,
            {
              cc: newCCs,
            },
          );
          if (data.sent) {
            message.success('The email was successfully sent.');
          }
        } catch (e) {
          message.error('Something went wrong, please try again.');
        } finally {
          setSendingEmail(false);
          setShowRetryModal(false);
        }
      },
    );
  }

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any): void {
    setSearchText(selectedKeys[0]);
    setSearchField(dataIndex);
    setCurrentPage(1);
    confirm();
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setCurrentPage(1);
            setSearchField('');
            setSearchText('');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const links = [createLink('Emails', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Emails</h1>
        <Table
          scroll={{ x: true }}
          loading={loading}
          dataSource={emails}
          columns={[
            {
              title: 'Sent to',
              dataIndex: 'to',
              sorter: true,
              sortDirections: ['descend', 'ascend'],
              ...getColumnSearchProps('to'),
            },
            {
              title: 'CCs',
              width: 200,
              dataIndex: 'cc',
              render: (ccs): JSX.Element => (
                <>
                  {ccs.map(
                    (cc: string, index: number): JSX.Element => (
                      <Tag key={cc + index}>{cc}</Tag>
                    ),
                  )}
                </>
              ),
            },
            {
              title: 'Subject',
              dataIndex: 'subject',
              sorter: (a, b): number => {
                const aStr = a.to;
                const bStr = b.to;
                if (aStr > bStr) {
                  return -1;
                }
                if (bStr > aStr) {
                  return 1;
                }
                return 0;
              },
              sortDirections: ['descend', 'ascend'],
              ...getColumnSearchProps('subject'),
            },
            {
              title: 'Status',
              dataIndex: 'sent',
              render: (sent: boolean, email: Email): JSX.Element =>
                sent ? (
                  <Tag color="green">Sent</Tag>
                ) : (
                  <>
                    <Tag color="red">Not sent</Tag>
                    <Button
                      type="link"
                      size="small"
                      onClick={(): void => {
                        setShowRetryModal(true);
                        setRetryEmail(email);
                        setInputCCs(email.cc.map((cc): string => cc.slice(1, cc.length - 1)));
                      }}
                    >
                      Retry
                    </Button>
                  </>
                ),
              sorter: (a, b): number => {
                return a === b ? 0 : a ? -1 : 1;
              },
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'Created at',
              dataIndex: 'created_at',
              render: (createdAt: number): string =>
                moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
            },
          ]}
          onChange={handleSorter}
          pagination={{
            current: currentPage,
            size: pageSize.toString(),
            showTotal: (total: number): string => `${total} emails`,
            total: totalEmails,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
            onShowSizeChange: (page, size): void => setPageSize(size),
          }}
        />
      </div>
      <Modal
        title="Please correct possible errors in these emails"
        visible={showRetryModal}
        onOk={sendEmail}
        confirmLoading={sendingEmail}
        onCancel={(): void => setShowRetryModal(false)}
        okText="Send"
      >
        {retryEmail !== null && (
          <Form>
            <Alert
              showIcon
              message={
                <Text strong>
                  The most common reason why an email would fail to send is because of one or more
                  of the CC emails are wrong.
                  <br />
                  If all the emails are correct and it still fails, please contact the development
                  team.
                </Text>
              }
            />
            <Alert
              type="warning"
              showIcon
              message={
                <Text strong>If you want to delete a CC, leave blank the corresponding input.</Text>
              }
              style={{ marginBottom: 15, marginTop: 20 }}
            />
            {retryEmail.cc &&
              inputCCs.map(
                (cc, index): JSX.Element => (
                  <Form.Item key={cc} label={`CC ${index + 1}`}>
                    {getFieldDecorator(`ccs[${index}]`, {
                      initialValue: cc,
                      rules: [
                        {
                          type: 'email',
                          message: 'Please enter a valid email',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                ),
              )}
          </Form>
        )}
      </Modal>
    </>
  );
}

export default Form.create({ name: 'retry-form' })(EmailsPage);
