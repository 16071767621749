import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
} from 'antd';
// components
import Spacer from '../../../../components/spacer';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { checkUserBelongsToRoles } from '../../../../utils';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';

const defaultSubjects = [
  'Warning! You may run out of stock soon',
  'Immediate action required! You are at risk of going Out of Stock!',
  'Urgent! You ran out of stock',
];

const { TextArea } = Input;

interface AddCommentProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => any;
}

const checkUserRoles = checkUserBelongsToRoles();

export default function AddComment({
  replenishment,
  fetchReplenishment,
}: AddCommentProps): JSX.Element {
  const [escalate, setEscalate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [customSubject, setCustomSubject] = useState(false);
  const [subject, setSubject] = useState(defaultSubjects[0]);
  const [body, setBody] = useState('');
  const [comment, setComment] = useState('');

  async function submitComment(): Promise<void> {
    setSubmitting(true);
    try {
      await ApiWithToken.post(`/replenishments/${replenishment._id}/add-comment`, {
        comment,
      });
      setBody('');
      setComment('');
      message.success('Your comment was successfully saved!');
      fetchReplenishment();
    } catch (e) {
      message.error('An error occurred submitting your comment. Please try again later;');
    } finally {
      setSubmitting(false);
    }
  }

  function cancelEscalate(): void {
    setEscalate(false);
    setSubject('');
    setBody('');
  }

  async function submitEscalationEmail(): Promise<void> {
    setSubmitting(true);
    try {
      await ApiWithToken.post(`/replenishments/${replenishment._id}/escalation-email`, {
        subject,
        body,
      });
      message.success(
        'Escalation Email Submitted. The reminders for this replenishment were paused',
      );
      cancelEscalate();
      fetchReplenishment();
    } catch (e) {
      message.error('The Escalation Email could not be sent. Please try again.');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div>
      <Modal
        visible={escalate}
        onCancel={cancelEscalate}
        destroyOnClose
        title="Escalation Email"
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={cancelEscalate}>Cancel</Button>
            <Spacer width={10} />
            <Popconfirm
              title={`This email will also be sent to the Escalation CCs in ${replenishment.brand.name}. Are you sure you want to continue?`}
              okText="Yes, I'm sure"
              cancelText="I changed my mind"
              onConfirm={submitEscalationEmail}
            >
              <Button disabled={submitting} loading={submitting} type="primary">
                Submit Escalation Email
              </Button>
            </Popconfirm>
          </div>
        }
      >
        <Alert
          type="warning"
          showIcon
          message={
            <p>
              The information entered in this form will be submitted to this brand&apos;s manager,
              using the Escalation CCs in the brand.
            </p>
          }
        />

        <Spacer height={20} />

        <Form.Item label="Subject" required>
          {customSubject ? (
            <Input onChange={(e): void => setSubject(e.target.value)} autoFocus />
          ) : (
            <>
              <Select
                style={{ width: '100%' }}
                defaultValue={defaultSubjects[0]}
                onChange={(value: string): void => setSubject(value)}
              >
                {defaultSubjects.map(
                  (sub: string): JSX.Element => (
                    <Select.Option key={sub}>{sub}</Select.Option>
                  ),
                )}
              </Select>
            </>
          )}

          <Checkbox
            onChange={(): void => {
              setCustomSubject(!customSubject);
              if (customSubject) {
                setSubject(defaultSubjects[0]);
              }
            }}
          >
            Use a custom subject
          </Checkbox>
        </Form.Item>

        <Form.Item label="Body" required>
          <TextArea onChange={(e): void => setBody(e.target.value)} />
        </Form.Item>
      </Modal>

      <Form.Item>
        <TextArea value={comment} rows={4} onChange={(e): void => setComment(e.target.value)} />
      </Form.Item>
      <div style={{ display: 'flex' }}>
        <Button htmlType="submit" loading={submitting} onClick={submitComment} type="primary">
          Add Comment
        </Button>
        {checkUserRoles(['admin', 'manager']) && (
          <>
            <Spacer width={4} />
            <Tooltip title="This button will display a form to submit an email to this brand's manager, using the brand's Escalation CCs">
              <Button icon="warning" type="link" onClick={(): void => setEscalate(true)}>
                Create Escalation Email
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}
