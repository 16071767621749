import React, { useState, useEffect } from 'react';
import {
  Button,
  Descriptions,
  message,
  Skeleton,
  Row,
  Typography,
  Divider,
  Form,
  Select,
  Tag,
  Table,
} from 'antd';
import { RouteComponentProps } from 'react-router';
// utils
import { ApiWithToken } from '../../../utils/api';
// interfaces
import { StonehengeBrand } from '../../../interfaces/brand';
// components
import BreadcrumbComposer, { createLink } from '../../../components/breadcrumb-composer';
import EditableText from '../../../components/editable-text';
import CreateBrandAddressForm from '../../../components/create-brand-address-form';
import BrandManagersTable from './components/brand-managers-table';
import BrandProductsTable from './components/brand-products-table';
import BrandAddressesTable from './components/brand-addresses-table';
import TagWithInput from '../../../components/tag-with-input';
import BrandContactsTable from './components/brand-contacts-table';
import CreateBrandContactForm from '../../../components/create-brand-contact-form';
import { parseBrandType } from '../../../utils';
import { Link } from 'react-router-dom';

const { Text, Paragraph } = Typography;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BrandViewPageProps extends RouteComponentProps<{ id: string }> {}

export default function BrandViewPage(props: BrandViewPageProps): JSX.Element {
  const {
    match: { params },
  } = props;
  const [brand, setBrand] = useState<StonehengeBrand | null>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [requiresVerificationPhotos, setRequiresVerificationPhotos] = useState('true');
  const [subBrands, setSubBrands] = useState<StonehengeBrand[]>([]);

  async function fetchBrand(): Promise<any> {
    try {
      const { data: newBrand }: { data: StonehengeBrand } = await ApiWithToken.get(
        `/brands/${params.id}`,
      );
      setBrand(newBrand);
      setForm(newBrand);
      setRequiresVerificationPhotos(newBrand.requires_verification_photos.toString());
    } catch (e) {
      console.log(e);
      message.error(
        'An error occurred fetching the brand. You will be redirected to brand list',
        3,
        (): void => props.history.push('/app/brands'),
      );
    }
  }

  async function fetchSubBrands(): Promise<any> {
    try {
      const { data }: { data: StonehengeBrand[] } = await ApiWithToken.get(
        `/brands/${params.id}/sub-brands`,
      );
      setSubBrands(data);
    } catch (e) {
      console.log(e);
      message.error(
        'An error occurred fetching the brand. You will be redirected to brand list',
        3,
        (): void => props.history.push('/app/brands'),
      );
    }
  }

  useEffect((): void => {
    fetchBrand();
    fetchSubBrands();
  }, [params]);

  function handleForm(property: string, value: string | boolean): void {
    setForm({
      ...form,
      [property]: value,
    });
  }

  async function updateBrand(): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.put(`/brands/${brand && brand.brand_code}`, form);
      setBrand(form);
      message.success('Brand updated successfully');
    } catch (e) {
      message.error('An error occurred updating the brand.', 3);
    } finally {
      setLoading(false);
      setEditing(false);
    }
  }

  async function onNewCC(email: string): Promise<void> {
    if (!brand) return;
    let emails = email.split(', ');
    emails = emails.map((newEmail): string => newEmail.trim());

    try {
      const newCCs = [...brand.cc, ...emails];
      await ApiWithToken.put(`/brands/${brand && brand.brand_code}`, {
        cc: newCCs,
      });
      fetchBrand();
      message.success('CC added successfully');
    } catch (e) {
      message.error('An error occurred adding the CC. Please try again');
    }
  }

  async function onDeleteCC(email: string): Promise<void> {
    if (!brand) return;
    try {
      const newCCs = [...brand.cc];
      const ccIndex = newCCs.findIndex((val): boolean => val === email);
      newCCs.splice(ccIndex, 1);
      await ApiWithToken.put(`/brands/${brand && brand.brand_code}`, {
        cc: newCCs,
      });
      fetchBrand();
      message.success('CC removed successfully');
    } catch (e) {
      message.error('An error occurred removing the CC. Please try again');
    }
  }

  async function onNewEscalationCC(email: string): Promise<void> {
    if (!brand) return;
    let emails = email.split(', ');
    emails = emails.map((newEmail): string => newEmail.trim());

    try {
      const newCCs = [...(brand.escalation_ccs || []), ...emails];
      const { data } = await ApiWithToken.put(`/brands/${brand && brand.brand_code}`, {
        escalationCcs: newCCs,
      });
      setBrand(data);
      message.success('Escalation CC added successfully');
    } catch (e) {
      message.error('An error occurred adding the Escalation CC. Please try again');
    }
  }

  async function onDeleteEscalationCC(email: string): Promise<void> {
    if (!brand) return;
    try {
      const newCCs = [...(brand.escalation_ccs || [])];
      const ccIndex = newCCs.findIndex((val): boolean => val === email);
      newCCs.splice(ccIndex, 1);
      const { data } = await ApiWithToken.put(`/brands/${brand && brand.brand_code}`, {
        escalationCcs: newCCs,
      });
      setBrand(data);
      message.success('Escalation CC removed successfully');
    } catch (e) {
      message.error('An error occurred removing the Escalation CC. Please try again');
    }
  }

  let content;

  if (brand) {
    content = (
      <>
        <Row type="flex" justify="space-between">
          <Text style={{ marginBottom: 20 }} strong>
            {brand.name}&apos;s details
          </Text>
          {editing ? (
            <div>
              <Button
                onClick={updateBrand}
                icon="edit"
                style={{ marginRight: 5 }}
                type="primary"
                loading={loading}
              >
                Save
              </Button>
              <Button
                onClick={(): void => {
                  setEditing(false);
                  setForm(brand);
                }}
                icon="edit"
                type="danger"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              <Button
                onClick={(): void => setEditing(!editing)}
                icon="edit"
                size="small"
                style={{ marginRight: 5 }}
              >
                Edit Brand
              </Button>
            </div>
          )}
        </Row>

        <Descriptions bordered size="small" column={2}>
          <Descriptions.Item label="Name" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('name', text)}
              editable={editing}
              value={editing ? form.name : brand.name}
            />
          </Descriptions.Item>

          {brand.parent_brand_code && (
            <Descriptions.Item label="Parent Brand" span={2}>
              <Link to={`/app/brands/view/${brand.parent_brand_code}`}>
                {brand.parent_brand_code}
              </Link>
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Brand Code" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('brand_code', text)}
              editable={false}
              value={brand.brand_code}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Brand Type" span={2}>
            <Tag>{brand.brand_type && parseBrandType(brand.brand_type.name)}</Tag>
          </Descriptions.Item>

          <Descriptions.Item label="Phone" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('phone', text)}
              editable={editing}
              value={editing ? form.phone : brand.phone}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Email" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('email', text)}
              editable={editing}
              value={editing ? form.email : brand.email}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Item Label URL" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('item_label_url', text)}
              editable={editing}
              value={editing ? form.item_label_url : brand.item_label_url}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Website" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('website', text)}
              editable={editing}
              value={editing ? form.website : brand.website}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Requires verification photos" span={2}>
            {editing ? (
              <Select
                value={requiresVerificationPhotos.toString()}
                style={{ width: 200 }}
                onChange={(val: string): void => {
                  setRequiresVerificationPhotos(val);
                  handleForm('requires_verification_photos', val === 'true');
                }}
              >
                <Option key={0} value="true">
                  {'Yes'}
                </Option>
                <Option key={1} value="false">
                  {'No'}
                </Option>
              </Select>
            ) : (
              <span>{brand.requires_verification_photos ? 'Yes' : 'No'}</span>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="CC" span={2}>
            <TagWithInput
              values={brand.cc}
              onNewTag={onNewCC}
              onDeleteTag={onDeleteCC}
              newText="New CC"
              inputType="email"
            />
          </Descriptions.Item>

          <Descriptions.Item label="Escalation CCs" span={2}>
            <TagWithInput
              values={brand.escalation_ccs || []}
              onNewTag={onNewEscalationCC}
              onDeleteTag={onDeleteEscalationCC}
              newText="New Escalation CC"
              inputType="email"
            />
          </Descriptions.Item>

          <Descriptions.Item label="Notes" span={2}>
            <EditableText
              onChangeText={(text): void => handleForm('notes', text)}
              editable={editing}
              value={editing ? form.notes : brand.notes}
            />
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Paragraph strong>Sub brands</Paragraph>
        <br />
        <Table
          scroll={{ x: 'scroll' }}
          loading={loading}
          dataSource={subBrands}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (text: string, record: StonehengeBrand): JSX.Element => (
                <Link to={`/app/brands/view/${record.brand_code}`}>{text}</Link>
              ),
            },
            {
              title: 'Code',
              dataIndex: 'brand_code',
              key: 'brand_code',
            },
            {
              title: 'Brand Type',
              render: (record: StonehengeBrand): JSX.Element => (
                <Tag>{record.brand_type && parseBrandType(record.brand_type.name)}</Tag>
              ),
            },
            {
              title: 'Contact name',
              dataIndex: 'contact_name',
              key: 'contact_name',
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Phone number',
              dataIndex: 'phone',
              key: 'phone',
              sorter: true,
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (text, record): JSX.Element => (
                <span>
                  <Button
                    size="small"
                    icon="eye"
                    onClick={(): void => {
                      // eslint-disable-next-line react/prop-types
                      props.history.push(`/app/brands/view/${record.brand_code}`);
                    }}
                  >
                    View
                  </Button>
                  <Divider type="vertical" />
                </span>
              ),
            },
          ]}
        />

        <Divider />

        <Paragraph strong>Brand addresses</Paragraph>
        <br />
        <Form.Item>
          <CreateBrandAddressForm
            brandCode={brand.brand_code}
            addresses={brand.addresses}
            fetchBrand={fetchBrand}
          />
        </Form.Item>
        <br />
        <BrandAddressesTable brand={brand} fetchBrand={fetchBrand} />

        <Divider />

        <Paragraph strong>Brand contacts</Paragraph>
        <br />
        <Form.Item>
          <CreateBrandContactForm brand={brand} fetchBrand={fetchBrand} />
        </Form.Item>
        <br />
        <BrandContactsTable brand={brand} fetchBrand={fetchBrand} />

        <Divider />

        <BrandManagersTable brand={brand} />

        <Divider />

        <BrandProductsTable brand={brand} subBrands={subBrands} history={props.history} />
      </>
    );
  } else {
    content = <Skeleton active paragraph={{ rows: 6 }} />;
  }

  const links = [
    createLink('Brands', ''),
    createLink('List', 'brands'),
    createLink(brand ? brand.name : '', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>{content}</div>
    </>
  );
}
