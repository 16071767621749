import React, { useState } from 'react';
import { Alert, Button, List, message, Modal, Popconfirm } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
import { ApiWithToken } from '../../../utils/api';

interface RemoveShipmentsModalProps {
  visible: boolean;
  onSuccess: () => void;
  onClose: () => void;
  replenishment: ReplenishmentRequest;
}

function RemoveShipmentsModal({
  visible,
  onClose,
  replenishment,
  onSuccess,
}: RemoveShipmentsModalProps): JSX.Element | null {
  const [loading, setLoading] = useState(false);

  async function deleteShipment(shipmentId: string): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.delete(`/replenishments/${replenishment._id}/shipments/${shipmentId}`);
      onSuccess();
      message.success(`The shipment ${shipmentId} was successfully deleted`);
    } catch (e) {
      message.error('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title="Remove shipments"
      visible={visible}
      onCancel={onClose}
      destroyOnClose={true}
      confirmLoading={loading}
      onOk={onClose}
    >
      <Alert
        type="info"
        showIcon
        message={
          <p>
            Here you can delete any shipment in the current replenishment. Please note that the
            shipments that you remove from the replenishment requests, will be deleted in Seller
            Central as well.
          </p>
        }
      />
      <List>
        {replenishment.shipments ? (
          <List>
            {Object.keys(replenishment.shipments).map(
              (shipmentId): JSX.Element => (
                <List.Item
                  key={`${shipmentId}-delete`}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>{shipmentId}</span>
                  <Popconfirm
                    title="Are you sure you want to delete this shipment?"
                    onConfirm={(): any => deleteShipment(shipmentId)}
                  >
                    <Button type="danger">Delete</Button>
                  </Popconfirm>
                </List.Item>
              ),
            )}
          </List>
        ) : (
          <List.Item>This replenishment does not have shipments to be deleted</List.Item>
        )}
      </List>
    </Modal>
  );
}

export default RemoveShipmentsModal;
