import React from 'react';
import { Shipment } from '../../interfaces/shipment-plan-result';
import { Typography, List, Collapse } from 'antd';

const { Text } = Typography;
const { Panel } = Collapse;

interface DisplayShipmentProductsProps {
  shipment: Shipment;
}

export default function DisplayShipmentProducts({
  shipment,
}: DisplayShipmentProductsProps): JSX.Element {
  return (
    <Collapse
      defaultActiveKey={['']}
      onChange={(): void => {}}
      style={{ marginBottom: 10, marginTop: 10 }}
    >
      <Panel header="Click here to see this shipment's products" key="1">
        <List bordered>
          {shipment.Items.map(
            (item): JSX.Element => (
              <List.Item key={item.SellerSKU}>
                <Text>
                  <Text strong>{item.Quantity}</Text>
                  <Text> units of </Text>
                  <Text>{item.SellerSKU}</Text>
                </Text>
              </List.Item>
            ),
          )}
        </List>
      </Panel>
    </Collapse>
  );
}
