import React, { FormEvent, useEffect, useState } from 'react';
import { Alert, Typography, Button, Form, Row, Tooltip, message, Table, Collapse } from 'antd';
import { FormComponentProps } from 'antd/es/form';
// utils
import { ApiWithToken } from '../../../../utils/api';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import { scrollToTop } from '../';

const { Text } = Typography;
const { Panel } = Collapse;

interface ApproveQuantitiesProps extends FormComponentProps {
  replenishment: ReplenishmentRequest;
  setLoading: Function;
  fetchReplenishment: Function;
}

function ApproveQuantities({
  replenishment,
  setLoading,
  fetchReplenishment,
  form: { validateFields },
}: ApproveQuantitiesProps): JSX.Element {
  const [products, setProducts] = useState<any>([]);

  async function approveRequest(e: FormEvent): Promise<void> {
    e.preventDefault();
    validateFields(
      async (err): Promise<void> => {
        if (err) return;
        setLoading(true);

        try {
          await ApiWithToken.put(`/replenishments/${replenishment && replenishment._id}`, {
            approved: true,
          });
          message.success('Replenishment Request was successfully approved!');
          fetchReplenishment();
          scrollToTop();
        } catch (e) {
          message.error('There was an error approving the request. Please try again later.');
        } finally {
          setLoading(false);
        }
      },
    );
  }

  async function requestAvailability(): Promise<void> {
    try {
      await ApiWithToken.post(
        `replenishments/request-products-availability/${replenishment && replenishment._id}`,
      );
      message.success('Products availability requested!');
    } catch (e) {
      message.error(
        'Something went wrong requesting the availability again. Please try again later.',
      );
    }
  }

  useEffect((): void => {
    setProducts(
      replenishment &&
        replenishment.products.map((product): object => ({
          ...product,
          ...(!product.availableQty &&
            product.availableQty !== 0 && { availableQty: product.requestedQty }),
        })),
    );
  }, [replenishment]);

  const columns: any = [
    {
      title: 'Image',
      dataIndex: 'product_image_url',
      key: 'product_image_url',
      render: (text: string): JSX.Element => (
        <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={text} />
      ),
    },
    { title: 'SKU', dataIndex: 'seller_sku', key: 'sku' },
    {
      title: 'Requested Qty',
      dataIndex: 'requestedQty',
      key: 'requestedQty',
      render: (text: string): JSX.Element => <Text>{text}</Text>,
    },
    {
      title: 'Available Qty',
      key: 'availableQty',
      render: (record: any): JSX.Element => <Text>{record.availableQty}</Text>,
    },
  ];

  return (
    <>
      <Collapse accordion style={{ marginBottom: 16 }}>
        <Panel header="See product's info" key="1">
          <Table className="mt-4" dataSource={products} columns={columns} />
        </Panel>
      </Collapse>
      <Form onSubmit={approveRequest}>
        <Alert
          style={{ marginTop: 10, marginBottom: 10 }}
          type="warning"
          showIcon
          message={
            <Text strong>
              Please note that if you approve these quantities, all products with available quantity
              set to zero will be deleted from this replenishment.
            </Text>
          }
        />
        <Row type="flex" justify="center" className="mt-4">
          <Tooltip title="This will let the brand know we asked them to enter their available quantities again. This is equivalent of 'rejecting' the available quantities they sent.">
            <Button size="large" onClick={requestAvailability} style={{ marginRight: 5 }}>
              Request availability again
            </Button>
          </Tooltip>
          <Button type="primary" size="large" htmlType="submit">
            Approve quantities
          </Button>
        </Row>
      </Form>
    </>
  );
}

export default Form.create<ApproveQuantitiesProps>({ name: 'approve-quantities-form' })(
  ApproveQuantities,
);
