import React, { useState } from 'react';
import { Typography, Icon, Button, message, Alert } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import ShipmentForm from '../../../../components/shipment-form/index';
// utils
import { ApiWithToken } from '../../../../utils/api';

const { Text, Paragraph } = Typography;

interface DisplayShipmentProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
}

export default function DisplayShipments({
  replenishment,
  fetchReplenishment,
}: DisplayShipmentProps): JSX.Element | null {
  const [goingBack, setGoingBack] = useState(false);

  if (!replenishment.shipmentPlan) return null;

  function shipmentWasSubmitted(shipmentId: any): boolean {
    if (!replenishment.shipments) return false;
    return replenishment.shipments[shipmentId] &&
      replenishment.shipments[shipmentId].boxes &&
      replenishment.shipments[shipmentId].boxes.length > 0
      ? true
      : false;
  }

  function parseShipments(newReplenishment: ReplenishmentRequest): any {
    if (!newReplenishment.shipmentPlan) return;

    return newReplenishment.shipmentPlan;
  }

  function getShipments(): any {
    return parseShipments(replenishment);
  }

  async function deleteShipmentPlan(): Promise<void> {
    setGoingBack(true);
    try {
      await ApiWithToken.post(`/replenishments/delete-shipment-plan/${replenishment._id}`);
    } catch (e) {
      message.error('Something went wrong going to the previous step. Please try again later!');
    } finally {
      setGoingBack(false);
    }
  }

  function displayOptionsNotes(): JSX.Element {
    if (replenishment.AreCasesRequired) {
      return (
        <Alert
          showIcon
          message={
            <ul>
              <li>
                <Paragraph>
                  <Text style={{ fontWeight: 'bold' }}>One SKU per box</Text> - If your shipment
                  contains multiple boxes but only one SKU per box, select One SKU per box.
                  Remember, max 45lbs per box!
                </Paragraph>
              </li>
            </ul>
          }
        />
      );
    }
    return (
      <Alert
        showIcon
        message={
          <div>
            <Paragraph>
              - <Text style={{ fontWeight: 'bold' }}>Everything in one box</Text> - If your shipment
              has only one box, select the Everything in one box option. Remember, max 45lbs per
              box!
            </Paragraph>
            <Paragraph>
              - <Text style={{ fontWeight: 'bold' }}>Multiple SKUs per box</Text> - If your shipment
              has More than one SKU per box and 15 or fewer total boxes, select the More than one
              SKU per box option. Remember, max 45lbs per box!
            </Paragraph>
          </div>
        }
      />
    );
  }

  return (
    <>
      {replenishment.dirty !== true && (
        <Button icon="left" onClick={deleteShipmentPlan} disabled={goingBack} loading={goingBack}>
          Go to previous step
        </Button>
      )}

      <div style={{ marginTop: 10, marginBottom: 10 }}>{displayOptionsNotes()}</div>

      {getShipments().map(
        (shipment: any, index: number): JSX.Element => {
          const alreadySubmitted = shipmentWasSubmitted(shipment.ShipmentId);
          return (
            <div key={shipment.ShipmentId} className="mt-4">
              <Text style={{ fontSize: 20 }}>Shipment #{index + 1}</Text>
              {alreadySubmitted && (
                <Icon
                  type="check-circle"
                  style={{ color: 'lightgreen', fontSize: 24, marginLeft: 10 }}
                />
              )}
              {!alreadySubmitted && (
                <ShipmentForm
                  replenishment={replenishment}
                  shipment={shipment}
                  fetchReplenishment={fetchReplenishment}
                />
              )}
            </div>
          );
        },
      )}
    </>
  );
}
