import React, { useEffect, useState } from 'react';
import { Alert, message, Typography } from 'antd';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
// interfaces
import { ReplenishmentRequest } from '../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../utils/api';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
import ReplenishmentSection from './components/replenishment-section';
import Spacer from '../../components/spacer';
import EditReplenishmentProducts from './components/edit-replenishment-products';
import ReplenishmentSettings from './components/replenishment-settings';
import EditShipments from './components/edit-shipments';
import BoxesDisplayTable from '../../components/boxes-display-table';

const { Text } = Typography;

interface ReplenishmentAdminPageProps
  extends RouteComponentProps<{ id: string }>,
    FormComponentProps {}

function ReplenishmentAdminPage(props: ReplenishmentAdminPageProps): JSX.Element {
  const [replenishment, setReplenishment] = useState<ReplenishmentRequest>();
  const [loading, setLoading] = useState(true);

  const {
    match: { params },
  } = props;

  async function fetchReplenishment(replenishmentId?: string): Promise<any> {
    try {
      const { data: newReplenishment }: { data: ReplenishmentRequest } = await ApiWithToken.get(
        `/replenishments/${replenishmentId || params.id}`,
      );
      if (newReplenishment) {
        setReplenishment(newReplenishment);

        setLoading(false);
      } else {
        message.error('An error occurred fetching the replenishment request.');
        props.history.push('/app/replenishment-requests');
      }
    } catch (e) {
      message.error('An error occurred fetching the replenishment request.');
      setLoading(false);
      props.history.push('/app/replenishment-requests');
    }
  }

  useEffect((): void => {
    fetchReplenishment();
  }, []);

  if (!replenishment || loading) {
    return <div>Loading...</div>;
  }

  const links = [
    createLink('Replenishment Requests', ''),
    createLink('List', 'replenishment-requests'),
    createLink(
      replenishment ? replenishment.id : '',
      `replenishment-requests/view/${replenishment && replenishment._id}`,
    ),
    createLink('Replenishment Admin', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <Text style={{ fontSize: 20 }}>Replenishment Admin</Text>
        <Spacer height={20} />
        <Alert
          type="warning"
          message={
            <div>
              <h4>Caution</h4>
              <span>
                The replenishment admin is a super powerful tool and it&apos;s meant to be used for
                special situations. Please use wisely.
              </span>
            </div>
          }
          showIcon
        />

        <Spacer height={20} />

        <div>
          <ReplenishmentSection title="Replenishment products">
            <EditReplenishmentProducts
              replenishment={replenishment}
              fetchReplenishment={fetchReplenishment}
            />
          </ReplenishmentSection>

          <ReplenishmentSection title="Shipments Editor">
            <EditShipments replenishment={replenishment} fetchReplenishment={fetchReplenishment} />
          </ReplenishmentSection>

          {replenishment.shipments && (
            <ReplenishmentSection title="Shipments Boxes">
              <BoxesDisplayTable replenishment={replenishment} />
            </ReplenishmentSection>
          )}

          <ReplenishmentSection title="Settings">
            <ReplenishmentSettings
              replenishment={replenishment}
              fetchReplenishment={fetchReplenishment}
            />
          </ReplenishmentSection>
        </div>
      </div>
    </>
  );
}

export default ReplenishmentAdminPage;
