import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getDataDecrypt } from '../../../utils/encryption';
// utils
import { checkUserBelongsToRoles } from '../../../utils';
// components
import ForbiddenPage from '../../errors/403';

const checkUserRole = checkUserBelongsToRoles();

interface PrivateRouteProps {
  component: React.ElementType;
  exact?: boolean;
  path: string | string[];
  roles?: string[];
}

export default function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { component: Component, roles = [], ...rest } = props;

  const accessData = getDataDecrypt('access');

  const userBelongsToRole = checkUserRole(roles);

  return (
    <Route
      {...rest}
      render={(props): JSX.Element => {
        if (!accessData) {
          return <Redirect to="/login" />;
        }
        if (userBelongsToRole) {
          return <Component {...props} />;
        }
        return <ForbiddenPage />;
      }}
    />
  );
}
