import React from 'react';

interface SpacerProps {
  width?: number;
  height?: number;
}

export default function Spacer({ width = 0, height = 0 }: SpacerProps): JSX.Element {
  return <div style={{ width, height }} />;
}
