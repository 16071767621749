import React from 'react';
import { Row, Col, Typography, Divider, Card, Layout } from 'antd';

// components
import FeedbackModal from '../../components/modals/feedback';
import Login from '../auth/login';
import CommonHeader from '../../components/header';

const { Paragraph } = Typography;
const { Content } = Layout;

export default function HomePage(): JSX.Element {
  return (
    <Layout>
      <Content>
        <CommonHeader />
        <Row type="flex">
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 12, order: 0 }}
            md={14}
            xl={16}
            className="p-4"
          >
            <Card title="PO Portal">
              <Paragraph strong>
                Sunken Stone&apos;s PO Portal guides you through management and documentation of
                your Amazon inventory replenishments
              </Paragraph>
              <Divider orientation="left">Would you like to help improve this tool?</Divider>
              <div className="mb-4">
                <FeedbackModal buttonLabel="Give us your feedback" />
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24, order: 0 }} sm={{ span: 12, order: 1 }} md={10} xl={8}>
            <Login />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
