import React, { useState, useEffect, FormEvent } from 'react';
import {
  Col,
  Descriptions,
  Form,
  Steps,
  Tooltip,
  Input,
  Icon,
  Row,
  Button,
  message,
  Tag,
  Typography,
  Checkbox,
  Select,
} from 'antd';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MessageType } from 'antd/lib/message';
// utils
import { ApiWithToken } from '../../../utils/api';
import { checkUserBelongsToRoles } from '../../../utils';
import roles from '../../../utils/roles';
// components
import BreadcrumbComposer, { createLink } from '../../../components/breadcrumb-composer';
import RolesTags from '../../../components/roles-tags';
// interfaces
import { User } from '../../../interfaces/user';
import { StonehengeBrand } from '../../../interfaces/brand';

const { Step } = Steps;
const { Paragraph, Text } = Typography;
const { CheckableTag } = Tag;
const { Option } = Select;

const checkUserRoles = checkUserBelongsToRoles();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ManageUserPageProps extends RouteComponentProps<{ id: string }>, FormComponentProps {}

function ManageUserPage(props: ManageUserPageProps): JSX.Element {
  const { match } = props;
  const { validateFields, getFieldDecorator } = props.form;

  const idParam = match.params.id;

  const [user, setUser] = useState<User | null>(null);
  const [brands, setBrands] = useState<StonehengeBrand[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disablePassword, setDisablePassword] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState(['user']);
  const [selectedBrand, setSelectedBrand] = useState<any>();

  async function saveUserRoles(): Promise<any> {
    try {
      setLoading(true);
      const userToUse = idParam ? user : userData;

      if (selectedRoles.includes('brand') && !selectedBrand) {
        return message.error("You can't select the brand role without a brand.");
      }

      let brand = null;
      if (selectedBrand) {
        brand =
          selectedBrand &&
          brands.find((brand: StonehengeBrand): boolean => brand.brand_code === selectedBrand);

        setUserData({
          ...userData,
          brand,
          roles: checkUserRoles(['admin']) ? selectedRoles : ['user', 'brand'],
        });
      }

      const { data } = await ApiWithToken.post(`/auth/assign-role/${userToUse && userToUse._id}`, {
        roles: checkUserRoles(['admin']) ? selectedRoles : ['user', 'brand'],
        ...(brand && { brand: { brandCode: brand.brand_code, name: brand.name } }),
      });
      message.success('Roles saved successfully!');
      setUserData(data);
      setCurrentStep(currentStep + 1);
    } catch (e) {
      if (e.response && e.response.data) {
        message.error(e.response.data.message);
      } else {
        message.error('Something went wrong. Please try again!');
      }
    } finally {
      setLoading(false);
    }
  }

  function selectBrand(selected: string): void {
    setSelectedBrand(selected);
  }

  useEffect((): void => {
    async function fetchUser(): Promise<any> {
      try {
        const { data } = await ApiWithToken.get(`/users/${idParam}`);
        setUser(data);
        if (data.brand) {
          setSelectedBrand(data.brand.brandCode);
        }
        setDisablePassword(true);
        setSelectedRoles(data.roles);
      } catch (e) {
        message.error(
          'An error occurred fetching the user. You will be redirected to users list',
          3,
          (): void => props.history.push('/app/users'),
        );
      }
    }
    if (idParam) {
      fetchUser();
    }
  }, [idParam]);

  useEffect((): void => {
    async function fetchBrands(): Promise<any> {
      try {
        const { data } = await ApiWithToken.get('/brands?getSubBrands=true');
        setBrands(data);
      } catch (e) {
        message.error('An error occurred fetching the brands.');
      } finally {
        setLoading(false);
      }
    }
    fetchBrands();
  }, []);

  function saveUser(e: FormEvent): void {
    e.preventDefault();

    validateFields(
      async (err, values): Promise<any> => {
        if (!err) {
          try {
            setLoading(true);
            const urlToUse = user ? `/users/${user && user._id}` : '/auth/register';
            const method = user ? 'put' : 'post';
            const { data } = await ApiWithToken[method](urlToUse, values);
            if (data) {
              setUserData({
                ...(data.user && data.user),
                ...values,
              });
            }
            message.success('User saved successfully!');
            setCurrentStep(currentStep + 1);
          } catch (e) {
            if (e.response && e.response.data) {
              message.error(e.response.data.message);
            } else {
              message.error('Something went wrong. Please try again!');
            }
          } finally {
            setLoading(false);
          }
        }
      },
    );
  }

  function renderStep2(): JSX.Element {
    if (checkUserRoles(['manager'])) {
      return (
        <>
          <Paragraph style={{ fontSize: 18 }}>Select brand</Paragraph>
          <Row className="mt-4">
            <Select
              showSearch
              value={selectedBrand}
              optionFilterProp="children"
              filterOption={(input, option: any): boolean =>
                option.props.children &&
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: 200 }}
              onChange={selectBrand}
            >
              {brands.map(
                (brand: StonehengeBrand): JSX.Element => (
                  <Option key={brand.brand_code} value={brand.brand_code}>
                    {brand.name}
                  </Option>
                ),
              )}
            </Select>
          </Row>
          <Row type="flex" justify="center" className="mt-4">
            <Button type="primary" onClick={saveUserRoles} loading={loading} disabled={loading}>
              Save
            </Button>
          </Row>
        </>
      );
    }
    return (
      <>
        <Paragraph strong style={{ fontSize: 18 }}>
          Click to select roles
        </Paragraph>
        <Row>
          <Col>
            {roles.map((role: string): JSX.Element | null => {
              return (
                <CheckableTag
                  key={role}
                  checked={role === 'user' ? true : selectedRoles.indexOf(role) > -1}
                  onChange={(checked: boolean): void | MessageType => {
                    if (role === 'user') return message.info("User role can't be removed");
                    const nextSelectedRoles = checked
                      ? [...selectedRoles, role]
                      : selectedRoles.filter((r: string): boolean => r !== role);
                    setSelectedRoles(nextSelectedRoles);
                  }}
                >
                  {role}
                </CheckableTag>
              );
            })}
          </Col>
        </Row>
        {selectedRoles.includes('brand') && (
          <Row className="mt-4">
            <Select
              showSearch
              value={selectedBrand}
              optionFilterProp="children"
              filterOption={(input, option: any): boolean =>
                option.props.children &&
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: 200 }}
              onChange={selectBrand}
            >
              {brands.map(
                (brand: StonehengeBrand): JSX.Element => (
                  <Option key={brand.brand_code} value={brand.brand_code}>
                    {brand.name}
                  </Option>
                ),
              )}
            </Select>
          </Row>
        )}
        <Row type="flex" justify="center" className="mt-4">
          <Button type="primary" onClick={saveUserRoles} loading={loading} disabled={loading}>
            Save
          </Button>
        </Row>
      </>
    );
  }

  const action = idParam ? 'Edit' : 'Create';

  const links = [
    createLink('Users', ''),
    createLink('List', 'users'),
    createLink(`${action} user`, ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>Create User</h1>
        <Steps current={currentStep}>
          <Step key="cu-step-1" title="Enter user details" />
          <Step
            key="cu-step-2"
            title={checkUserRoles(['admin']) ? 'Select user roles' : 'Select brand for user'}
          />
          <Step key="cu-step-3" title="Done!" />
        </Steps>
        {currentStep === 0 && (
          <Form onSubmit={saveUser} className="mt-4">
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                initialValue: user && user.name,
                rules: [{ required: true, message: "User's name is required" }],
              })(
                <Input
                  placeholder="Enter user's name"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />,
              )}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                initialValue: user && user.email,
                rules: [{ required: true, type: 'email', message: 'You must enter a valid email' }],
              })(
                <Input
                  placeholder="Enter user's email"
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  suffix={
                    <Tooltip title="Remember to use user's work email">
                      <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  }
                />,
              )}
            </Form.Item>
            {user && (
              <Checkbox
                onChange={(e: CheckboxChangeEvent): void => setDisablePassword(!e.target.checked)}
                checked={!disablePassword}
              >
                I want to update this user&apos;s password
              </Checkbox>
            )}
            <Form.Item label="Password">
              {getFieldDecorator('password', {
                rules: [
                  { required: !user, min: 8, message: 'Password must have at least 8 characters' },
                ],
              })(
                <Input.Password
                  placeholder="Enter password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  disabled={disablePassword}
                />,
              )}
            </Form.Item>
            <Row type="flex" justify="center">
              <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Next
                <Icon type="right" />
              </Button>
            </Row>
          </Form>
        )}
        {currentStep === 1 && <div className="mt-4">{renderStep2()}</div>}
        {currentStep === 2 && (
          <div className="mt-4">
            <Paragraph strong style={{ fontSize: 20 }}>
              User was successfully set up!
            </Paragraph>
            <Paragraph>Find below the created user&apos;s credentials</Paragraph>
            <Descriptions bordered size="small" column={2}>
              <Descriptions.Item label="Name" span={2}>
                <Text>{userData && userData.name}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={2}>
                <Text>{userData && userData.email}</Text>
              </Descriptions.Item>
              {checkUserRoles(['admin']) && (
                <Descriptions.Item label="Roles" span={2}>
                  <RolesTags roles={selectedRoles} />
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
        )}
      </div>
    </>
  );
}

const WrappedCreateUserForm = Form.create({ name: 'create-user' })(ManageUserPage);
export default WrappedCreateUserForm;
