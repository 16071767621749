import React from 'react';
import { Alert, Typography, Row, Button } from 'antd';
// utils
import { getDataDecrypt } from '../../../../utils/encryption';
import { checkUserBelongsToRoles } from '../../../../utils';
// interfaces
import { User } from '../../../../interfaces/user';
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import RemoveReplenishmentProducts from './remove-replenishment-products';
import UnpauseReplenishmentButton from './unpause-replenishment';
import Spacer from '../../../../components/spacer';

const { Text } = Typography;

const checkUserRoles = checkUserBelongsToRoles();

interface PausedReplenishmentContentProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
  history: any;
}

export default function PausedReplenishmentContent({
  replenishment,
  fetchReplenishment,
  history,
}: PausedReplenishmentContentProps): JSX.Element {
  const user: User = getDataDecrypt('user');

  return (
    <>
      {checkUserRoles(['admin', 'manager']) ? (
        <>
          <Alert type="error" showIcon message={<Text strong>{replenishment.lastError}</Text>} />
          <br />
          {checkUserRoles(['admin']) && (
            <RemoveReplenishmentProducts
              replenishment={replenishment}
              fetchReplenishment={fetchReplenishment}
            />
          )}
          <Row type="flex" justify="center">
            {checkUserRoles(['admin', 'manager']) && (
              <div>
                <Button
                  type="primary"
                  icon="monitor"
                  onClick={(): void =>
                    history.push(
                      `/app/replenishment-requests/${replenishment && replenishment._id}/admin`,
                    )
                  }
                >
                  Go to replenishment admin
                </Button>
              </div>
            )}
            <Spacer width={10} />
            <UnpauseReplenishmentButton
              replenishment={replenishment}
              fetchReplenishment={fetchReplenishment}
            />
          </Row>
        </>
      ) : (
        <Alert
          type="info"
          showIcon
          message={
            <Text strong>
              Hi {user.name}! Your replenishment is currently paused while your Inventory Manager
              makes certain your SKUs have no issues entering this order. Thank you for your
              patience!
            </Text>
          }
        />
      )}
    </>
  );
}
