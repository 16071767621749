/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import {
  Button,
  Icon,
  message,
  Row,
  AutoComplete,
  Form,
  Input,
  Select,
  List,
  Typography,
} from 'antd';
import * as SmartyStreetsSdk from 'smartystreets-javascript-sdk';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import * as _ from 'lodash';
// interfaces
import { AddressObject } from '../../interfaces/brand';
import { ApiWithToken } from '../../utils/api';
// components
import Spacer from '../spacer';
// config
import { env } from '../../config/client';
// constants
import { usStatesList } from '../../pages/brands/view/components/brand-addresses-table/us-states-list';

const { Text } = Typography;
const SmartyCore = SmartyStreetsSdk.core;

interface CreateBrandAddressFormProps {
  fetchBrand?: () => Promise<void>;
  addresses: AddressObject[];
  brandCode: string;
}

export default function CreateBrandAddressForm({
  fetchBrand,
  addresses,
  brandCode,
}: CreateBrandAddressFormProps): JSX.Element {
  const [query, setQuery] = useState('');
  const [creating, setCreating] = useState(false);
  const [address, setAddress] = useState<any>(null);
  const [suiteNumber, setSuiteNumber] = useState('');
  const [location, setLocation] = useState('US');
  const [showGoogle, setShowGoogle] = useState(false);
  const [options, setOptions] = useState<SmartyStreetsSdk.usAutocompletePro.Suggestion[]>([]);
  const credentials = new SmartyCore.SharedCredentials(env.smartyAuthId, 'localhost');

  function handleChange(query: any): void {
    setQuery(query);
  }

  function findCity(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): google.maps.GeocoderAddressComponent | null {
    for (const component of addressComponents) {
      if (component.types.includes('locality') && component.types.includes('political')) {
        return component;
      }
    }
    return null;
  }

  function findState(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): google.maps.GeocoderAddressComponent | null {
    for (const component of addressComponents) {
      if (
        component.types.includes('administrative_area_level_1') &&
        component.types.includes('political')
      ) {
        return component;
      }
    }
    return null;
  }

  function findCountry(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): google.maps.GeocoderAddressComponent | null {
    for (const component of addressComponents) {
      if (component.types.includes('country') && component.types.includes('political')) {
        return component;
      }
    }
    return null;
  }

  function findPostalCode(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): google.maps.GeocoderAddressComponent | null {
    for (const component of addressComponents) {
      if (component.types.includes('postal_code')) {
        return component;
      }
    }
    return null;
  }

  async function onSelect(query: any): Promise<void> {
    setQuery(query.description);
    const [place] = await geocodeByPlaceId(query.placeId);

    const { address_components, formatted_address } = place;
    const [streetOne] = formatted_address.split(', ');
    const city = findCity(address_components);
    const state = findState(address_components);
    const country = findCountry(address_components);
    const postalCode = findPostalCode(address_components);

    const addressToSave = {
      street_one: streetOne.trim(),
      street_two: '',
      city: city && city.long_name,
      zip_code: postalCode && postalCode.long_name,
      state: {
        id: 0,
        state_name: state && state.long_name,
        state_code: state && state.short_name,
      },
      country: {
        id: 0,
        country_name: country && country.long_name,
        country_code: country && country.short_name,
        dialing_code: 1,
      },
    };
    setAddress(addressToSave);
  }

  function handleSelectAddress(index: string): void {
    const selected = options[parseInt(index)];

    const stateIndex = usStatesList.findIndex(
      (state): boolean => state.abbreviation === selected.state,
    );

    const addressToSave = {
      street_one: selected.streetLine,
      street_two: '',
      city: selected.city,
      zip_code: selected.zipcode,
      state: {
        id: 0,
        state_name: stateIndex > -1 ? usStatesList[stateIndex].name : selected.state,
        state_code: selected.state,
      },
      country: {
        id: 0,
        country_name: 'United States',
        country_code: 'US',
        dialing_code: 1,
      },
    };
    setAddress(addressToSave);
  }

  async function saveAddress(): Promise<void> {
    if (address === null) {
      message.error('You need to select an address from the list');
      return;
    }

    try {
      const newAddresses = [...addresses];

      const newAddress = { ...address };

      // set suite number
      if (suiteNumber.trim().length > 0) {
        newAddress.street_two = `STE ${suiteNumber}`;
      }

      newAddresses.push(newAddress);

      console.log(brandCode);
      await ApiWithToken.put(`/brands/${brandCode}`, {
        addresses: newAddresses,
      });
      message.success('The address was successfully added');
      fetchBrand && fetchBrand();
      setCreating(false);
      setQuery('');
      setSuiteNumber('');
    } catch (e) {
      message.error('An error occurred saving the address. Please try again later');
    }
  }

  async function fetchLookupsUS(value: string): Promise<void> {
    if (!value) {
      return;
    }

    const client = SmartyCore.buildClient.usAutocompletePro(credentials);
    const Lookup = SmartyStreetsSdk.usAutocompletePro.Lookup;
    let lookup = new Lookup(value);
    lookup.maxResults = 10;

    try {
      const results = await client.send(lookup);
      const filtered = _.uniqBy(results.result, 'streetLine');
      setOptions(filtered);
    } catch (error) {
      console.log('Error fetching addresses: ' + JSON.stringify(error));
    }
  }

  const debouncedFetchUS = _.debounce(fetchLookupsUS, 750);

  if (!creating) {
    return (
      <Button type="link" onClick={(): void => setCreating(true)}>
        <Icon type="solution" />
        Add new address
      </Button>
    );
  }

  function cancelCreateAddress(): void {
    setCreating(false);
    setQuery('');
    setSuiteNumber('');
    setLocation('US');
    setShowGoogle(false);
  }

  return (
    <div style={{ width: 400 }}>
      <Form.Item label="Select location">
        <Select
          style={{ width: 400 }}
          defaultValue={location}
          onSelect={(value: string): void => {
            setLocation(value);

            setShowGoogle(value !== 'US');
          }}
        >
          <Select.Option key="US" value="US">
            US
          </Select.Option>
          <Select.Option key="ALL" value="ALL">
            International
          </Select.Option>
        </Select>
      </Form.Item>
      {showGoogle ? (
        <PlacesAutocomplete value={query} onChange={handleChange}>
          {({ getInputProps, suggestions, loading, getSuggestionItemProps }): JSX.Element => (
            <div>
              <Form.Item>
                <Input
                  {...getInputProps({
                    placeholder: 'Search places...',
                    className: 'location-search-input',
                  })}
                />
              </Form.Item>
              {suggestions.length > 0 && (
                <List
                  bordered
                  size="small"
                  style={{ zIndex: 20, position: 'absolute', top: 150, left: 0, width: 400 }}
                >
                  {loading && <List.Item>Loading...</List.Item>}
                  {suggestions.map(
                    (suggestion): JSX.Element => {
                      return (
                        <List.Item
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                          onClick={(): any => onSelect(suggestion)}
                          style={{ backgroundColor: 'white' }}
                        >
                          <Text style={{ cursor: 'pointer' }}>{suggestion.description}</Text>
                        </List.Item>
                      );
                    },
                  )}
                </List>
              )}
              <Form.Item label="Enter Suite Number">
                <Input
                  style={{ width: 120 }}
                  onChange={(e): void => setSuiteNumber(e.target.value)}
                />
              </Form.Item>
              <Row type="flex" justify="center">
                <Button type="primary" onClick={saveAddress}>
                  Save
                </Button>

                <Spacer width={10} />

                <Button onClick={cancelCreateAddress}>Cancel</Button>
              </Row>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <div>
          <AutoComplete
            style={{ width: 400 }}
            placeholder="Search places..."
            onSelect={(value): void => handleSelectAddress(value.toString())}
            onSearch={debouncedFetchUS}
          >
            {options.map(
              (option, index): JSX.Element => (
                <AutoComplete.Option key={index} value={`${index}`}>
                  {option.streetLine}
                </AutoComplete.Option>
              ),
            )}
          </AutoComplete>
          <Form.Item label="Enter Suite Number">
            <Input style={{ width: 120 }} onChange={(e): void => setSuiteNumber(e.target.value)} />
          </Form.Item>
          <Row type="flex" justify="center">
            <Button type="primary" onClick={saveAddress}>
              Save
            </Button>
            <Spacer width={10} />
            <Button onClick={cancelCreateAddress}>Cancel</Button>
          </Row>
        </div>
      )}
    </div>
  );
}
