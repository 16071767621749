import React from 'react';
import { Layout, Menu, Typography } from 'antd';

import './header.css';

const { Header } = Layout;
const { Text } = Typography;

function CommonHeader(): JSX.Element {
  return (
    <Header>
      <Text className="header-title">PO Portal</Text>
      <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px' }} />
    </Header>
  );
}

export default CommonHeader;
