import React, { useEffect, useState } from 'react';
import { Row, Col, List, Skeleton, Tag, Typography } from 'antd';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
import StatisticCard from './components/statistic-card';
// utils
import { checkUserBelongsToRoles, addIdKeyToList } from '../../utils';
import { ApiWithToken } from '../../utils/api';
// interfaces
import { ReplenishmentRequest } from '../../interfaces/replenishment-request';

const checkUserRole = checkUserBelongsToRoles();
const { Text } = Typography;

export default function DashboardPage(props: RouteComponentProps): JSX.Element {
  const [stats, setStats] = useState<any>({});
  const [replenishments, setReplenishmentRequests] = useState<ReplenishmentRequest[]>([]);
  const [loading, setLoading] = useState(true);

  const { history } = props;

  async function getAllReplenishmentRequests(): Promise<any> {
    setLoading(true);

    try {
      const { data } = await ApiWithToken.get('/replenishments/actionable');
      const replenishments = addIdKeyToList(data);
      setReplenishmentRequests(replenishments);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    async function fetchStatistics(): Promise<void> {
      let newStats: any = {};

      let url = '/dashboard/brand';

      if (checkUserRole(['admin'])) {
        url = '/dashboard/admin';
      } else if (checkUserRole(['manager'])) {
        url = '/dashboard/manager';
      }

      const { data } = await ApiWithToken.get(url);
      // indexes:
      // 0 is users
      // 1 is emails
      // 2 is brands
      // 3 is products
      // 4 is replenishments in progress
      // 5 is completed replenishments
      newStats.users = data[0];
      newStats.emails = data[1];
      newStats.brands = data[2];
      newStats.products = data[3];
      newStats.replenishmentsInProgress = data[4];
      newStats.completedReplenishments = data[5];

      setStats(newStats);
    }

    fetchStatistics();
  }, []);

  useEffect((): void => {
    if (checkUserRole('manager') || checkUserRole('brand')) getAllReplenishmentRequests();
  }, []);

  let content = null;
  if (checkUserRole('manager') || checkUserRole('admin')) {
    content = (
      <Row gutter={16} style={{ marginTop: 40 }}>
        {checkUserRole('admin') && (
          <>
            <Col span={6}>
              <StatisticCard
                history={history}
                title="Emails Sent"
                value={stats.emails}
                moduleLink="emails"
              />
            </Col>
          </>
        )}

        <Col span={6}>
          <StatisticCard
            history={history}
            title="Total Users"
            value={stats.users}
            moduleLink="users"
          />
        </Col>

        <Col span={6}>
          <StatisticCard
            history={history}
            title="Total Brands"
            value={stats.brands}
            moduleLink="brands"
          />
        </Col>

        <Col span={6}>
          <StatisticCard
            history={history}
            title="Total Products"
            value={stats.products}
            moduleLink="products"
          />
        </Col>

        <Col span={6}>
          <StatisticCard
            history={history}
            title="Replenishments In Progress"
            value={stats.replenishmentsInProgress}
            moduleLink="replenishment-requests"
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            history={history}
            title="Completed replenishments"
            value={stats.completedReplenishments}
            moduleLink="replenishment-requests"
          />
        </Col>
      </Row>
    );
  } else {
    content = (
      <Row gutter={16} style={{ marginTop: 40 }}>
        <Col span={6}>
          <StatisticCard
            history={history}
            title="Replenishments In Progress"
            value={stats.replenishmentsInProgress}
            moduleLink="replenishment-requests"
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            history={history}
            title="Completed replenishments"
            value={stats.completedReplenishments}
            moduleLink="replenishment-requests"
          />
        </Col>
      </Row>
    );
  }

  let actionableContent = null;

  if (checkUserRole('manager') || checkUserRole('brand')) {
    actionableContent = (
      <div style={{ padding: 24, background: '#fff' }}>
        <h1>Replenishment Requests Requiring Action</h1>

        <Row gutter={16} style={{ marginTop: 20, paddingLeft: 10, paddingRight: 10 }}>
          <Col span={12}>
            <List
              bordered
              loading={loading}
              itemLayout="horizontal"
              dataSource={replenishments}
              locale={{
                emptyText: checkUserRole('brand') && (
                  <Text>
                    No replenishments require action from you at this time. You will receive an
                    email notification from your Inventory Manager when it is time for you to move
                    to the next step of any open replenishment.
                  </Text>
                ),
              }}
              renderItem={(item): JSX.Element => (
                <List.Item
                  actions={[
                    <Tag key={`${item._id}-action`} color="gold">
                      Action required
                    </Tag>,
                    <Link key={item._id} to={`/app/replenishment-requests/view/${item._id}`}>
                      <span>Go to replenishment</span>
                    </Link>,
                  ]}
                >
                  <Skeleton avatar={false} title={false} loading={false} active>
                    <List.Item.Meta
                      avatar=""
                      title={
                        <Link
                          key={`${item._id}-2`}
                          to={`/app/replenishment-requests/view/${item._id}`}
                        >
                          <span>{item.id}</span>
                        </Link>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }

  const links = [createLink('Home', ''), createLink('Dashboard', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      {actionableContent}
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>Dashboard</h1>

        {content}
      </div>
    </>
  );
}
