import React, { useEffect, useState } from 'react';
import { Button, Table, message, Divider, Input, Icon, Tag } from 'antd';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { RouteComponentProps } from 'react-router';
// utils
import { ApiWithToken } from '../../utils/api';
import { addIdKeyToList, parseBrandType } from '../../utils';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
// styles
import './brands.css';
// interfaces
import { StonehengeBrand } from '../../interfaces/brand';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BrandsPageProps extends RouteComponentProps<{ id: string }> {}

export default function BrandsPage({ history }: BrandsPageProps): JSX.Element {
  const [brands, setBrands] = useState<StonehengeBrand[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalBrands, setTotalBrands] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState<string>('');

  async function getAllBrands(): Promise<any> {
    setLoading(true);

    try {
      const { data } = await ApiWithToken.get('/brands');
      let newBrands = addIdKeyToList(data);

      if (searchText && searchText.length > 0 && searchField.length > 0) {
        newBrands = newBrands.filter((brand: StonehengeBrand): boolean => {
          return (
            // @ts-ignore
            brand[searchField] &&
            // @ts-ignore
            brand[searchField].toLowerCase().includes(searchText.toLowerCase())
          );
        });
      }

      setBrands(newBrands);
      setTotalBrands(newBrands.length);
    } catch (e) {
      message.error('An error occurred fetching the brands. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    getAllBrands();
  }, [currentPage, extraQueries, searchField, searchText, pageSize]);

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any): void {
    setSearchText(selectedKeys[0]);
    setSearchField(dataIndex);
    setCurrentPage(1);
    confirm();
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setCurrentPage(1);
            setSearchField('');
            setSearchText('');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  async function handleSorter(pagination: any, filters: any, sorter: any): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const content = (
    <div>
      <Table
        scroll={{ x: 'scroll' }}
        loading={loading}
        dataSource={brands}
        onChange={handleSorter}
        pagination={{
          current: currentPage,
          size: pageSize.toString(),
          showTotal: (total: number): string => `${total} brands`,
          total: totalBrands,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
          onShowSizeChange: (page, size): void => setPageSize(size),
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: StonehengeBrand): JSX.Element => (
              <Link to={`/app/brands/view/${record.brand_code}`}>{text}</Link>
            ),
            sorter: true,
            ...getColumnSearchProps('name'),
          },
          {
            title: 'Code',
            dataIndex: 'brand_code',
            key: 'brand_code',
            ...getColumnSearchProps('brand_code'),
          },
          {
            title: 'Brand Type',
            render: (record: StonehengeBrand): JSX.Element => (
              <Tag>{record.brand_type && parseBrandType(record.brand_type.name)}</Tag>
            ),
          },
          {
            title: 'Contact name',
            dataIndex: 'contact_name',
            key: 'contact_name',
            sorter: true,
            ...getColumnSearchProps('contact_name'),
          },
          {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            key: 'email',
            ...getColumnSearchProps('email'),
          },
          {
            title: 'Phone number',
            dataIndex: 'phone',
            key: 'phone',
            sorter: true,
            ...getColumnSearchProps('phone'),
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (text, record): JSX.Element => (
              <span>
                <Button
                  size="small"
                  icon="eye"
                  onClick={(): void => history.push(`/app/brands/view/${record.brand_code}`)}
                >
                  View
                </Button>
                <Divider type="vertical" />
              </span>
            ),
          },
        ]}
      />
    </div>
  );

  const links = [createLink('Brands', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Brands</h1>
        {content}
      </div>
    </>
  );
}
