import React from 'react';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import UploadSignedBol from './upload-signed-bol';
import SubmitPalletLabels from './submit-pallet-labels';
import GetPalletLabels from './get-pallet-labels';

interface LTLExtraFieldsProps {
  replenishment: ReplenishmentRequest;
  shipmentId: any;
  fetchReplenishment: Function;
}

export default function LTLExtraFields({
  replenishment,
  shipmentId,
  fetchReplenishment,
}: LTLExtraFieldsProps): JSX.Element {
  const shipment = replenishment.shipments[shipmentId];

  const labelsContent = shipment.pallets ? (
    <GetPalletLabels
      replenishment={replenishment}
      shipmentId={shipmentId}
      fetchReplenishment={fetchReplenishment}
    />
  ) : (
    <SubmitPalletLabels
      replenishment={replenishment}
      shipmentId={shipmentId}
      fetchReplenishment={fetchReplenishment}
    />
  );

  return (
    <div>
      <UploadSignedBol replenishment={replenishment} shipmentId={shipmentId} />

      {labelsContent}
    </div>
  );
}
