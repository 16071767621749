import React from 'react';
import { Collapse, Icon, Alert, Row, Col, Carousel, Popconfirm, Button, message } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../../../utils/api';

const { Panel } = Collapse;

interface DisplayShipmentsPhotosProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: Function;
}

export default function DisplayShipmentsPhotos({
  replenishment,
  fetchReplenishment,
}: DisplayShipmentsPhotosProps): any | null {
  async function approvePhotos(shipmentId: string): Promise<void> {
    try {
      await ApiWithToken.post(
        `/replenishments/approve-photos/${replenishment._id}/shipment/${shipmentId}`,
      );
      message.success(`Shipment ${shipmentId} photos were successfully approved.`);

      fetchReplenishment();
    } catch (e) {
      message.error('There was an error approving the photos. Please try again later');
    }
  }

  async function rejectPhotos(shipmentId: string): Promise<void> {
    try {
      await ApiWithToken.post(
        `/replenishments/reject-photos/${replenishment._id}/shipment/${shipmentId}`,
      );
      message.success(`Shipment ${shipmentId} photos were successfully rejected.`);
      setTimeout((): void => window.location.reload(), 1000);
    } catch (e) {
      message.error('There was an error rejecting the photos. Please try again later');
    }
  }

  return (
    <>
      <Alert
        type="info"
        showIcon
        message="Please look carefully the following pictures sent by the brand and make sure the labels were correctly applied."
        style={{ marginBottom: 20, marginTop: 20 }}
      />
      {Object.keys(replenishment.shipments).map(
        (shipmentId: any): JSX.Element | boolean =>
          !replenishment.shipments[shipmentId].photosApproved && (
            <Collapse
              key={shipmentId}
              bordered={false}
              expandIcon={({ isActive }): JSX.Element => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel header={`Shipment ${shipmentId}`} key={shipmentId}>
                <Row>
                  <Col span={8} offset={8}>
                    <Carousel arrows draggable>
                      {replenishment.shipments[shipmentId].verificationPhotos &&
                        replenishment.shipments[shipmentId].verificationPhotos.map(
                          (pictureURL: string, index: number): JSX.Element => (
                            <div key={pictureURL}>
                              <img src={pictureURL} width="100%" alt={`Box label ${index + 1}`} />
                            </div>
                          ),
                        )}
                    </Carousel>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Button type="primary" onClick={(): Promise<void> => approvePhotos(shipmentId)}>
                    Approve
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to reject these pictures?"
                    onConfirm={(): Promise<void> => rejectPhotos(shipmentId)}
                  >
                    <Button type="danger">Reject</Button>
                  </Popconfirm>
                </Row>
              </Panel>
            </Collapse>
          ),
      )}
    </>
  );
}
