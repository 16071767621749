import React from 'react';
import * as H from 'history';
import { Typography, message, Skeleton, Button, Row, Popconfirm } from 'antd';
// components
import BreadcrumbComposer, { createLink } from '../../../../components/breadcrumb-composer';
import ReplenishmentComments from '../components/replenishment-comments';
import PausedReplenishmentContent from '../components/paused-replenishment-content';
import DisplayCompletedStepsSummary from '../components/display-completed-steps-summary';
import DisplayShipmentSteps from './steps';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { checkUserBelongsToRoles } from '../../../../utils';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import { ReplenishmentSteps } from '../../../../enums/replenishment-request.enums';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ViewReplenishmentRequestPageV2Props {
  replenishment: ReplenishmentRequest | null;
  fetchReplenishment: Function;
  loading: boolean;
  setLoading: Function;
  history: H.History;
}

const { Text } = Typography;

const checkUserRoles = checkUserBelongsToRoles();

export default function ViewReplenishmentRequestPageV2({
  replenishment,
  fetchReplenishment,
  loading,
  setLoading,
  history,
}: ViewReplenishmentRequestPageV2Props): JSX.Element {
  async function deleteReplenishment(): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.delete(`/replenishments/${replenishment && replenishment._id}`);
      message.success('Replenishment request deleted successfully!');
      history.push('/app/replenishment-requests');
    } catch (e) {
      message.error(
        'There was an error deleting the replenishment request. Please try again later',
      );
    } finally {
      setLoading(false);
    }
  }

  let content;

  if (loading && !replenishment) {
    content = <Skeleton active paragraph={{ rows: 6 }} />;
  } else {
    content = (
      <>
        <Row type="flex" justify="space-between">
          <Text style={{ fontSize: 20 }}>
            Replenishment Request {replenishment && replenishment.id}
          </Text>
          {checkUserRoles(['admin', 'manager']) && (
            <div>
              <Button
                type="primary"
                icon="monitor"
                onClick={(): void =>
                  history.push(
                    `/app/replenishment-requests/${replenishment && replenishment._id}/admin`,
                  )
                }
              >
                Go to replenishment admin
              </Button>
            </div>
          )}
          {checkUserRoles(['admin']) && (
            <Popconfirm
              title="Are you sure you want to delete this replenishment request?"
              onConfirm={(): Promise<void> => deleteReplenishment()}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          )}
        </Row>
        <br />

        {replenishment && (
          <DisplayShipmentSteps
            replenishment={replenishment}
            fetchReplenishment={(): Promise<any> => fetchReplenishment()}
            loading={loading}
            setLoading={setLoading}
            history={history}
          />
        )}
      </>
    );
  }

  const links = [
    createLink('Replenishment Requests', ''),
    createLink('List', 'replenishment-requests'),
    createLink(replenishment ? replenishment.id : '', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        {replenishment && replenishment.paused ? (
          <PausedReplenishmentContent
            fetchReplenishment={(): Promise<void> => fetchReplenishment()}
            replenishment={replenishment}
            history={history}
          />
        ) : (
          content
        )}
      </div>

      {replenishment &&
        replenishment.step &&
        replenishment.step > ReplenishmentSteps.ConfirmQuantities && (
          <div style={{ padding: 24, background: '#fff', minHeight: 150, marginTop: 40 }}>
            <DisplayCompletedStepsSummary replenishment={replenishment} />
          </div>
        )}

      {replenishment && (
        <div style={{ padding: 24, background: '#fff', minHeight: 360, marginTop: 40 }}>
          <ReplenishmentComments
            replenishment={replenishment}
            fetchReplenishment={(): Promise<void> => fetchReplenishment()}
          />
        </div>
      )}
    </>
  );
}
