import React, { useState } from 'react';
import { Alert, Button, Card, Form, Input, message, Modal, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/es/form';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../../utils/api';
import Spacer from '../../../components/spacer';

interface AddShipmentPlanModalProps extends FormComponentProps {
  visible: boolean;
  onSuccess: () => void;
  onClose: () => void;
  replenishment: ReplenishmentRequest;
}

interface ShipmentPlanProduct {
  [key: string]: any;

  FulfillmentNetworkSKU: string;
  Quantity: string;
  SellerSKU: string;
}

function AddShipmentPlanModal({
  visible,
  onClose,
  replenishment,
  onSuccess,
  form: { getFieldDecorator, validateFields },
}: AddShipmentPlanModalProps): JSX.Element | null {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ShipmentPlanProduct[]>([]);

  async function handleSave(): Promise<void> {
    validateFields(
      async (err, values): Promise<void> => {
        if (err) return;

        if (!values.Items || values.Items.length === 0) {
          message.error("You can't add a shipment without products");
          return;
        }

        setLoading(true);

        const [Name, AddressLine1, CityStateAndPostal, Country] = values.ShipToAddress.split('\n');
        const [City, StateAndPostal] = CityStateAndPostal.split(', ');
        const [StateOrProvinceCode, PostalCode] = StateAndPostal.split(' ');
        const [CountryCode] = Country.split(' ');

        const newValues = {
          ...values,
          ShipToAddress: {
            Name,
            AddressLine1,
            City,
            StateOrProvinceCode,
            PostalCode,
            CountryCode,
          },
        };

        try {
          await ApiWithToken.post(`/replenishments/${replenishment._id}/shipments`, newValues);

          onSuccess();
          onClose();
        } catch (e) {
          message.error('Something went wrong. Please check your input and try again.');
        } finally {
          setLoading(false);
        }
      },
    );
  }

  function addNewProduct(): void {
    const emptyProduct: ShipmentPlanProduct = {
      FulfillmentNetworkSKU: '',
      Quantity: '',
      SellerSKU: '',
    };

    setProducts([...products, emptyProduct]);
  }

  function removeProduct(index: number): void {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    setProducts(newProducts);
  }

  return (
    <Modal
      title="Add new shipment plan"
      visible={visible}
      onCancel={(): void => {
        setProducts([]);
        onClose();
      }}
      destroyOnClose={true}
      confirmLoading={loading}
      onOk={handleSave}
      width={600}
    >
      <Alert
        type="info"
        showIcon
        message={
          <>
            <h4>Note</h4>
            <p>
              Here you can add a new shipment <b>that already exists in Seller Central</b>. Enter
              its ID in the following form and you&apos;ll be able to edit its values from the
              Shipments Editor.
            </p>
          </>
        }
      />

      <Spacer height={20} />

      <Card style={{ padding: 20 }}>
        <h3>Shipment Info</h3>

        <Form.Item label="Shipment ID">
          {getFieldDecorator('ShipmentId', {
            rules: [{ required: true, message: ' ' }],
            normalize: value => value && value.trim(),
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Shipment Name">
          {getFieldDecorator('ShipmentName', {
            rules: [{ required: true, message: ' ' }],
            normalize: value => value && value.trim(),
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Fulfillment Center ID">
          {getFieldDecorator('FulfillmentCenterId', {
            rules: [{ required: true, message: ' ' }],
            normalize: value => value && value.trim(),
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Label Prep Type">
          {getFieldDecorator('LabelPrepType', { rules: [{ required: true, message: ' ' }] })(
            <Select style={{ width: 300 }}>
              <Select.Option value="SELLER_LABEL">Seller</Select.Option>
              <Select.Option value="AMAZON_LABEL_PREFERRED">Amazon</Select.Option>
            </Select>,
          )}
        </Form.Item>
      </Card>

      <Spacer height={20} />

      <Card style={{ padding: 20 }}>
        <h3>Ship To Address</h3>
        <Form.Item label="Ship To Address Block">
          {getFieldDecorator('ShipToAddress', {
            rules: [{ required: true, message: ' ' }],
          })(<Input.TextArea />)}
        </Form.Item>
      </Card>

      <Spacer height={20} />

      <Card style={{ padding: 20 }}>
        <h3>Products</h3>

        <Alert
          type="info"
          showIcon
          message={
            <>
              <h4>Reminder</h4>
              <p>Use a quantity that is divisible by the case quantity.</p>
            </>
          }
        />

        <Spacer height={20} />

        <Button onClick={addNewProduct}>Add product</Button>
        <Spacer height={10} />
        <Table
          pagination={{
            pageSize: products.length,
          }}
          columns={[
            {
              title: 'Fulfillment Network SKU',
              render: (value, item, index): JSX.Element => (
                <Form.Item>
                  {getFieldDecorator(`Items[${index}].FulfillmentNetworkSKU`, {
                    normalize: value => value && value.trim(),
                  })(<Input />)}
                </Form.Item>
              ),
            },
            {
              title: 'Seller SKU',
              render: (value, item, index): JSX.Element => (
                <Form.Item required>
                  {getFieldDecorator(`Items[${index}].SellerSKU`, {
                    rules: [{ required: true, message: ' ' }],
                    normalize: value => value && value.trim(),
                  })(<Input />)}
                </Form.Item>
              ),
            },
            {
              title: 'Quantity',
              render: (value, item, index): JSX.Element => (
                <Form.Item required>
                  {getFieldDecorator(`Items[${index}].Quantity`, {
                    rules: [{ required: true, message: ' ' }],
                    normalize: value => value && value.trim(),
                  })(<Input />)}
                </Form.Item>
              ),
            },
            {
              title: 'Actions',
              render: (value, item, index): JSX.Element => (
                <Button
                  icon="delete"
                  type="danger"
                  size="small"
                  onClick={(): void => removeProduct(index)}
                >
                  Remove
                </Button>
              ),
            },
          ]}
          dataSource={products}
        />
      </Card>
    </Modal>
  );
}

export default Form.create<AddShipmentPlanModalProps>({ name: 'new-shipment-plan-form' })(
  AddShipmentPlanModal,
);
