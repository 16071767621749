import React from 'react';
import { Statistic, Card } from 'antd';

interface StatisticCardProps {
  title: string;
  value: any;
  moduleLink?: string;
  history?: any;
  children?: React.ReactNode;
}

function StatisticCard(props: StatisticCardProps): JSX.Element {
  const { title, value, moduleLink, history, children } = props;

  return (
    <Card draggable onClick={(): void => moduleLink && history.push(moduleLink)}>
      <Statistic title={title} value={value}></Statistic>
      {children}
    </Card>
  );
}

export default StatisticCard;
