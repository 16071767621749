import React, { useEffect, useState } from 'react';
import { Button, Descriptions, message, Skeleton, Typography, Row, Popconfirm } from 'antd';
import { RouteComponentProps } from 'react-router';
// utils
import { ApiWithToken } from '../../../utils/api';
// components
import BreadcrumbComposer, { createLink } from '../../../components/breadcrumb-composer';
import { User } from '../../../interfaces/user';
import { Link } from 'react-router-dom';

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserPageProps extends RouteComponentProps<{ id: string }> {}

export default function UserPage(props: UserPageProps): JSX.Element {
  const {
    match: { params },
  } = props;
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect((): void => {
    async function fetchUser(): Promise<any> {
      try {
        const { data: newUser } = await ApiWithToken.get(`/users/${params.id}`);
        if (newUser) {
          setUser(newUser);
          setLoading(false);
        } else {
          message.error(
            'An error occurred fetching the user. You will be redirected to user list',
            3,
            (): void => props.history.push('/app/users'),
          );
        }
      } catch (e) {
        message.error(
          'An error occurred fetching the user. You will be redirected to user list',
          3,
          (): void => props.history.push('/app/users'),
        );
        setLoading(false);
      }
    }
    fetchUser();
  }, []);

  async function deleteUser(): Promise<any> {
    try {
      const resp = await ApiWithToken.delete(`/users/${user && user._id}`);
      if (resp.status === 200) {
        message.success('User was successfully deleted', 3, (): void =>
          props.history.push('/app/users'),
        );
      } else {
        message.error('There was an error deleting the user.');
      }
    } catch (e) {
      message.error("You don't have permission to perform this action.");
    }
  }

  let content = null;

  if (loading || !user) {
    content = <Skeleton active paragraph={{ rows: 6 }} />;
  } else {
    content = (
      <Descriptions bordered column={2}>
        <Descriptions.Item label="Name" span={2}>
          <Text>{user.name}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Phone number" span={2}>
          <a href={`tel:${user.phone}`}>{user.phone}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </Descriptions.Item>
        {user.brand && (
          <Descriptions.Item label="Brand" span={2}>
            <Link to={`/app/brands/view/${user.brand.brandCode}`}>
              {user.brand.name} ({user.brand.brandCode})
            </Link>
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  }

  const links = [
    createLink('Users', ''),
    createLink('List', 'users'),
    createLink(user ? user.name : '', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <Row type="flex" justify="space-between">
          {user && (
            <>
              <Text style={{ marginBottom: 20 }}>{user && user.name} account details</Text>
              <div>
                <Button
                  onClick={(): void => props.history.push(`/app/users/edit/${user._id}`)}
                  icon="edit"
                  size="small"
                  style={{ marginRight: 5 }}
                >
                  Edit User
                </Button>
                <Popconfirm
                  placement="left"
                  title="Are you sure you want to delete this user?"
                  onConfirm={deleteUser}
                >
                  <Button size="small" icon="delete" type="danger">
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            </>
          )}
        </Row>
        {content}
      </div>
    </>
  );
}
