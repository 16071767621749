import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Upload, Icon, Row, Col, Button, Result, Alert, Typography, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// env
import { env } from '../../config/client';
// utils
import Api from '../../utils/api';

const { Text } = Typography;

interface UploadLabelsPicturePageProps
  extends RouteComponentProps<{ replenishmentId: string; shipmentId: string }>,
    FormComponentProps {}

function UploadLabelsPicturePage(props: UploadLabelsPicturePageProps): JSX.Element {
  const {
    form: { getFieldDecorator },
  } = props;

  const [isDone, setDone] = useState(false);

  const { replenishmentId, shipmentId } = props.match.params;

  async function submitForApproval(): Promise<void> {
    try {
      await Api.post(
        `/replenishments/${replenishmentId}/request-photos-approval/shipment/${shipmentId}`,
      );
      message.success('Pictures received successfully!');
    } catch (e) {
      message.error(
        'There was an error submitting your photos for approval. Please try again later',
      );
    }
  }

  return (
    <>
      <h1 style={{ textAlign: 'center' }}>Sunken Stone PO Portal</h1>
      {isDone ? (
        <Result status="success" title="Pictures received successfully!" />
      ) : (
        <Row type="flex" justify="center">
          <Col xs={20}>
            <Alert
              style={{ marginBottom: 15 }}
              type="error"
              message={
                <Text strong>
                  ** IMPORTANT ** DO NOT APPLY FBA LABELS ACROSS BOX SEAMS, CAN RESULT IN AMAZON
                  LOSING YOUR INVENTORY
                </Text>
              }
            />
            <Form>
              <Form.Item label="Please upload photos of cartons with FBA labels applied:">
                <div className="dropbox">
                  {getFieldDecorator('dragger', {
                    rules: [{ required: true }],
                  })(
                    <Upload.Dragger
                      name="file"
                      action={`${env.apiUrl}/replenishments/upload-labels-picture/${replenishmentId}/shipment/${shipmentId}`}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">Click this area to upload photos</p>
                    </Upload.Dragger>,
                  )}
                </div>
              </Form.Item>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="primary"
                block
                onClick={(): void => {
                  submitForApproval();
                  setDone(true);
                }}
              >
                Submit for approval
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Form.create<UploadLabelsPicturePageProps>({ name: 'upload-picture' })(
  UploadLabelsPicturePage,
);
