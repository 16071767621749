import React from 'react';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import HazmatTrackingNumbers from './hazmat-tracking-numbers';
import HazmatProNumber from './hazmat-pro-numbers';
import { ShipmentType } from '../../../../enums/replenishment-request.enums';

interface UploadTrackingOrProNumbersProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: Function;
}

export default function UploadTrackingOrProNumbers({
  replenishment,
  fetchReplenishment,
}: UploadTrackingOrProNumbersProps): JSX.Element {
  return (
    <>
      {Object.keys(replenishment.shipments).map((shipmentId: any): JSX.Element | null => {
        const shipment = replenishment.shipments[shipmentId];

        if (
          shipment.isHazmat &&
          shipment.shipmentType === ShipmentType.SP &&
          shipment.submittedTrackingNumbers !== true
        ) {
          return (
            <HazmatTrackingNumbers
              replenishment={replenishment}
              fetchReplenishment={(): Promise<void> => fetchReplenishment()}
              shipmentId={shipmentId}
            />
          );
        } else if (
          shipment.isHazmat &&
          shipment.shipmentType === ShipmentType.LTL &&
          shipment.submittedProNumber !== true
        ) {
          return (
            <HazmatProNumber
              replenishment={replenishment}
              fetchReplenishment={(): Promise<void> => fetchReplenishment()}
              shipmentId={shipmentId}
            />
          );
        }

        return null;
      })}
    </>
  );
}
