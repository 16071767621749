import React, { useState, FormEvent } from 'react';
import { Input, Tag, Icon, Form, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

interface TagWithInputProps extends FormComponentProps {
  values: string[];
  onNewTag: (newValue: string) => any;
  onDeleteTag: (newValue: string) => any;
  newText: string;
  inputType?: string;
}

function TagWithInput({
  values,
  onNewTag,
  onDeleteTag,
  newText,
  inputType = 'text',
  form,
}: TagWithInputProps): JSX.Element {
  const [inputVisible, setInputVisible] = useState(false);

  const { validateFields, getFieldDecorator } = form;

  function submitForm(e?: FormEvent): void {
    e && e.preventDefault();

    validateFields((err, values): void => {
      if (err) return;
      setInputVisible(false);
      onNewTag(values.email);
    });
  }

  return (
    <>
      {values.map(
        (value, index): JSX.Element => (
          <Tag key={value + index} closable onClose={(): any => onDeleteTag(value)}>
            {value}
          </Tag>
        ),
      )}
      {inputVisible && (
        <Form onSubmit={submitForm}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a valid email',
                },
                {
                  validator: (rule, value, callback): void => {
                    const parsedValue: string[] = value.split(', ');

                    if (parsedValue.length === 0) {
                      return callback('Please enter a valid email');
                    }

                    for (const email of parsedValue) {
                      const isEmail = email.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      );

                      if (!isEmail) return callback('One or more emails are invalid');
                    }

                    callback();
                  },
                },
              ],
            })(
              <Input
                suffix={
                  <Tooltip title="Enter one email or a list of emails separated by a comma and a space. e.g. jhon@doe.com, jane@doe.com">
                    <Icon type="info-circle" />
                  </Tooltip>
                }
                autoFocus
                type={inputType}
                size="small"
                style={{ width: 300 }}
                onPressEnter={(): any => submitForm()}
              />,
            )}
          </Form.Item>
        </Form>
      )}
      {!inputVisible && (
        <Tag
          onClick={(): void => setInputVisible(true)}
          style={{ background: '#fff', borderStyle: 'dashed' }}
        >
          <Icon type="plus" /> {newText || 'New Tag'}
        </Tag>
      )}
    </>
  );
}

export default Form.create<TagWithInputProps>({ name: 'tag-with-input' })(TagWithInput);
