import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, message, Table, Input, Icon, Row, Tag } from 'antd';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import Highlighter from 'react-highlight-words';
// components
import BreadcrumbComposer, { createLink } from '../../../components/breadcrumb-composer';
import Spacer from '../../../components/spacer';
// interfaces
import { StonehengeBrand } from '../../../interfaces/brand';
// utils
import { ApiWithToken } from '../../../utils/api';
import { parseBrandType } from '../../../utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ManageReplenishmentRequestPageProps
  extends FormComponentProps,
    RouteComponentProps<{ id: string }> {}

function ManageReplenishmentRequestPage(props: ManageReplenishmentRequestPageProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<StonehengeBrand[]>([]);
  const [displayBrands, setDisplayBrands] = useState<StonehengeBrand[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');

  async function getAllBrands(): Promise<any> {
    setLoading(true);

    try {
      const { data } = await ApiWithToken.get('/brands');
      setBrands(data);
      setDisplayBrands(data);
    } catch (e) {
      message.error('An error occurred fetching the brands. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    getAllBrands();
    setSelectedBrandId('');
  }, []);

  function handleSearch(selectedKeys: any, dataIndex: string): void {
    setSearchText(selectedKeys[0]);
    console.log(selectedKeys);
    if (!selectedKeys[0] || selectedKeys[0] === '') {
      setDisplayBrands(brands);
      return;
    }

    if (dataIndex === 'name') {
      const filtered = brands.filter((brand: StonehengeBrand): boolean => {
        const name = brand.name.toLowerCase();
        return name.includes(selectedKeys[0].toLowerCase());
      });
      setDisplayBrands(filtered);
    } else if (dataIndex === 'brand_code') {
      const filtered = brands.filter((brand: StonehengeBrand): boolean => {
        const code = brand.brand_code.toLowerCase();
        return code.includes(selectedKeys[0].toLowerCase());
      });
      setDisplayBrands(filtered);
    }
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => {
            const value = e.target.value ? [e.target.value] : [];
            setSelectedKeys(value);
            handleSearch(value, dataIndex);
          }}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setSearchText('');
            setDisplayBrands(brands);
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  function handleGoToBrand(): void {
    if (selectedBrandId) {
      props.history.push(`/app/replenishment-requests/create/${selectedBrandId}`);
    }
  }

  const links = [
    createLink('Replenishment Requests', ''),
    createLink('Create Replenishment Request', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>Create Replenishment Request</h1>
        <Table
          rowKey={(record: StonehengeBrand): string => record.brand_code}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKey: any): void => {
              setSelectedBrandId(selectedRowKey);
            },
          }}
          scroll={{ x: 'scroll' }}
          loading={loading}
          dataSource={displayBrands}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              width: 300,
              key: 'name',
              render: (text: string, record: any): JSX.Element => (
                <Link to={`/app/brands/view/${record._id}`}>{text}</Link>
              ),
              sorter: true,
              ...getColumnSearchProps('name'),
            },
            {
              title: 'Brand Code',
              dataIndex: 'brand_code',
              width: 200,
              key: 'brand_code',
              render: (text: string): JSX.Element => <span>{text}</span>,
              sorter: true,
              ...getColumnSearchProps('brand_code'),
            },
            {
              title: 'Brand Type',
              render: (record: StonehengeBrand): JSX.Element => (
                <Tag>{record.brand_type && parseBrandType(record.brand_type.name)}</Tag>
              ),
            },
            {
              title: '',
            },
          ]}
        />
        <Spacer height={20} />
        <Row type="flex" justify="center">
          <Button disabled={selectedBrandId === ''} type="primary" onClick={handleGoToBrand}>
            Create a replenishment request for the selected brand
          </Button>
        </Row>
      </div>
    </>
  );
}

const WrappedCreateBrandForm = Form.create({ name: 'create-brand' })(
  ManageReplenishmentRequestPage,
);
export default WrappedCreateBrandForm;
