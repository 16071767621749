import React from 'react';
import { Collapse, Table, Typography, Icon, Alert, Button } from 'antd';
import moment from 'moment';
// interfaces
import { ReplenishmentRequest, Box } from '../../interfaces/replenishment-request';
// utils
import { checkUserBelongsToRoles } from '../../utils';

const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

const checkuserRoles = checkUserBelongsToRoles();

interface BoxesDisplayTableProps {
  replenishment: ReplenishmentRequest;
}

export default function BoxesDisplayTable({ replenishment }: BoxesDisplayTableProps): JSX.Element {
  return (
    <>
      {Object.keys(replenishment.shipments).map(
        (shipmentId: any): JSX.Element => {
          const shipment = replenishment.shipments[shipmentId];
          return (
            <>
              {replenishment.finished && shipment.isHazmat && checkuserRoles(['admin', 'manager']) && (
                <Alert
                  showIcon
                  type="warning"
                  message={
                    <>
                      <Paragraph strong>
                        This shipment is hazmat and Amazon requires some information to be entered
                        in manually in Seller Central. Please go to this shipment in Seller Central,
                        enter the boxes&apos; weight and dimensions that you&apos;ll find below and
                        click on the &quot;Complete shipment&quot; button.
                      </Paragraph>
                      <Paragraph style={{ fontStyle: 'italic', fontSize: 12 }}>
                        PS: Make sure you&apos;re logged in to Seller Central first.
                      </Paragraph>
                      <Button
                        target="_blank"
                        type="link"
                        href={`https://sellercentral.amazon.com/gp/fba/inbound-shipment-workflow/index.html/ref=ag_fbaisw_name_fbasqs#${shipmentId}/prepare`}
                      >
                        Go to shipment in Seller Central
                      </Button>
                    </>
                  }
                />
              )}
              <Collapse
                key={shipmentId}
                expandIcon={({ isActive }): JSX.Element => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
                style={{ marginTop: 10, marginBottom: 40 }}
              >
                <Panel header={`Boxes of shipment ${shipmentId}`} key={shipmentId}>
                  <Table
                    dataSource={shipment.boxes}
                    columns={[
                      {
                        title: 'Box #',
                        render: (text, record): JSX.Element => (
                          <Text>
                            {shipment.boxes.findIndex((item: Box): boolean => record === item) + 1}
                          </Text>
                        ),
                      },
                      {
                        title: 'Box content',
                        render: (record): JSX.Element => (
                          <>
                            {record.products.map(
                              (product: any): JSX.Element => (
                                <Text key={product.seller_sku}>
                                  {product.qty} units of {product.seller_sku}
                                  {product.expirationDate &&
                                    ` expiring on: ${moment(product.expirationDate).format(
                                      'MM-DD-YYYY',
                                    )}`}{' '}
                                  <br />
                                </Text>
                              ),
                            )}
                          </>
                        ),
                      },
                      {
                        title: 'Weight',
                        dataIndex: 'weight',
                        render: (text): JSX.Element =>
                          text ? <Text>{text} lbs</Text> : <Text>N/A</Text>,
                      },
                      {
                        title: 'Dimensions',
                        render: (record): JSX.Element =>
                          record.dimensions ? (
                            <Text>
                              {record.dimensions.x}x{record.dimensions.y}x{record.dimensions.z}
                            </Text>
                          ) : (
                            <Text>N/A</Text>
                          ),
                      },
                    ]}
                  />
                </Panel>
              </Collapse>
            </>
          );
        },
      )}
    </>
  );
}
