import React from 'react';
import { Typography, Alert, Button, message, Popconfirm } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import Spacer from '../../../../components/spacer';
import { ApiWithToken } from '../../../../utils/api';

const { Paragraph } = Typography;

interface AdditionalStepsProps {
  replenishment: ReplenishmentRequest;
}

export default function AdditionalSteps({ replenishment }: AdditionalStepsProps): JSX.Element {
  async function markReplenishmentCompleted(): Promise<void> {
    try {
      await ApiWithToken.post(`/replenishments/${replenishment._id}/complete`);
    } catch (e) {
      message.error('Something went wrong. Please try again.');
    }
  }

  return (
    <>
      {Object.keys(replenishment.shipments).map(
        (shipmentId: any): JSX.Element => {
          const shipment = replenishment.shipments[shipmentId];
          return (
            <>
              {shipment.isHazmat && (
                <>
                  <Alert
                    showIcon
                    type="warning"
                    message={
                      <>
                        <Paragraph strong>
                          This shipment is hazmat and Amazon requires some information to be entered
                          in manually in Seller Central. Please go to this shipment in Seller
                          Central, enter the boxes&apos; weight and dimensions that you&apos;ll find
                          below and click on the &quot;Complete shipment&quot; button.
                        </Paragraph>
                        <Paragraph style={{ fontStyle: 'italic', fontSize: 12 }}>
                          PS: Make sure you&apos;re logged in to Seller Central first.
                        </Paragraph>
                        <Button
                          target="_blank"
                          type="link"
                          href={`https://sellercentral.amazon.com/gp/fba/inbound-shipment-workflow/index.html/ref=ag_fbaisw_name_fbasqs#${shipmentId}/prepare`}
                        >
                          Go to shipment in Seller Central
                        </Button>
                      </>
                    }
                  />
                  <Spacer height={60} />
                </>
              )}
            </>
          );
        },
      )}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Popconfirm
          title="Are you sure you want to mark this replenishment as finished?"
          onConfirm={markReplenishmentCompleted}
        >
          <Button>Mark replenishment as finished</Button>
        </Popconfirm>
      </div>
    </>
  );
}
