import React, { useState, useEffect } from 'react';
import { Table, message, Typography, Button, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
// interfaces
import { StonehengeBrand } from '../../../../../interfaces/brand';
import { User } from '../../../../../interfaces/user';
// utils
import { ApiWithToken } from '../../../../../utils/api';
import { addIdKeyToList } from '../../../../../utils';

interface BrandManagersTableProps {
  brand: StonehengeBrand;
}

const { Text } = Typography;

export default function BrandManagersTable(props: BrandManagersTableProps): JSX.Element {
  const { brand } = props;

  const [managers, setManagers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  async function fetchManagers(): Promise<void> {
    try {
      const { data } = await ApiWithToken.get(`/users/managers?brandCode=${brand.brand_code}`);
      const managersWithKey = addIdKeyToList(data);
      setManagers(managersWithKey);
    } catch (e) {
      message.error("An error occurred fetching brand's managers");
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    fetchManagers();
  }, [brand]);

  async function removeUserBrand(userId: string): Promise<void> {
    try {
      setLoading(true);
      await ApiWithToken.put(`/users/${userId}`, {
        brand: null,
      });
      message.success('The user access was sucessfully removed');
      fetchManagers();
    } catch (e) {
      message.error(
        "There was an error removing the user's access to the brand. Please try again later.",
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Text strong>Brand Managers</Text>
      <Table
        style={{ marginTop: 20 }}
        loading={loading}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record: User): JSX.Element => (
              <Link to={`/app/users/view/${record._id}`}>
                <span>{text}</span>
              </Link>
            ),
          },
          {
            title: 'Email',
            dataIndex: 'email',
          },
          {
            title: 'Actions',
            render: (record: User): JSX.Element => (
              <Popconfirm
                title="Are you sure you want to remove this user's access to this brand?"
                onConfirm={(): any => removeUserBrand(record._id)}
              >
                <Button size="small" type="danger">
                  Remove
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        dataSource={managers}
      />
    </>
  );
}
