import React from 'react';
import { Redirect } from 'react-router-dom';
// utils
import { getDataDecrypt } from '../../utils/encryption';

// This component checks wether the user is logged in or not
// and redirects them to the home/login or to the app
export default function SplashPage(): JSX.Element {
  const access = getDataDecrypt('access');
  const goToPage = access ? '/app/dashboard' : '/home';
  return <Redirect to={goToPage} />;
}
