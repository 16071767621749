import React, { useEffect, useState } from 'react';
import { message, Skeleton, Descriptions, Divider, Row, Typography, Button, Select } from 'antd';
import { ApiWithToken } from '../../../utils/api';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
// components
import BreadcrumbComposer, { createLink } from '../../../components/breadcrumb-composer';
import EditableText from '../../../components/editable-text';
import Spacer from '../../../components/spacer';
// interfaces
import { StonehengeProduct, MasterCarton, Dimensions } from '../../../interfaces/product';
// utils
import { checkUserBelongsToRoles } from '../../../utils';

const checkuserRoles = checkUserBelongsToRoles();

const { Text } = Typography;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProductViewPageProps extends FormComponentProps, RouteComponentProps<{ id: string }> {}

export default function ProductViewPage(props: ProductViewPageProps): JSX.Element {
  const {
    match: { params },
  } = props;
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editingHazmat, setEditingHazmat] = useState(false);
  const [editingConsumable, setEditingConsumable] = useState(false);
  const [editingIgnore, setEditingIgnore] = useState(false);
  const [masterCartonData, setMasterCartonData] = useState<Partial<MasterCarton>>({
    case_quantity: 0,
  });
  const [dimensions, setDimensions] = useState<Dimensions>({ x: 0, y: 0, z: 0 });
  const [product, setProduct] = useState<StonehengeProduct | null>(null);
  const [hazmat, setHazmat] = useState(product && product.hazmat ? true : false);
  const [productIgnored, setProductIgnored] = useState(product && product.ignore ? true : false);
  const [consumable, setConsumable] = useState(product && product.consumable ? true : false);

  function selectHazmat(value: number): void {
    setHazmat(value === 1 ? true : false);
  }

  function selectIgnore(value: number): void {
    setProductIgnored(value === 1 ? true : false);
  }

  function selectConsumable(value: number): void {
    setConsumable(value === 1 ? true : false);
  }

  async function fetchProduct(): Promise<any> {
    try {
      setLoading(true);
      const { data: newProduct }: { data: StonehengeProduct } = await ApiWithToken.get(
        `/products/${params.id}`,
      );

      const cartonInfo = newProduct.master_carton;

      if (cartonInfo) {
        setMasterCartonData(cartonInfo);
        setDimensions(cartonInfo.dimensions);
      }

      setProduct(newProduct);
      setHazmat(newProduct.hazmat ? true : false);
      setConsumable(newProduct.consumable ? true : false);
      setProductIgnored(newProduct.ignore);
      setLoading(false);
    } catch (e) {
      message.error(
        'An error occurred fetching the product. Please try again later.',
        3,
        (): void => props.history.push('/app/products'),
      );
    }
  }

  useEffect((): void => {
    fetchProduct();
  }, []);

  function handleMasterCarton(property: string, value: string): void {
    setMasterCartonData({
      ...masterCartonData,
      [property]: parseInt(value),
    });
  }

  function handleDimensionChange(property: string, value: string): void {
    setDimensions({
      ...dimensions,
      [property]: parseInt(value),
    });
  }

  async function updateMasterCarton(): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.put(
        `/products/${product && encodeURIComponent(product.seller_sku)}/master-carton`,
        {
          masterCarton: {
            ...masterCartonData,
            dimensions: {
              ...dimensions,
            },
          },
        },
      );
      message.success('Master carton info submitted successfully!');
      fetchProduct();
    } catch (e) {
      message.error('An error occurred updating the product.', 3);
    } finally {
      setLoading(false);
      setEditing(false);
    }
  }

  async function updateProductsHazmat(): Promise<void> {
    try {
      await ApiWithToken.put(
        `/products/${product && encodeURIComponent(product.seller_sku)}/hazmat`,
        {
          hazmat,
        },
      );

      setEditingHazmat(false);

      message.success('The hazmat status was successfully updated');

      fetchProduct();
    } catch (e) {
      //
    }
  }

  async function updateConsumableFlag(): Promise<void> {
    try {
      await ApiWithToken.put(
        `/products/${product && encodeURIComponent(product.seller_sku)}/consumable`,
        {
          consumable,
        },
      );

      setEditingConsumable(false);

      message.success('The consumable status was successfully updated');
      fetchProduct();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  async function updateIgnoredFlag(): Promise<void> {
    try {
      await ApiWithToken.put(
        `/products/${product && encodeURIComponent(product.seller_sku)}/ignore`,
        {
          ignore: productIgnored,
        },
      );

      setEditingIgnore(false);

      message.success('The ignore status was successfully updated');
      fetchProduct();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  let content = null;

  if (loading || !product) {
    content = <Skeleton active paragraph={{ rows: 6 }} />;
  } else {
    content = (
      <>
        <Row style={{ marginBottom: 20 }}>
          <Text strong>{product.seller_sku} details</Text>
        </Row>
        <Descriptions bordered size="small" column={2}>
          <Descriptions.Item label="SKU" span={2}>
            <span>{product.seller_sku}</span>
          </Descriptions.Item>

          <Descriptions.Item label="Consumable" span={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {editingConsumable ? (
                <>
                  <Select
                    showSearch
                    value={consumable ? 1 : 0}
                    style={{ width: 200 }}
                    onChange={selectConsumable}
                  >
                    <Option key={1} value={1}>
                      {'Yes'}
                    </Option>
                    <Option key={0} value={0}>
                      {'No'}
                    </Option>
                  </Select>
                  <Spacer width={8} />
                  <Button type="primary" icon="edit" size="small" onClick={updateConsumableFlag}>
                    Save
                  </Button>
                  <Spacer width={4} />
                  <Button
                    icon="edit"
                    size="small"
                    onClick={(): void => {
                      setEditingConsumable(!editingConsumable);
                      setConsumable(product.consumable ? true : false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Text>{consumable ? 'Yes' : 'No'}</Text>
                  {checkuserRoles(['admin', 'manager']) ? (
                    <>
                      <Spacer width={8} />
                      <Button
                        icon="edit"
                        size="small"
                        onClick={(): void => setEditingConsumable(!editingConsumable)}
                      >
                        Change
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Hazmat" span={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {editingHazmat ? (
                <>
                  <Select
                    showSearch
                    value={hazmat ? 1 : 0}
                    style={{ width: 200 }}
                    onChange={selectHazmat}
                  >
                    <Option key={1} value={1}>
                      {'Yes'}
                    </Option>
                    <Option key={0} value={0}>
                      {'No'}
                    </Option>
                  </Select>
                  <Spacer width={8} />
                  <Button type="primary" icon="edit" size="small" onClick={updateProductsHazmat}>
                    Save
                  </Button>
                  <Spacer width={4} />
                  <Button
                    icon="edit"
                    size="small"
                    onClick={(): void => {
                      setEditingHazmat(!editingHazmat);
                      setHazmat(!!(product && product.hazmat));
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Text>{hazmat ? 'Yes' : 'No'}</Text>
                  {checkuserRoles(['admin']) ? (
                    <>
                      <Spacer width={8} />
                      <Button
                        icon="edit"
                        size="small"
                        onClick={(): void => setEditingHazmat(!editingHazmat)}
                      >
                        Change
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Ignore" span={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {editingIgnore ? (
                <>
                  <Select
                    showSearch
                    value={productIgnored ? 1 : 0}
                    style={{ width: 200 }}
                    onChange={selectIgnore}
                  >
                    <Option key={1} value={1}>
                      {'Yes'}
                    </Option>
                    <Option key={0} value={0}>
                      {'No'}
                    </Option>
                  </Select>
                  <Spacer width={8} />
                  <Button type="primary" icon="edit" size="small" onClick={updateIgnoredFlag}>
                    Save
                  </Button>
                  <Spacer width={4} />
                  <Button
                    icon="edit"
                    size="small"
                    onClick={(): void => {
                      setEditingIgnore(!editingIgnore);
                      setProductIgnored(product && product.ignore);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Text>{productIgnored ? 'Yes' : 'No'}</Text>
                  {checkuserRoles(['admin']) ? (
                    <>
                      <Spacer width={8} />
                      <Button
                        icon="edit"
                        size="small"
                        onClick={(): void => setEditingIgnore(!editingIgnore)}
                      >
                        Change
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Brand">{product.brand_code}</Descriptions.Item>
        </Descriptions>

        <Divider />

        <Row type="flex" justify="space-between" style={{ marginBottom: 20 }}>
          <Text strong>Master Carton</Text>
          {editing ? (
            <div>
              <Button
                onClick={updateMasterCarton}
                icon="edit"
                style={{ marginRight: 5 }}
                type="primary"
                loading={loading}
              >
                Save
              </Button>
              <Button
                onClick={(): void => {
                  setEditing(false);
                  setMasterCartonData(product.master_carton || {});
                }}
                icon="edit"
                type="danger"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              onClick={(): void => setEditing(!editing)}
              icon="edit"
              size="small"
              style={{ marginRight: 5 }}
            >
              Edit Master Carton
            </Button>
          )}
        </Row>

        <Descriptions bordered size="small" column={4}>
          <Descriptions.Item label="Quantity in case" span={2}>
            <EditableText
              value={
                masterCartonData.case_quantity ? masterCartonData.case_quantity.toString() : '0'
              }
              editable={editing}
              onChangeText={(text): void => handleMasterCarton('case_quantity', text)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Weight per box (lb)" span={2}>
            <EditableText
              value={masterCartonData.weight ? masterCartonData.weight.toString() : '0'}
              editable={editing}
              onChangeText={(text): void => handleMasterCarton('weight', text)}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Dimensions (in.)" span={2}>
            <div style={{ display: 'flex', width: '230px' }}>
              <EditableText
                value={dimensions && dimensions.x ? dimensions.x.toString() : '0'}
                editable={editing}
                onChangeText={(text): void => handleDimensionChange('x', text)}
              />
              <Text>x</Text>
              <EditableText
                value={dimensions && dimensions.y ? dimensions.y.toString() : '0'}
                editable={editing}
                onChangeText={(text): void => handleDimensionChange('y', text)}
              />
              <Text>x</Text>
              <EditableText
                value={dimensions && dimensions.z ? dimensions.z.toString() : '0'}
                editable={editing}
                onChangeText={(text): void => handleDimensionChange('z', text)}
              />
            </div>
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  }

  const links = [
    createLink('Products', ''),
    createLink('List', 'products'),
    createLink(product ? product.seller_sku : '', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>{content}</div>
    </>
  );
}
