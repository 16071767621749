import React, { useState } from 'react';
import { message, Switch, Typography } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
// components
import ReplenishmentReminderSwitch from './replenishment-reminder-switch';
import Spacer from '../../../components/spacer';
// api
import { ApiWithToken } from '../../../utils/api';

const { Text } = Typography;

interface ReplenishmentSettingsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => any;
}

function ReplenishmentSettings({
  replenishment,
  fetchReplenishment,
}: ReplenishmentSettingsProps): JSX.Element {
  const [approved, setApproved] = useState(replenishment.approved);
  const [finished, setFinished] = useState(replenishment.finished);
  const [paused, setPaused] = useState(replenishment.paused);
  const [hasLabels, setHasLabels] = useState(replenishment.hasLabels);

  async function toggleReplenishmentProperty(property: string, value: boolean): Promise<void> {
    try {
      await ApiWithToken.put(`/replenishments/${replenishment._id}`, {
        [property]: value,
      });
      fetchReplenishment();

      message.success('Replenishment updated successfully');
    } catch (e) {
      message.error('Something went wrong. Please try again.');
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ReplenishmentReminderSwitch replenishment={replenishment} />

      <Spacer height={10} />

      <div style={{ display: 'flex' }}>
        <Switch
          checked={paused}
          onChange={(checked): void => {
            setPaused(checked);
            toggleReplenishmentProperty('paused', checked);
          }}
        />
        <Spacer width={10} />
        <Text>Replenishment paused</Text>
      </div>

      <Spacer height={10} />

      <div style={{ display: 'flex' }}>
        <Switch
          defaultChecked
          checked={approved}
          onChange={(checked): void => {
            setApproved(checked);
            toggleReplenishmentProperty('approved', checked);
          }}
        />
        <Spacer width={10} />
        <Text>Quantities approved</Text>
      </div>

      <Spacer height={10} />

      <div style={{ display: 'flex' }}>
        <Switch
          checked={hasLabels}
          onChange={(checked): void => {
            setHasLabels(checked);
            toggleReplenishmentProperty('hasLabels', checked);
          }}
        />

        <Spacer width={10} />
        <Text>Brand has labeled products</Text>
      </div>

      <Spacer height={10} />

      <div style={{ display: 'flex' }}>
        <Switch
          checked={finished}
          onChange={(checked): void => {
            setFinished(checked);
            toggleReplenishmentProperty('finished', checked);
          }}
        />

        <Spacer width={10} />
        <Text>Replenishment finished</Text>
      </div>
    </div>
  );
}

export default ReplenishmentSettings;
