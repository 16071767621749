import React, { useState, useEffect } from 'react';
import { Select, Result, Descriptions } from 'antd';
// interfaces
import { ReplenishmentRequest, ShipmentPlan } from '../../interfaces/replenishment-request';
// components
import OneSKUPerBoxTable from './tables/one-sku-per-box';
import EverythingInOneBoxTable from './tables/everything-in-one-box';
import MoreThanOneSKUPerBoxTable from './tables/more-than-one-sku-per-box';
import Spacer from '../spacer';

interface ShipmentFormProps {
  replenishment: ReplenishmentRequest;
  shipment: ShipmentPlan;
  fetchReplenishment: () => void;
}

export default function ShipmentForm({
  replenishment,
  shipment,
  fetchReplenishment,
}: ShipmentFormProps): JSX.Element | null {
  const [selectedForm, setSelectedForm] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [options, setOptions] = useState<{ value: string; text: string }[]>([]);

  useEffect((): void => {
    if (!replenishment) return;
    const options = [
      { value: 'one-sku-per-box', text: 'One SKU per box' },
      { value: 'everything-in-one-box', text: 'Everything in one box' },
      {
        value: 'more-than-one-sku-per-box',
        text: 'More than one SKU per box (Total 15 boxes or less)',
      },
    ];
    if (replenishment.AreCasesRequired) {
      setOptions([options[0]]);
    } else {
      setOptions(options.slice(1));
    }
  }, []);

  if (!replenishment) return null;

  function displaySelectedMethod(): JSX.Element {
    switch (selectedForm) {
      case 'one-sku-per-box':
        return (
          <OneSKUPerBoxTable
            replenishment={replenishment}
            shipment={shipment}
            setSuccess={setSuccess}
            fetchReplenishment={fetchReplenishment}
          />
        );
      case 'everything-in-one-box':
        return (
          <EverythingInOneBoxTable
            replenishment={replenishment}
            shipment={shipment}
            setSuccess={setSuccess}
            fetchReplenishment={fetchReplenishment}
          />
        );
      case 'more-than-one-sku-per-box':
        return (
          <MoreThanOneSKUPerBoxTable
            replenishment={replenishment}
            shipment={shipment}
            setSuccess={setSuccess}
            fetchReplenishment={fetchReplenishment}
          />
        );
      default:
        return <div></div>;
    }
  }

  if (success) {
    return <Result status="success" title="Shipment data successfully saved!" />;
  }

  const address = shipment.ShipToAddress;

  return (
    <div>
      <Descriptions title="Ship To:" layout="vertical" bordered>
        <Descriptions.Item label="Fulfillment Center">{address.Name}</Descriptions.Item>
        <Descriptions.Item label="Address" span={2}>
          {address.AddressLine1}
        </Descriptions.Item>
        <Descriptions.Item label="City and state">
          {address.City}, {address.StateOrProvinceCode}
        </Descriptions.Item>
        <Descriptions.Item label="Postal Code">{address.PostalCode}</Descriptions.Item>
        <Descriptions.Item label="Country Code">{address.CountryCode}</Descriptions.Item>
      </Descriptions>
      <Spacer height={20} />
      <Select
        style={{ width: 300 }}
        onChange={(newValue: string): void => setSelectedForm(newValue)}
      >
        {options.map(
          (option): JSX.Element => (
            <Select.Option key={option.value} value={option.value}>
              {option.text}
            </Select.Option>
          ),
        )}
      </Select>
      {displaySelectedMethod()}
    </div>
  );
}
