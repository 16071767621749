/* eslint-disable @typescript-eslint/camelcase */

import React, { useState } from 'react';
import { Table, Button, Popconfirm, message, Input, Select } from 'antd';
// interfaces
import { AddressObject, StonehengeBrand } from '../../../../../interfaces/brand';
// utils
import { checkUserBelongsToRoles } from '../../../../../utils';
import { ApiWithToken } from '../../../../../utils/api';
// components
import Spacer from '../../../../../components/spacer';
import { usStatesList } from './us-states-list';

interface BrandAddressesTableProps {
  brand: StonehengeBrand;
  fetchBrand: () => Promise<void>;
}

const checkUserRoles = checkUserBelongsToRoles();

export default function BrandAddressesTable(props: BrandAddressesTableProps): JSX.Element | null {
  const emptyAddress: AddressObject = {
    street_one: '',
    street_two: '',
    city: '',
    zip_code: 0,
    state: {
      state_name: '',
      state_code: '',
      country: '',
    },
    country: {
      country_name: '',
      country_code: '',
      dialing_code: 0,
    },
  };

  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [newAddress, setNewAddress] = useState(emptyAddress);
  const [selectedState, setSelectedState] = useState<string | null>(null);

  const { brand, fetchBrand } = props;

  async function deleteAddress(address: AddressObject): Promise<void> {
    try {
      const newAddresses = [...brand.addresses];
      const addressIndex = newAddresses.findIndex(
        (a: AddressObject): boolean => a.street_one === address.street_one,
      );
      newAddresses.splice(addressIndex, 1);
      await ApiWithToken.put(`/brands/${brand.brand_code}`, {
        addresses: newAddresses,
      });
      fetchBrand();
      message.success('The address was successfully deleted.');
    } catch (e) {
      message.error('An error occurred deleting the address');
    }
  }

  function cancelEdit(): void {
    setEditingRow(null);
    setNewAddress(emptyAddress);
    setSelectedState(null);
  }

  async function updateAddress(): Promise<void> {
    if (editingRow === null) return;
    const state = usStatesList.find((state): boolean => state.abbreviation === selectedState);

    try {
      const newAddresses = [...brand.addresses];
      newAddresses[editingRow] = {
        ...newAddress,
        ...(state && {
          state:
            {
              state_code: state.abbreviation,
              state_name: state.name,
            } || {},
        }),
      };

      await ApiWithToken.put(`/brands/${brand.brand_code}`, {
        addresses: newAddresses,
      });
      cancelEdit();
      fetchBrand();
      message.success('The address was successfully updated.');
    } catch (e) {
      //
    }
  }

  function renderEdit(index: number, address: AddressObject): JSX.Element {
    if (editingRow === index) {
      return (
        <div style={{ display: 'flex' }}>
          <Button size="small" type="primary" onClick={updateAddress}>
            Save
          </Button>
          <Spacer width={10} />
          <Button size="small" onClick={cancelEdit}>
            Cancel
          </Button>
        </div>
      );
    }

    function editRow(index: number): void {
      setEditingRow(index);
      setNewAddress(brand.addresses[index]);
    }

    return (
      <div style={{ display: 'flex' }}>
        <Button icon="edit" size="small" onClick={(): void => editRow(index)}>
          Edit
        </Button>
        <Spacer width={5} />
        {checkUserRoles(['admin']) && (
          <Popconfirm
            placement="left"
            title="Are you sure you want to delete this address?"
            onConfirm={(): Promise<any> => deleteAddress(address)}
          >
            <Button size="small" icon="delete" type="danger">
              Delete
            </Button>
          </Popconfirm>
        )}
      </div>
    );
  }

  function setAddressValue(property: string, value: string): void {
    const address = {
      ...newAddress,
      [property]: value,
    };

    setNewAddress(address);
  }

  function selectState(value: string): void {
    setSelectedState(value);
  }

  return (
    <Table
      columns={[
        {
          title: 'Street one',
          dataIndex: 'street_one',
          render: (text, record, index): JSX.Element =>
            index === editingRow ? (
              <Input
                defaultValue={text}
                onChange={(e): void => setAddressValue('street_one', e.target.value.trim())}
              />
            ) : (
              <span>{text}</span>
            ),
        },
        {
          title: 'Suite',
          dataIndex: 'street_two',
          render: (text, record, index): JSX.Element =>
            index === editingRow ? (
              <Input
                defaultValue={text}
                onChange={(e): void => setAddressValue('street_two', e.target.value.trim())}
              />
            ) : (
              <span>{text}</span>
            ),
        },
        {
          title: 'City',
          dataIndex: 'city',
          render: (text, record, index): JSX.Element =>
            index === editingRow ? (
              <Input
                defaultValue={text}
                onChange={(e): void => setAddressValue('city', e.target.value.trim())}
              />
            ) : (
              <span>{text}</span>
            ),
        },
        {
          title: 'Zip Code',
          dataIndex: 'zip_code',
          render: (text, record, index): JSX.Element =>
            index === editingRow ? (
              <Input
                defaultValue={text}
                onChange={(e): void => setAddressValue('zip_code', e.target.value.trim())}
              />
            ) : (
              <span>{text}</span>
            ),
        },
        {
          title: 'State',
          dataIndex: 'state.state_name',
          render: (text, record, index): JSX.Element =>
            index === editingRow ? (
              <Select onChange={selectState} style={{ width: 150 }} defaultValue={text}>
                {usStatesList.map(
                  (state): JSX.Element => (
                    <Select.Option key={state.abbreviation}>{state.name}</Select.Option>
                  ),
                )}
              </Select>
            ) : (
              <span>{text}</span>
            ),
        },
        {
          title: 'Country',
          dataIndex: 'country.country_name',
          render: (text): JSX.Element => <span>{text}</span>,
        },
        {
          title: 'Actions',
          render: (address: AddressObject, record, index: number): JSX.Element =>
            renderEdit(index, address),
        },
      ]}
      dataSource={brand.addresses}
      rowKey="street_one"
    />
  );
}
