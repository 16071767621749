import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Upload, Icon, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// env
import { env } from '../../config/client';

interface UploadBOLPhotoPageProps
  extends RouteComponentProps<{ replenishmentId: string; shipmentId: string }>,
    FormComponentProps {}

function UploadBOLPhotoPage(props: UploadBOLPhotoPageProps): JSX.Element {
  const {
    form: { getFieldDecorator },
  } = props;

  const { replenishmentId, shipmentId } = props.match.params;

  return (
    <>
      <h1 style={{ textAlign: 'center' }}>Sunken Stone PO Portal</h1>
      <Row type="flex" justify="center">
        <Col xs={20}>
          <Form>
            <Form.Item
              label="Please upload a photo of the stamped version of the Bill Of Lading once the pickup is
            completed to finish this shipment:"
            >
              <div className="dropbox">
                {getFieldDecorator('dragger', {
                  rules: [{ required: true }],
                })(
                  <Upload.Dragger
                    name="file"
                    action={`${env.apiUrl}/replenishments/upload-bol-picture/${replenishmentId}/shipment/${shipmentId}`}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click this area to upload photos</p>
                  </Upload.Dragger>,
                )}
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Form.create<UploadBOLPhotoPageProps>({ name: 'upload-picture' })(UploadBOLPhotoPage);
