import React, { useState, useEffect } from 'react';
import { Alert, Typography, Table, Button, message, Input, Collapse, Modal } from 'antd';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { getDataDecrypt } from '../../../../utils/encryption';
import { checkUserBelongsToRoles } from '../../../../utils';
// interfaces
import {
  ReplenishmentProduct,
  ReplenishmentRequest,
} from '../../../../interfaces/replenishment-request';
import ReplenishmentStatusTag from './status-tag';
import { scrollToTop } from '../';

const checkUserRoles = checkUserBelongsToRoles();
const { Text } = Typography;
const { Panel } = Collapse;

interface ConfirmQuantitiesProps {
  setLoading: Function;
  fetchReplenishment: Function;
  loading: boolean;
  replenishment: ReplenishmentRequest | null;
  history: any;
}

const user = getDataDecrypt('user');

export default function ConfirmQuantities({
  replenishment,
  fetchReplenishment,
  loading,
  setLoading,
  history,
}: ConfirmQuantitiesProps): JSX.Element {
  const [editing, setEditing] = useState<any>({});
  const [isConfirmed, setConfirmed] = useState(false);
  const [belongsToBrand, setBelongsToBrand] = useState(false);
  const [products, setProducts] = useState<any>([]);

  async function confirm(productsToSend: ReplenishmentProduct[]): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment && replenishment._id}/available-quantities`,
        {
          products: productsToSend,
        },
      );
      message.success('Request confirmed successfully!');
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('There was an error confirming the request. Please try again later');
    } finally {
      setLoading(false);
    }
  }

  async function confirmRequest(): Promise<void> {
    const productsToSend = products.filter(
      (product: any): boolean => product && product.availableQty && product.availableQty > 0,
    );

    if (productsToSend.length === 0) {
      Modal.confirm({
        title: 'No products',
        content: 'If you do this all the products and the replenishment will be deleted, continue?',
        cancelText: 'Cancel',
        okText: 'Confirm',
        onOk: async (): Promise<void> => {
          await confirm(productsToSend);
          history.replace(`/app/replenishment-requests`);
        },
      });
      return;
    }

    confirm(products);
  }

  function handleChange(sku: string, value: string): void {
    const newProducts = products.map((product: any): object => ({
      ...product,
      ...(sku === product.seller_sku && { availableQty: parseInt(value) }),
    }));

    setProducts(newProducts);
  }

  useEffect((): void => {
    setProducts(
      replenishment &&
        replenishment.products.map((product): object => ({
          ...product,
          ...(!product.availableQty &&
            product.availableQty !== 0 && { availableQty: product.requestedQty }),
        })),
    );

    setConfirmed(
      replenishment
        ? replenishment.products.some((product: any): boolean =>
            product.hasOwnProperty('availableQty'),
          )
        : false,
    );

    if (replenishment) {
      setBelongsToBrand(user.brand ? user.brand.brandCode === replenishment.brand.code : false);
    } else {
      setBelongsToBrand(false);
    }
  }, [replenishment]);

  const columns: any = [
    {
      title: 'Image',
      dataIndex: 'product_image_url',
      key: 'product_image_url',
      render: (text: string): JSX.Element => (
        <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={text} />
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'seller_sku',
      key: 'sku',
      render: (sku: string, product: ReplenishmentProduct): JSX.Element => (
        <a href={`https://www.amazon.com/${sku}/dp/${product.asin}`}>{sku}</a>
      ),
    },
    {
      title: 'Requested Qty',
      dataIndex: 'requestedQty',
      key: 'requestedQty',
      render: (text: string): JSX.Element => <Text>{text}</Text>,
    },
  ];

  const availableQtyColumn = {
    title: 'Available Qty',
    key: 'availableQty',
    render: (record: any): JSX.Element =>
      editing[record.seller_sku] ? (
        <Input
          defaultValue={record.availableQty}
          onChange={(e): void => handleChange(record.seller_sku, e.target.value)}
          style={{ maxWidth: 80, textAlign: 'center' }}
          type="number"
        />
      ) : (
        <Text>{record.availableQty}</Text>
      ),
  };

  if (!isConfirmed && belongsToBrand) {
    columns.push(availableQtyColumn, {
      title: 'Operations',
      render: (record: any): JSX.Element => (
        <>
          <Button
            size="small"
            onClick={(): void =>
              setEditing({ ...editing, [record.seller_sku]: !editing[record.seller_sku] })
            }
            style={{ marginRight: 10 }}
          >
            {editing[record.seller_sku] ? 'Save' : 'Modify Qty'}
          </Button>
          {editing[record.seller_sku] && (
            <Button
              size="small"
              onClick={(): void => {
                handleChange(record.seller_sku, record.requestedQty);
                setEditing({
                  ...editing,
                  [record.seller_sku]: false,
                });
              }}
            >
              Reset
            </Button>
          )}
        </>
      ),
    });
  } else {
    if (isConfirmed) {
      columns.push(availableQtyColumn);
    }
  }

  return checkUserRoles(['admin', 'manager']) ? (
    <>
      <Collapse accordion style={{ marginBottom: 16 }}>
        <Panel header="See product's info" key="1">
          <Table className="mt-4" dataSource={products} columns={columns} />
        </Panel>
      </Collapse>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ReplenishmentStatusTag replenishment={replenishment} />
      </div>
    </>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Alert
        showIcon
        type="warning"
        style={{ marginBottom: 20 }}
        message={
          <Text strong>
            If you want to ship LTL (Less Than Truckload/Full Truckload), please note that only up
            to 8 pallets are allowed per order.
          </Text>
        }
      />
      <Table className="mt-4" dataSource={products} columns={columns} />
      <Button type="primary" size="large" onClick={confirmRequest} loading={loading}>
        Confirm Request
      </Button>
    </div>
  );
}
