import React, { useState, FormEvent, useEffect } from 'react';
import {
  Select,
  Button,
  Form,
  message,
  Row,
  Col,
  Table,
  Input,
  Radio,
  Tooltip,
  Popover,
  Typography,
  Modal,
  Result,
  Icon,
  Alert,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// interfaces
import {
  ReplenishmentRequest,
  ReplenishmentProduct,
} from '../../../../interfaces/replenishment-request';
import { AddressObject, Brand } from '../../../../interfaces/brand';
// utils
import { ApiWithToken } from '../../../../utils/api';
// components
import CreateBrandAddressForm from '../../../../components/create-brand-address-form';
// assets
import individualPackedImg from '../../../../assets/individual-packed-img.png';
import CasePackedImg from '../../../../assets/case-packed-img.png';
import { scrollToTop } from '../';

const { Paragraph, Text } = Typography;

interface ShippingPlanFormProps extends FormComponentProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
  history: any;
}

function ShippingPlanForm({
  replenishment,
  form,
  fetchReplenishment,
  history,
}: ShippingPlanFormProps): JSX.Element {
  const { getFieldDecorator, validateFields, getFieldValue } = form;
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState<AddressObject[]>([]);
  const [brand, setBrand] = useState<Brand>(replenishment.brand);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [brandCode, setBrandCode] = useState(replenishment.brand.code);

  async function fetchBrand(): Promise<any> {
    try {
      const { data: newBrand } = await ApiWithToken.get(`/brands/${replenishment.brand.code}`);
      setBrand(newBrand);
      setAddresses(newBrand.addresses || []);
    } catch (e) {
      setBrand(replenishment.brand);
    }
  }

  useEffect((): void => {
    fetchBrand();
  }, []);

  function compareCaseQtyWithAvailableQty(
    rule: any,
    value: string,
    callback: (result?: string) => any,
    product: ReplenishmentProduct,
  ): void {
    if (!value) return callback('A case quantity must be entered');
    const enteredNumber = parseInt(value);
    if (product.availableQty && enteredNumber > product.availableQty) {
      return callback('Case quantity cannot be greater than the shipping quantity');
    }
    if (product.availableQty && product.availableQty % enteredNumber !== 0) {
      return callback('Case quantity must be divisor of shipping quantity');
    }
    callback();
  }

  async function updateReplenishment(values: any): Promise<void> {
    setLoading(true);
    const address = addresses.find(
      (add: AddressObject): boolean => add.street_one === values.address,
    );

    const isCasePacked = values.packing === 1;

    let newProducts;

    if (isCasePacked) {
      newProducts = replenishment.products.map((product): any => ({
        ...product,
        case_quantity: parseInt(
          values.products[product.seller_sku.replace(/\./g, '_')].case_quantity,
        ),
      }));
    }

    try {
      const response = await ApiWithToken.post(
        `/replenishments/${replenishment._id}/shipment-plan`,
        {
          ...((isCasePacked && { products: newProducts }) || {}),
          AreCasesRequired: isCasePacked,
          shipFrom: address,
        },
      );

      if (response.data.success) {
        message.success('Information stored correctly');
      } else {
        message.error('Sorry, something went wrong.');
      }

      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('There was an error saving the data. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  function submitShippingPlan(e: FormEvent): void {
    e.preventDefault();
    validateFields((err: any, values: any): any => {
      if (err) return;
      updateReplenishment(values);
    });
  }

  async function nuclearDeleteReplenishment(): Promise<void> {
    setDeleting(true);
    try {
      await ApiWithToken.post(`/replenishments/nuclear-delete/${replenishment._id}`);
      setDeleting(false);
      setModalVisible(false);
      message.success('New replenishment request created.');
      history.replace(`/app/replenishment-requests`);
    } catch (e) {
      message.error('This replenishment could not be deleted. Please try again later.');
    }
  }

  const nuclearDeleteContent = (
    <div style={{ width: 200 }}>
      <Paragraph strong>
        If you need to change these numbers in order to enter the case quantity, you can do a
        nuclear delete by clicking the button below
      </Paragraph>
      <Button type="danger" size="small" onClick={(): void => setModalVisible(true)}>
        Nuclear Delete
      </Button>
    </div>
  );

  return (
    <>
      <Alert
        showIcon
        message={
          <div>
            <Paragraph>
              When choosing <Text style={{ fontWeight: 'bold' }}>Individual</Text>, you will be
              directed to either
              <Text style={{ fontWeight: 'bold' }}> Everything in One Box</Text> or
              <Text style={{ fontWeight: 'bold' }}> Multiple SKUs per Box</Text>. Both of which must
              still remain 45lbs and below.
            </Paragraph>
          </div>
        }
      />
      <Form onSubmit={submitShippingPlan}>
        <Row>
          <Col xs={24} md={12}>
            <div>
              <Form.Item label="Packing Type">
                {getFieldDecorator('packing', {
                  rules: [
                    {
                      required: true,
                      message: 'You must select packing type',
                    },
                  ],
                })(
                  <Radio.Group style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Tooltip
                      title="Individual products are single products of varying quantities and
                        conditions"
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Radio value={0}>Individual</Radio>
                        <img src={individualPackedImg} width="100" />
                      </div>
                    </Tooltip>
                    <Tooltip title="Case-packed products are multiples of the same product packaged by the manufacturer, each case containing the same quantity in the same condition">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Radio value={1}>Case-Packed</Radio>
                        <Row>
                          <img src={CasePackedImg} width="100" />
                          <img src={CasePackedImg} width="100" />
                        </Row>
                      </div>
                    </Tooltip>
                  </Radio.Group>,
                )}
              </Form.Item>
            </div>

            <Form.Item label="Where are the products shipped from?">
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: true,
                    message: 'You must select an address',
                  },
                ],
              })(
                <Select style={{ width: 300 }}>
                  {addresses.map((address: AddressObject, index: number): JSX.Element | null => {
                    return (
                      <Select.Option
                        key={address.street_one + index.toString()}
                        value={address.street_one}
                      >
                        {address.street_one}, {address.street_two}. {address.city}{' '}
                        {address.state && address.state.state_code} {address.zip_code}
                      </Select.Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              <CreateBrandAddressForm
                brandCode={brandCode}
                addresses={brand.addresses}
                fetchBrand={fetchBrand}
              />
            </Form.Item>
          </Col>
          <Col xs={24} style={{ paddingTop: 30 }}>
            <Alert type="error" showIcon message="Please note: No more than 200 boxes allowed" />
          </Col>
          <Col xs={24} style={{ paddingTop: 30 }}>
            {getFieldValue('packing') === 1 && (
              <>
                <Table
                  dataSource={replenishment.products}
                  pagination={{ pageSize: replenishment.products.length }}
                  columns={[
                    { title: 'SKU', dataIndex: 'seller_sku' },
                    {
                      title: 'Qty',
                      dataIndex: 'availableQty',
                      render: (text): JSX.Element => (
                        <>
                          <Text style={{ marginRight: 5 }}>{text}</Text>
                          <Popover title="Nuclear delete" content={nuclearDeleteContent}>
                            <Icon type="info-circle" />
                          </Popover>
                        </>
                      ),
                    },
                    {
                      title: 'Qty in case',
                      render: (product: ReplenishmentProduct): JSX.Element => {
                        console.log({ product });
                        return (
                          <Form.Item>
                            {getFieldDecorator(
                              `products.${product.seller_sku.replace(/\./g, '_')}.case_quantity`,
                              {
                                initialValue:
                                  product.master_carton && product.master_carton.case_quantity,
                                rules: [
                                  {
                                    validator: (rule, value, callback): void =>
                                      compareCaseQtyWithAvailableQty(
                                        rule,
                                        value,
                                        callback,
                                        product,
                                      ),
                                  },
                                  {
                                    validator: (rule, value, callback): void => {
                                      if (parseInt(value) > 150) {
                                        return callback(
                                          'Quantity in case cannot be greater than 150',
                                        );
                                      }
                                      callback();
                                    },
                                  },
                                ],
                              },
                            )(
                              <Input
                                type="numeric"
                                max={product.availableQty && product.availableQty.toString()}
                              />,
                            )}
                          </Form.Item>
                        );
                      },
                    },
                  ]}
                />
              </>
            )}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Button htmlType="submit" type="primary" loading={loading} size="large">
            Submit
          </Button>
        </Row>
      </Form>
      <Modal
        title="Confirm nuclear delete"
        visible={modalVisible}
        okType="danger"
        okText="Nuclear Delete Replenishment"
        onOk={nuclearDeleteReplenishment}
        confirmLoading={deleting}
        onCancel={(): void => setModalVisible(false)}
      >
        <>
          <Result status="warning" title="WARNING!" />
          <Paragraph>
            You have selected to Delete the current shipment. You will be directed to the start of a
            new replenishment to enter in a new available quantity. You will lose all data from this
            current request including the comments.
          </Paragraph>
        </>
      </Modal>
    </>
  );
}

export default Form.create<ShippingPlanFormProps>({ name: 'shipping-form' })(ShippingPlanForm);
