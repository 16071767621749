import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

interface ReplenishmentSectionProps {
  children: JSX.Element;
  title: string;
}

export default function ReplenishmentSection(props: ReplenishmentSectionProps): JSX.Element {
  const { children, title } = props;

  return (
    <div>
      <Title style={{ fontSize: 18 }}>{title}</Title>
      <div style={{ padding: 15, paddingTop: 0 }}>{children}</div>
    </div>
  );
}
