import React from 'react';
import { Alert, List, Spin, Typography } from 'antd';
/// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import Spacer from '../../../../components/spacer';

const { Text } = Typography;

interface DisplayManagersLastStepProps {
  replenishment: ReplenishmentRequest;
}

export default function DisplayManagersLastStep({
  replenishment,
}: DisplayManagersLastStepProps): JSX.Element {
  return (
    <>
      {replenishment.finished ? (
        <>
          <Alert
            type="success"
            style={{ marginTop: 20 }}
            showIcon
            message="This replenishment request was finished successfully."
          />

          <Spacer height={20} />

          {replenishment.shipments &&
            Object.keys(replenishment.shipments).map(
              (shipmentId: string): JSX.Element => {
                const shipment = replenishment.shipments[shipmentId];
                return (
                  <>
                    <List key={shipmentId} header={`Shipment ${shipmentId}`} bordered>
                      {shipment.verificationPhotos && shipment.verificationPhotos.length > 0 && (
                        <List.Item>
                          <b>Verification photos:</b>
                          <br />
                          <ul style={{ listStyle: 'none' }}>
                            {shipment.verificationPhotos.map(
                              (photoLink): JSX.Element => (
                                <li key={photoLink}>
                                  <a href={photoLink}>{photoLink}</a>
                                </li>
                              ),
                            )}
                          </ul>
                        </List.Item>
                      )}
                      {shipment.shipmentType === 'LTL' && shipment.isHazmat !== true && (
                        <List.Item>
                          <b>Stamped BOL:</b>
                          <br />
                          <ul style={{ listStyle: 'none' }}>
                            <li>
                              {shipment.stampedBillOfLading ? (
                                <a href={shipment.stampedBillOfLading}>
                                  {shipment.stampedBillOfLading}
                                </a>
                              ) : (
                                <Text>Waiting for brand to submit their stamped BOL</Text>
                              )}
                            </li>
                          </ul>
                        </List.Item>
                      )}
                    </List>
                    <Spacer height={10} />
                  </>
                );
              },
            )}
        </>
      ) : (
        <>
          <Spin style={{ marginRight: 10 }} />
          <Text>
            Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to get the
            UPS/Pallet Labels
          </Text>
        </>
      )}
    </>
  );
}
