import React, { useState, useEffect, FormEvent } from 'react';
import {
  Typography,
  Form,
  message,
  Input,
  Row,
  Col,
  Button,
  Alert,
  Select,
  Collapse,
  Icon,
  Descriptions,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import { ApiWithToken } from '../../../../utils/api';
import { scrollToTop } from '../';

const { Text } = Typography;
const { Panel } = Collapse;

interface HazmatTrackingNumbersProps extends FormComponentProps {
  replenishment: ReplenishmentRequest;
  shipmentId: any;
  fetchReplenishment: () => Promise<void>;
}

function HazmatTrackingNumbers({
  replenishment,
  shipmentId,
  form,
  fetchReplenishment,
}: HazmatTrackingNumbersProps): JSX.Element {
  const [boxes, setBoxes] = useState<any[]>([]);
  const [processing, setProcessing] = useState(false);

  const { getFieldDecorator, getFieldValue } = form;

  useEffect((): void => {
    const shipmentBoxes = replenishment.shipments[shipmentId].boxes;
    if (shipmentBoxes) {
      setBoxes(shipmentBoxes);
    }
  }, []);

  async function updateTrackingNumbers(e: FormEvent): Promise<void> {
    e.preventDefault();
    setProcessing(true);
    const { validateFields } = form;
    validateFields(
      async (err, values): Promise<void> => {
        if (err) return;

        const { trackingNumbers, shipmentTrackingNumber } = values;

        const trackingNumbersToUse = shipmentTrackingNumber || trackingNumbers;

        try {
          await ApiWithToken.post(
            `/replenishments/${replenishment._id}/shipments/${shipmentId}/tracking-numbers`,
            {
              trackingNumbers: trackingNumbersToUse,
            },
          );

          fetchReplenishment();
          scrollToTop();
          message.success('The tracking numbers were stored successfully!');
        } catch (e) {
          message.error(
            (e.response && e.response.data.message) || 'Something went wrong. Please try again.',
          );
        } finally {
          setProcessing(false);
        }
      },
    );
  }

  function displayTrackingNumbersForm(): JSX.Element | null {
    switch (getFieldValue('trackingNumbersMode')) {
      case 'one-for-all':
        return (
          <Form.Item label={`Tracking number for shipment ${shipmentId}`}>
            {getFieldDecorator(`shipmentTrackingNumber`, {
              rules: [{ required: true, message: 'All tracking numbers are required' }],
            })(<Input style={{ width: 200 }} />)}
          </Form.Item>
        );
      case 'one-per-box':
        return (
          <Row>
            {boxes.map(
              (box, index): JSX.Element => (
                <Col key={`${shipmentId}-${index}`} span={12}>
                  <Form.Item label={`Tracking number for box ${index + 1}`}>
                    {getFieldDecorator(`trackingNumbers[${index}]`, {
                      rules: [{ required: true, message: 'All tracking numbers are required' }],
                    })(<Input style={{ width: 200 }} />)}
                  </Form.Item>
                </Col>
              ),
            )}
          </Row>
        );
      default:
        return null;
    }
  }

  const shipmentPlanShipment =
    replenishment.shipmentPlan &&
    replenishment.shipmentPlan.find((sp): boolean => sp.ShipmentId === shipmentId);

  const address = (shipmentPlanShipment && shipmentPlanShipment.ShipToAddress) || {
    AddressLine1: '',
    City: '',
    CountryCode: '',
    Name: '',
    PostalCode: '',
    StateOrProvinceCode: '',
  };

  return (
    <Collapse
      key={shipmentId}
      expandIcon={({ isActive }): JSX.Element => (
        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      )}
      style={{ marginTop: 20 }}
    >
      <Panel header={`Tracking for shipment ${shipmentId}`} key={shipmentId}>
        <Descriptions title="Ship To:" layout="vertical" bordered>
          <Descriptions.Item label="Fulfillment Center">{address.Name}</Descriptions.Item>
          <Descriptions.Item label="Address" span={2}>
            {address.AddressLine1}
          </Descriptions.Item>
          <Descriptions.Item label="City and state">
            {address.City}, {address.StateOrProvinceCode}
          </Descriptions.Item>
          <Descriptions.Item label="Postal Code">{address.PostalCode}</Descriptions.Item>
          <Descriptions.Item label="Country Code">{address.CountryCode}</Descriptions.Item>
        </Descriptions>
        <Form onSubmit={updateTrackingNumbers}>
          <Alert
            type="warning"
            showIcon
            message={<Text strong>Please enter the tracking numbers for your boxes</Text>}
            style={{ marginBottom: 20, marginTop: 20 }}
          />

          <Form.Item label="How do you want to enter the tracking numbers?">
            {getFieldDecorator('trackingNumbersMode', {
              rules: [{ required: true, message: 'Please select an option.' }],
            })(
              <Select>
                <Select.Option value="one-for-all">
                  Enter in the Master Tracking Number for all boxes
                </Select.Option>
                <Select.Option value="one-per-box">
                  Enter in Tracking Number for each box
                </Select.Option>
              </Select>,
            )}
          </Form.Item>

          {displayTrackingNumbersForm()}

          <Row type="flex" justify="center">
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginTop: 20 }}
              disabled={processing}
              loading={processing}
            >
              Submit tracking numbers
            </Button>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

export default Form.create<HazmatTrackingNumbersProps>({ name: 'hazmat-tracking-form' })(
  HazmatTrackingNumbers,
);
