import React, { useState } from 'react';
import { Avatar, Button, Dropdown, Layout, Menu, Row, Col, Modal } from 'antd';
// components
import Notifications from '../notifications';
import { checkUserBelongsToRoles } from '../../utils';

const { Header } = Layout;

interface AppHeaderProps {
  history: any;
}

const checkUserRole = checkUserBelongsToRoles();

export default function AppHeader(props: AppHeaderProps): JSX.Element {
  const [displayBugReportModal, setDisplayBugReportModal] = useState(false);

  function logout(): void {
    localStorage.clear();
    window.location.replace('/');
  }

  const menu = (
    <Menu>
      <Menu.Item key="head2">
        <Button icon="logout" type="danger" onClick={logout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header style={{ background: '#fff', padding: 0 }}>
        <Row type="flex" justify="end">
          {checkUserRole(['admin', 'manager']) && (
            <Col xs={{ pull: 2 }}>
              <Button
                icon="question-circle"
                type="link"
                onClick={(): void => setDisplayBugReportModal(true)}
              >
                Support
              </Button>
            </Col>
          )}

          <Col xs={{ pull: 1 }}>
            <Notifications history={props.history} />
          </Col>

          {/* <Col xs={{ span: 2, offset: 21 }} md={{ span: 1, offset: 23 }}> */}
          <Col style={{ paddingRight: 15 }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Avatar size="large" icon="user" />
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Modal
        title="Submit a bug report"
        visible={displayBugReportModal}
        width={'70%'}
        onCancel={(): void => setDisplayBugReportModal(false)}
        onOk={(): void => setDisplayBugReportModal(false)}
        destroyOnClose={true}
      >
        <iframe
          title="Bug Report"
          className="clickup-embed clickup-dynamic-height"
          src="https://forms.clickup.com/f/88r71-19733/LV85KYFYV1VIW2BHSS"
          style={{ height: '70vh', width: 'calc(100% - 5px)', border: 'none' }}
        />
        <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js" />
      </Modal>
    </>
  );
}
