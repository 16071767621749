import React from 'react';
import { message, Popconfirm, Button } from 'antd';
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import { ApiWithToken } from '../../../../utils/api';

interface UnpauseReplenishmentButtonProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
}

export default function UnpauseReplenishmentButton({
  replenishment,
  fetchReplenishment,
}: UnpauseReplenishmentButtonProps): JSX.Element {
  async function unpause(): Promise<void> {
    try {
      await ApiWithToken.put(`/replenishments/${replenishment._id}`, {
        paused: false,
        lastError: null,
      });
      fetchReplenishment();
    } catch (e) {
      message.error('Something went wrong.');
      fetchReplenishment();
    }
  }

  return (
    <Popconfirm title="Are you sure you want to resume this replenishment?" onConfirm={unpause}>
      <Button icon="play-circle">Resume replenishment</Button>
    </Popconfirm>
  );
}
