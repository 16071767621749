import React, { useState } from 'react';
import { Button, Modal } from 'antd';
// components
import FeedbackForm from '../../feedback-form';

interface Props {
  isOpen?: boolean;
  buttonLabel: string;
}

export default function FeedbackModal(props: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  function toggle(): void {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <Button type="primary" onClick={toggle}>
        {props.buttonLabel || ''}
      </Button>
      <Modal
        title="We'd love to know what you think!"
        visible={isOpen}
        onOk={toggle}
        onCancel={toggle}
        footer={null}
        destroyOnClose
      >
        <FeedbackForm onCancel={toggle} />
      </Modal>
    </div>
  );
}
