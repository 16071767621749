import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Layout } from 'antd';
// styles
import './main.css';
import './styles/utils.css';
// routing components
import PrivateRoute from './components/routing/private-route';
// components
import HomePage from './pages/home';
import LoginPage from './pages/auth/login';
import SplashPage from './pages/splash';
import AppPage from './pages/app';
import UploadLabelsPicturePage from './pages/upload-labels-picture/index';
import UploadStampedBolPicture from './pages/upload-stamped-bol-picture';
import * as Sentry from '@sentry/react';

function App(): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={<div>Test for Sentry Checking!</div>}>
      <Layout>
        <Router>
          <Route path="/" exact component={SplashPage} />

          <Route
            path="/upload-labels-picture/:replenishmentId/shipment/:shipmentId"
            exact
            component={UploadLabelsPicturePage}
          />

          <Route
            path="/upload-bol-picture/:replenishmentId/shipment/:shipmentId"
            exact
            component={UploadStampedBolPicture}
          />

          <Route path="/home" component={HomePage} />
          <Route path="/login" component={LoginPage} />

          <PrivateRoute component={AppPage} path="/app" />
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
}

export default App;
