import React from 'react';
import { Collapse, Typography, Alert } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import LTLExtraFields from './ltl-extra-fields';
// utils
import GetUpsLabels from './get-ups-labels';

const { Text } = Typography;
const { Panel } = Collapse;

interface DisplayShipmentsLastStepsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: Function;
}

export default function DisplayShipmentsLastSteps({
  replenishment,
  fetchReplenishment,
}: DisplayShipmentsLastStepsProps): JSX.Element | null {
  const shipmentIds: any[] = Object.keys(replenishment.shipments);

  function handleInformationForShipment(shipmentId: any): JSX.Element | null {
    const shipment = replenishment.shipments[shipmentId];

    let shipmentType;

    if (shipment.isHazmat) {
      shipmentType = 'hazmat';
    } else if (shipment.shipmentType === 'LTL') {
      shipmentType = 'LTL';
    } else if (shipment.shipmentType === 'SP') {
      shipmentType = 'SP';
    }

    switch (shipmentType) {
      case 'LTL':
        return (
          <LTLExtraFields
            replenishment={replenishment}
            shipmentId={shipmentId}
            fetchReplenishment={fetchReplenishment}
          />
        );
      case 'SP':
        return (
          <GetUpsLabels
            replenishment={replenishment}
            shipmentId={shipmentId}
            fetchReplenishment={fetchReplenishment}
          />
        );
      case 'hazmat':
        return <Alert showIcon type="success" message={<Text>This shipment is complete.</Text>} />;
    }

    return null;
  }

  return (
    <>
      {shipmentIds.map(
        (shipmentId: any): JSX.Element => (
          <Collapse bordered={false} key={shipmentId}>
            <Panel header={`Shipment ${shipmentId}`} key={shipmentId + 1}>
              {handleInformationForShipment(shipmentId)}
            </Panel>
          </Collapse>
        ),
      )}
    </>
  );
}
