import React, { useEffect, useState } from 'react';
import { Alert, Button, List, message, Modal } from 'antd';
// interfaces
import { ReplenishmentProduct } from '../../../../interfaces/replenishment-request';
import { User } from '../../../../interfaces/user';
import { ProductError } from '../../../../interfaces/product-error';
// components
import Spacer from '../../../../components/spacer';
import { ApiWithToken } from '../../../../utils/api';
import { getDataDecrypt } from '../../../../utils/encryption';

interface HandleProductsErrorsModalProps {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  erroredProducts: ProductError[];
  products: ReplenishmentProduct[];
  retryCallback: () => any;
  brandCode: string;
  history: any;
  whoLabels: string;
}

export default function HandleProductsErrorsModal({
  showModal,
  setShowModal,
  erroredProducts,
  products,
  retryCallback,
  brandCode,
  history,
  whoLabels,
}: HandleProductsErrorsModalProps): JSX.Element {
  const [passingProducts, setPassingProducts] = useState<ReplenishmentProduct[]>([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect((): void => {
    const erroredSkus: string[] = [];
    const erroredAsins: string[] = [];
    for (const error of erroredProducts) {
      if (error.asin) {
        erroredAsins.push(error.asin);
      }
      if (error.seller_sku) {
        erroredSkus.push(error.seller_sku);
      }
    }

    setPassingProducts(
      products.filter(
        (p): boolean => !erroredSkus.includes(p.seller_sku) && !erroredAsins.includes(p.asin),
      ),
    );
  }, [products, erroredProducts]);

  async function proceedWithPassingProducts(): Promise<void> {
    const user: User = getDataDecrypt('user');

    setSubmitting(true);
    try {
      const response = await ApiWithToken.post('replenishments', {
        brandCode,
        products: passingProducts,
        user,
        packageLabels: whoLabels,
        country: passingProducts[0].country || 'US',
      });
      message.success(
        `A replenishment request with ID ${response.data.id} was successfully created!`,
        3,
      );
      history.push(`/app/replenishment-requests/view/${response.data._id}`);
    } catch (e) {
      message.error('Something went wrong. Please try again');
    } finally {
      setSubmitting(false);
    }
  }

  function testAgain(): void {
    setShowModal(false);
    retryCallback && retryCallback();
  }

  return (
    <Modal
      title="Some products have problems"
      visible={showModal}
      onCancel={(): void => setShowModal(false)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={submitting} onClick={(): void => setShowModal(false)}>
            Back
          </Button>
          <Spacer width={5} />
          <Button disabled={submitting} onClick={testAgain}>
            Test Again
          </Button>
          <Spacer width={5} />
          {passingProducts.length > 0 && (
            <Button
              type="primary"
              disabled={submitting}
              loading={submitting}
              onClick={proceedWithPassingProducts}
            >
              Proceed with passing products
            </Button>
          )}
        </div>
      }
    >
      <h3>Products with errors</h3>
      <Alert
        type="info"
        style={{ marginTop: 20 }}
        showIcon
        message={
          <p>
            For errors of type FBA_INB_XXXX, refer to&nbsp;
            <a
              style={{ color: 'blue' }}
              href="https://github.com/amzn/selling-partner-api-docs/blob/bcc9de8bde8b9584006c84c3656215c351edfdc0/references/fba-inbound-eligibility-api/fbaInbound.md#ineligibilityreasonlist"
            >
              this page
            </a>
            .
          </p>
        }
      />

      <Spacer height={15} />

      <List
        loading={false}
        itemLayout="horizontal"
        dataSource={erroredProducts}
        renderItem={(item): JSX.Element => (
          <List.Item>
            <List.Item.Meta title={item.seller_sku} />
            <div>
              <span>{item.description}</span>
            </div>
          </List.Item>
        )}
      />
      {passingProducts.length > 0 && (
        <>
          <Spacer height={20} />
          <h3>Passing Products</h3>
          <List
            loading={false}
            itemLayout="horizontal"
            dataSource={passingProducts}
            renderItem={(item): JSX.Element => (
              <List.Item>
                <List.Item.Meta title={item.seller_sku} />
              </List.Item>
            )}
          />
        </>
      )}
    </Modal>
  );
}
