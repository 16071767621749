import React, { useState } from 'react';
import { Button, Col, Form, Input, Typography, Row } from 'antd';

const { TextArea } = Input;
const { Text } = Typography;

interface Props {
  onCancel: () => void;
}

export default function FeedbackForm(props: Props): JSX.Element {
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState('');

  if (sent) {
    return (
      <Text strong className="thanks-message">
        Thank you for participating!
      </Text>
    );
  }

  return (
    <Form>
      <Form.Item>
        <TextArea value={message} onChange={(e): void => setMessage(e.target.value)} />
      </Form.Item>
      <Row type="flex" justify="end">
        <Col sm={24} md={8}>
          <Button
            className="cancel"
            type="danger"
            style={{ marginRight: 5 }}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button className="submit" type="primary" onClick={(): void => setSent(true)}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
