import React, { useState, useEffect } from 'react';
import { Typography, DatePicker, Input, Table, Row, Button, Form, message, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
// interfaces
import { StonehengeBrand } from '../../../interfaces/brand';
import {
  ReplenishmentRequest,
  ReplenishmentProduct,
  ShipmentPlan,
} from '../../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../../utils/api';
import { isLessThan5Months } from './tables-utils';
import { labelOptions, PageType, marketPlaceOptions } from '../../../utils/marketplace';
import { scrollToTop } from '../../../pages/replenishment-requests/view';
import { MarketplacesEnum } from '../../../enums/marketplaces.enum';

const { Text } = Typography;

interface EverythingInOneBoxProps extends FormComponentProps {
  replenishment?: ReplenishmentRequest | null;
  setSuccess: (newVal: boolean) => void;
  shipment: ShipmentPlan;
  fetchReplenishment: () => void;
}

function OneSKUPerBoxTable({
  replenishment,
  form,
  shipment,
  setSuccess,
}: EverythingInOneBoxProps): JSX.Element | null {
  const [products, setProducts] = useState<any[]>([]);
  const [brand, setBrand] = useState<any | StonehengeBrand>({});
  const [pageTypes, setPageTypes] = useState<PageType[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchBrand(): Promise<void> {
    try {
      const { data: newBrand } = await ApiWithToken.get(
        `/brands/${replenishment && replenishment.brand.code}`,
      );
      setBrand(newBrand);
    } catch (e) {
      message.error('An error occurred fetching the brand.');
    }
  }

  useEffect((): void => {
    if (shipment) {
      const shipmentProductsWithKey = shipment.Items.map((product: any, index: number): object => ({
        key: product.SellerSKU + index,
        ...product,
      }));

      setProducts(shipmentProductsWithKey);
      fetchBrand();
    }
  }, []);

  useEffect((): void => {
    if (brand) {
      const brandMarketplace = brand.marketplace
        ? brand.marketplace.marketplaceId
        : MarketplacesEnum.US;

      const marketplace = marketPlaceOptions.find(
        (marketplace): boolean =>
          (replenishment && replenishment.marketplaceId ? true : false) &&
          marketplace.marketplaceId ===
            ((replenishment && replenishment.marketplaceId) || brandMarketplace),
      );

      if (marketplace) {
        const validPageTypes = labelOptions.filter((labelOption): boolean => {
          return (
            labelOption.allowedCountries.length === 0 ||
            labelOption.allowedCountries.includes(marketplace.countryCode)
          );
        });

        setPageTypes(validPageTypes);
      } else {
        const validPageTypes = labelOptions.filter((labelOption): boolean => {
          return (
            labelOption.allowedCountries.length === 0 || labelOption.allowedCountries.includes('US')
          );
        });

        setPageTypes(validPageTypes);
      }
    }
  }, [brand]);

  if (!replenishment) return null;

  function findImageBySKU(sku: string): string {
    if (!replenishment) return '';

    const product = replenishment.products.find((product): boolean => product.seller_sku === sku);
    return product ? product.product_image_url : '';
  }

  const { getFieldDecorator, validateFields, getFieldValue } = form;

  async function submitForm(shipmentData: any): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment && replenishment._id}/shipments/${
          shipment.ShipmentId
        }/logistics`,
        shipmentData,
      );
      message.success('Logistics info was successfully submitted!');
      scrollToTop();
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      message.error('There was an error updating the logistics info. Please try again');
      setLoading(false);
    }
  }

  async function submitLogistics(e: any): Promise<void> {
    e.preventDefault();
    validateFields((err, values): void | null => {
      if (err || !replenishment) return null;

      const { dimensions, weight } = values[0];

      const box: any = {
        weight: parseInt(weight),
        dimensions: {
          x: parseInt(dimensions.x),
          y: parseInt(dimensions.y),
          z: parseInt(dimensions.z),
        },
        products: [],
      };

      for (let i = 0; i < Object.keys(values).length; i++) {
        const product = values[i];
        if (product) {
          if (product.expirationDate && !product.expirationDate.isAfter(moment())) {
            message.error('Expiration date must be after today');
            return;
          }
          box.products.push({
            totalQty: parseInt(products[i].Quantity),
            seller_sku: products[i].SellerSKU,
            qty: parseInt(products[i].Quantity),
            ...(product.expirationDate && { expirationDate: product.expirationDate }),
          });
        }
      }

      if (box.products.length !== shipment.Items.length) {
        for (let i = 1; i < shipment.Items.length; i++) {
          const product = shipment.Items[i];
          if (
            !box.products.find(
              (boxProduct: any): boolean => boxProduct.seller_sku === product.SellerSKU,
            )
          ) {
            box.products.push({
              totalQty: product.Quantity,
              seller_sku: product.SellerSKU,
              qty: product.Quantity,
            });
          }
        }
      }

      const newShipment = {
        boxes: [box],
        shipmentType: values.shipmentType,
        pageType: values.pageType,
      };

      submitForm(newShipment);
    });
  }

  // const isDevEnv = window.location.href.includes('dev.inventory');

  function findProductIsConsumable(sku: string): boolean {
    const product =
      replenishment && replenishment.products.find((p): boolean => p.seller_sku === sku);
    if (!product) return false;
    return product.consumable;
  }

  return (
    <>
      <Form onSubmit={submitLogistics}>
        <Form.Item label="Please select the shipment type:">
          {getFieldDecorator('shipmentType', {
            rules: [{ required: true, message: 'You must select the shipment type' }],
            initialValue: 'SP',
          })(
            <Select>
              <Select.Option value="SP">Small Parcel (SP)</Select.Option>
              <Select.Option value="LTL" disabled>
                Less Than Truckload/Full Truckload (LTL)
              </Select.Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Select the page type for your labels">
          {getFieldDecorator('pageType', {
            initialValue: 'PackageLabel_Plain_Paper',
            rules: [{ required: true, message: 'Page type is required' }],
          })(
            <Select>
              {pageTypes.map(
                (pageType): JSX.Element => (
                  <Select.Option key={pageType.value} value={pageType.value}>
                    {pageType.label}
                  </Select.Option>
                ),
              )}
            </Select>,
          )}
        </Form.Item>
        <Table
          bordered
          scroll={{ x: true }}
          className="mt-4"
          dataSource={products}
          columns={[
            {
              key: 'img',
              dataIndex: 'SellerSKU',
              render: (text: string): JSX.Element => (
                <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={findImageBySKU(text)} />
              ),
            },
            {
              title: 'SKU',
              dataIndex: 'SellerSKU',
            },
            {
              title: 'Qty',
              dataIndex: 'Quantity',
            },
            {
              title: 'Expiration date',
              render: (text: string, record: any, index: number): JSX.Element =>
                findProductIsConsumable(record.SellerSKU) ? (
                  <Form.Item>
                    {getFieldDecorator(`${index}.expirationDate`, {
                      rules: [{ required: true, message: 'Expiration date is required' }],
                    })(
                      <DatePicker
                        disabledDate={isLessThan5Months}
                        style={{ width: 160 }}
                        placeholder="Select date"
                        defaultPickerValue={moment().add(5, 'months')}
                      />,
                    )}
                  </Form.Item>
                ) : (
                  <Text>Not needed</Text>
                ),
            },
            ...(getFieldValue('shipmentType') === 'LTL'
              ? []
              : [
                  {
                    title: 'Weight (lb)',
                    render: (
                      text: string,
                      record: ReplenishmentProduct,
                      index: number,
                    ): JSX.Element | null =>
                      index === 0 ? (
                        <Form.Item>
                          {getFieldDecorator(`${index}.weight`, {
                            rules: [{ required: true, message: ' ' }],
                          })(<Input type="number" style={{ width: 70 }} min="0" max="45" />)}
                        </Form.Item>
                      ) : null,
                  },
                  {
                    title: 'Dimensions',
                    render: (
                      text: string,
                      record: ReplenishmentProduct,
                      index: number,
                    ): JSX.Element | null =>
                      index === 0 ? (
                        <Row type="flex" justify="space-between" style={{ width: 200 }}>
                          <Form.Item>
                            {getFieldDecorator(`${index}.dimensions.x`, {
                              rules: [{ required: true, message: ' ' }],
                            })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
                          </Form.Item>
                          <Text>x</Text>
                          <Form.Item>
                            {getFieldDecorator(`${index}.dimensions.y`, {
                              rules: [{ required: true, message: ' ' }],
                            })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
                          </Form.Item>
                          <Text>x</Text>
                          <Form.Item>
                            {getFieldDecorator(`${index}.dimensions.z`, {
                              rules: [{ required: true, message: ' ' }],
                            })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
                          </Form.Item>
                        </Row>
                      ) : null,
                  },
                ]),
          ]}
        />

        <Row type="flex" justify="center">
          <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
            Submit logistics info
          </Button>
        </Row>
      </Form>
    </>
  );
}

export default Form.create<EverythingInOneBoxProps>({ name: 'logistics-form' })(OneSKUPerBoxTable);
