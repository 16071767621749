import React from 'react';
import { Tag } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import { getDataDecrypt } from '../../../../utils/encryption';

interface ReplenishmentStatusTagProps {
  replenishment: ReplenishmentRequest | null;
}

export default function ReplenishmentStatusTag({
  replenishment,
}: ReplenishmentStatusTagProps): JSX.Element | null {
  if (!replenishment) return null;

  const user = getDataDecrypt('user');

  const brandSentAvailable = replenishment.products.some((product: any): boolean =>
    product.hasOwnProperty('availableQty'),
  );

  const userBelongsToBrand = user.brand ? user.brand.brandCode === replenishment.brand.code : false;

  const us = 'Sunken Stone';
  const brand = replenishment.brand.name;

  let color = 'orange';
  let message = '';

  if (brandSentAvailable) {
    message = `Awaiting approval by ${us}`;

    if (replenishment.approved) {
      color = 'green';
      message = 'Approved';
    }
  } else {
    if (userBelongsToBrand) {
      return null;
    }
    message = `Awaiting availability confirmation by ${brand}`;
  }

  return <Tag color={color}>{message}</Tag>;
}
