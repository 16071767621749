import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Table, Input, Typography, Popconfirm, message } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
import { StonehengeProduct } from '../../../interfaces/product';
// components
import Spacer from '../../../components/spacer';
// utils
import { ApiWithToken } from '../../../utils/api';

const { Text } = Typography;

interface EditReplenishmentProductsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
}

function EditReplenishmentProducts({
  replenishment,
  fetchReplenishment,
}: EditReplenishmentProductsProps): JSX.Element {
  const [editingRow, setEditingRow] = useState<string | null>();
  const [products, setProducts] = useState(replenishment.products);
  const [productOptions, setProductOptions] = useState<string[]>([]);
  const [lastProductsBatch, setLastProductsBatch] = useState<StonehengeProduct[]>([]);
  const [skuSearchText, setSkuSearchText] = useState('placeholder');
  const [selectedSku, setSelectedSku] = useState<any>();
  const [requestedQty, setRequestedQty] = useState<number>();
  const [availableQty, setAvailableQty] = useState<number>();

  const newProductId = 'new-product';

  function toggleEditRow(sku: string): void {
    if (sku === newProductId) {
      setEditingRow(null);
      setProducts(replenishment.products);
      return;
    }
    if (editingRow === sku) {
      setEditingRow(null);
      return;
    }
    setEditingRow(sku);
    const replenishmentProduct = replenishment.products.find(
      (rp): boolean => rp.seller_sku === sku,
    );
    if (replenishmentProduct) {
      setSelectedSku(replenishmentProduct.seller_sku);
      setRequestedQty(replenishmentProduct.requestedQty);
      setAvailableQty(replenishmentProduct.availableQty);
    }
  }

  async function searchBySku(text: string): Promise<void> {
    try {
      const {
        data: { data },
      } = await ApiWithToken.get(`/products?sku=${text}`);
      if (data) {
        const skus = data.map((product: StonehengeProduct): string => product.seller_sku);
        setLastProductsBatch(data);
        setProductOptions(skus);
      }
    } catch (e) {
      console.log('something went wrong', e);
    }
  }

  useEffect((): void => {
    searchBySku(skuSearchText);
  }, [skuSearchText]);

  useEffect((): void => {
    setProducts(replenishment.products);
  }, [replenishment]);

  async function saveProductChanges(): Promise<void> {
    try {
      if (availableQty) {
        if (!selectedSku || !requestedQty) {
          return;
        }
      }

      if (!selectedSku || !requestedQty) {
        return;
      }

      const productInLastBatchIndex = lastProductsBatch.findIndex(
        (p): boolean => p.seller_sku === selectedSku,
      );
      let product;
      if (productInLastBatchIndex < 0) {
        const { data } = await ApiWithToken.get(`/products?sku=${selectedSku}`);
        if (data.length > 1) {
          const finalProduct = data.find(
            (p: StonehengeProduct): boolean => p.seller_sku === selectedSku,
          );
          if (finalProduct) {
            product = finalProduct;
          }
        } else {
          product = data[0];
        }
      } else {
        product = lastProductsBatch[productInLastBatchIndex];
        if (!product) {
          product = replenishment.products.find((p): boolean => p.seller_sku === selectedSku);
        }
      }

      await ApiWithToken.put(
        `/replenishments/${replenishment._id}/products/${encodeURIComponent(product.seller_sku)}`,
        {
          ...product,
          requestedQty: requestedQty,
          availableQty: availableQty,
          case_quantity: product.case_quantity,
        },
      );

      message.success('Products updated successfully');
    } catch (e) {
      message.error('Something went wrong. Please try again.');
    } finally {
      setEditingRow(null);
      fetchReplenishment();
    }
  }

  function addNewProductRow(): void {
    if (products.length > 0 && products[products.length - 1].seller_sku === newProductId) {
      message.warn('You can only add one product at a time');
      return;
    }

    const productsCopy = Array.from(products);

    productsCopy.push({
      _id: newProductId,
      seller_sku: '',
      asin: '',
      // eslint-disable-next-line
      product_image_url: '',
      consumable: false,
      requestedQty: 0,
      availableQty: 0,
    });

    setProducts(productsCopy);
    setEditingRow(newProductId);
  }

  async function removeProduct(sku: string): Promise<void> {
    try {
      await ApiWithToken.delete(
        `/replenishments/${replenishment._id}/products/${encodeURIComponent(sku)}`,
      );
      message.success('Product removed from shipment');
    } catch (e) {
      message.error('Something went wrong removing the product. Please try again.');
    } finally {
      fetchReplenishment();
    }
  }

  return (
    <Table
      dataSource={products}
      rowKey="_id"
      pagination={{
        pageSize: products.length,
      }}
      footer={(): JSX.Element => (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button onClick={addNewProductRow}>Add new product</Button>
        </div>
      )}
      columns={[
        {
          dataIndex: 'product_image_url',
          render: (productImageUrl): JSX.Element =>
            productImageUrl && (
              <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={productImageUrl} />
            ),
        },
        {
          title: 'SKU',
          dataIndex: 'seller_sku',
          render: (value: string, product): JSX.Element =>
            editingRow === product._id && product._id === newProductId ? (
              <AutoComplete
                dataSource={productOptions}
                style={{ minWidth: 220 }}
                onSelect={(value): void => setSelectedSku(value)}
                onSearch={(text): void => setSkuSearchText(text)}
                defaultValue={product.seller_sku}
                placeholder="Search by SKU"
              />
            ) : (
              <Text>{value}</Text>
            ),
        },
        {
          title: 'Requested quantity',
          dataIndex: 'requestedQty',
          render: (value: string, product): JSX.Element =>
            editingRow === product.seller_sku ? (
              <Input
                defaultValue={value}
                onChange={(e): void => setRequestedQty(parseInt(e.target.value))}
              />
            ) : (
              <Text>{value}</Text>
            ),
        },
        {
          title: 'Available quantity',
          dataIndex: 'availableQty',
          render: (value: string, product): JSX.Element =>
            editingRow === product.seller_sku ? (
              <Input
                defaultValue={value}
                onChange={(e): void => setAvailableQty(parseInt(e.target.value))}
              />
            ) : (
              <Text>{value}</Text>
            ),
        },
        {
          title: 'Actions',
          render: (product): JSX.Element =>
            editingRow === product.seller_sku ? (
              <div style={{ display: 'flex' }}>
                <Button type="primary" size="small" onClick={saveProductChanges}>
                  Save
                </Button>
                <Spacer width={5} />
                <Button size="small" onClick={(): void => toggleEditRow(product.seller_sku)}>
                  Cancel
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <Button size="small" onClick={(): void => toggleEditRow(product.seller_sku)}>
                  Edit
                </Button>
                <Spacer width={5} />
                <Popconfirm
                  title="Are you sure you want to delete remove this product from the replenishment request?"
                  onConfirm={(): any => removeProduct(product.seller_sku)}
                >
                  <Button type="danger" size="small">
                    Remove
                  </Button>
                </Popconfirm>
              </div>
            ),
        },
      ]}
    />
  );
}

export default EditReplenishmentProducts;
