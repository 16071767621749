/* eslint-disable */
export interface EnvType {
  apiUrl: string;
  appUrl: string;
  socketUrl: string;
  smartyAuthId: string;
}

export const env: EnvType = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  appUrl: process.env.REACT_APP_URL || '',
  socketUrl: process.env.REACT_APP_SOCKET_URL || '',
  smartyAuthId: process.env.REACT_APP_SMARTY_AUTH_ID || '',
};
