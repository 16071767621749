import moment from 'moment';

export function isLessThan5Months(from: moment.Moment | undefined | null): boolean {
  if (!from) return true;
  const fiveMonthsFromNow = moment()
    .add(5, 'months')
    .subtract(1, 'day');

  if (from.isBefore(fiveMonthsFromNow)) {
    return true;
  }
  return false;
}
