import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { message } from 'antd';
import io from 'socket.io-client';
// utils
import { ApiWithToken } from '../../../utils/api';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
// env
import { env } from '../../../config/client';
// components
import ViewReplenishmentRequestPageV1 from './v1';
import ViewReplenishmentRequestPageV2 from './v2';

export function scrollToTop(): void {
  window.scrollTo(0, 0);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ViewReplenishmentRequestPageProps extends RouteComponentProps<{ id: string }> {}

export default function ViewReplenishmentRequestPage(
  props: ViewReplenishmentRequestPageProps,
): JSX.Element {
  const {
    match: { params },
    history,
  } = props;

  const [replenishment, setReplenishment] = useState<ReplenishmentRequest | null>(null);
  const [loading, setLoading] = useState(true);

  async function fetchReplenishment(replenishmentId?: string): Promise<any> {
    try {
      const { data: newReplenishment }: { data: ReplenishmentRequest } = await ApiWithToken.get(
        `/replenishments/${replenishmentId || params.id}`,
      );
      if (newReplenishment) {
        setReplenishment(newReplenishment);
        setLoading(false);
      } else {
        message.error('An error occurred fetching the replenishment request.');
        props.history.push('/app/replenishment-requests');
      }
    } catch (e) {
      message.error('An error occurred fetching the replenishment request.');
      console.log(e);
      setLoading(false);
      props.history.push('/app/replenishment-requests');
    }
  }

  useEffect((): void => {
    fetchReplenishment(params.id);
  }, [params]);

  useEffect((): void => {
    function connectToSocket(): void {
      const socket = io(`${env.socketUrl}/replenishments`);
      socket.on(params.id, (): any => fetchReplenishment());
    }
    connectToSocket();
  }, []);

  let content;

  if (replenishment && !replenishment.step) {
    content = (
      <ViewReplenishmentRequestPageV1
        history={history}
        replenishment={replenishment}
        fetchReplenishment={fetchReplenishment}
        loading={loading}
        setLoading={setLoading}
      />
    );
  } else {
    content = (
      <ViewReplenishmentRequestPageV2
        history={history}
        replenishment={replenishment}
        fetchReplenishment={fetchReplenishment}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }

  return content;
}
