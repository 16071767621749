import React from 'react';
import { Typography, Alert } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import BillOfLadingForm from './bill-of-lading-form';

interface UploadSignedBolProps {
  replenishment: ReplenishmentRequest;
  shipmentId: any;
}

const { Text } = Typography;

export default function UploadSignedBol({
  replenishment,
  shipmentId,
}: UploadSignedBolProps): JSX.Element {
  const shipment = replenishment.shipments[shipmentId];

  return (
    <div>
      {shipment.billOfLading ? (
        <BillOfLadingForm
          replenishment={replenishment}
          shipment={shipment}
          shipmentId={shipmentId}
        />
      ) : (
        <Alert
          type="info"
          showIcon
          message={
            <Text strong>We will send the Bill Of Lading to your email when it is available.</Text>
          }
          style={{ marginBottom: 20 }}
        />
      )}
    </div>
  );
}
