import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

export default function ForbiddenPage(): JSX.Element {
  return (
    <div style={{ padding: 24, background: '#fff', minHeight: 360, marginTop: 15 }}>
      <Row type="flex" justify="center" align="middle">
        <Col>
          <img
            src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg"
            className="mr-4"
            alt="403 error"
          />
          <Text style={{ fontSize: 18 }}>You don&apos;t have access to this page.</Text>
        </Col>
      </Row>
    </div>
  );
}
