import React, { useState, useEffect } from 'react';
import {
  Typography,
  DatePicker,
  Input,
  Table,
  Row,
  Button,
  Form,
  message,
  Alert,
  Select,
  Divider,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
// utils
import { ApiWithToken } from '../../../utils/api';
import { isLessThan5Months } from './tables-utils';
import { labelOptions, PageType, marketPlaceOptions } from '../../../utils/marketplace';
// interfaces
import {
  ReplenishmentRequest,
  ShipmentPlan,
  ReplenishmentProduct,
  ReplenishmentShipment,
  Box,
} from '../../../interfaces/replenishment-request';
import { BolContact, Brand } from '../../../interfaces/brand';
// components
import CreateBrandContactForm from '../../create-brand-contact-form';
import { getCleanSku } from './more-than-one-sku-per-box';
import { scrollToTop } from '../../../pages/replenishment-requests/view';
import { MarketplacesEnum } from '../../../enums/marketplaces.enum';

const { Text } = Typography;

interface OneSKUPerBoxTableProps extends FormComponentProps {
  replenishment?: ReplenishmentRequest | null;
  setSuccess: (newVal: boolean) => void;
  shipment: ShipmentPlan;
  fetchReplenishment: () => void;
}

function OneSKUPerBoxTable({
  replenishment,
  form,
  shipment,
  setSuccess,
  fetchReplenishment,
}: OneSKUPerBoxTableProps): JSX.Element | null {
  const [products, setProducts] = useState<any[]>([]);
  const [productsRows, setProductsRows] = useState<{ [string: string]: any }>({});
  const [brand, setBrand] = useState<any | Brand>({});
  const [pageTypes, setPageTypes] = useState<PageType[]>([]);
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, validateFields, getFieldValue } = form;

  async function fetchBrand(): Promise<void> {
    try {
      const { data: newBrand } = await ApiWithToken.get(
        `/brands/${replenishment && replenishment.brand.code}`,
      );
      setBrand(newBrand);
    } catch (e) {
      message.error('An error occurred fetching the brand.');
    }
  }

  useEffect((): void => {
    if (shipment) {
      const shipmentProductsWithKey = shipment.Items.map((product: any, index: number): object => ({
        key: product.SellerSKU + index,
        ...product,
      }));

      setProducts(shipmentProductsWithKey);
      fetchBrand();
    }
  }, []);

  useEffect((): void => {
    if (brand) {
      const brandMarketplace = brand.marketplace
        ? brand.marketplace.marketplaceId
        : MarketplacesEnum.US;

      const marketplace = marketPlaceOptions.find(
        (marketplace): boolean =>
          (replenishment && replenishment.marketplaceId ? true : false) &&
          marketplace.marketplaceId ===
            ((replenishment && replenishment.marketplaceId) || brandMarketplace),
      );

      if (marketplace) {
        const validPageTypes = labelOptions.filter((labelOption): boolean => {
          return (
            labelOption.allowedCountries.length === 0 ||
            labelOption.allowedCountries.includes(marketplace.countryCode)
          );
        });

        setPageTypes(validPageTypes);
      } else {
        const validPageTypes = labelOptions.filter((labelOption): boolean => {
          return (
            labelOption.allowedCountries.length === 0 || labelOption.allowedCountries.includes('US')
          );
        });

        setPageTypes(validPageTypes);
      }
    }
  }, [brand]);

  if (!replenishment) return null;

  function findImageBySKU(sku: string): string {
    if (!replenishment) return '';

    const product = replenishment.products.find((product): boolean => product.seller_sku === sku);
    return product ? product.product_image_url : '';
  }

  function findProductBySKU(sku: string): ReplenishmentProduct | null {
    if (!replenishment) return null;

    const product = replenishment.products.find((product): boolean => product.seller_sku === sku);
    return product ? product : null;
  }

  async function submitForm(shipmentData: any): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment && replenishment._id}/shipments/${
          shipment.ShipmentId
        }/logistics`,
        shipmentData,
      );
      message.success('Shipment info was successfully submitted!');
      setSuccess(true);
      setLoading(false);
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('There was an error updating the logistics info. Please try again');
      setLoading(false);
    }
  }

  function addNewRow(sku: string): void {
    const rowsCopy = {
      ...productsRows,
    };

    const sellerSKU = getCleanSku(sku);

    if (rowsCopy[sellerSKU]) {
      rowsCopy[sellerSKU].push({});
    } else {
      rowsCopy[sellerSKU] = [{}];
    }
    setProductsRows(rowsCopy);
  }

  function deleteNewRow(sku: string): void {
    const rowsCopy = {
      ...productsRows,
    };

    const sellerSKU = getCleanSku(sku);

    if (rowsCopy[sellerSKU]) {
      if (rowsCopy[sellerSKU].length === 0) return;

      rowsCopy[sellerSKU].splice(rowsCopy[sellerSKU].length - 1, 1);
      setProductsRows(rowsCopy);
    }
  }

  function showDeleteButton(sku: string): boolean {
    const rowsCopy = {
      ...productsRows,
    };

    const sellerSKU = getCleanSku(sku);

    return rowsCopy[sellerSKU] && rowsCopy[sellerSKU].length > 0;
  }

  function getProductRows(sku: string): any[] {
    const sellerSKU = getCleanSku(sku);

    if (productsRows[sellerSKU]) {
      return productsRows[sellerSKU];
    }

    return [];
  }

  async function submitLogistics(e: any): Promise<void> {
    e.preventDefault();
    validateFields((err, values): void | null => {
      if (err || !replenishment) return;

      const { shipmentType, pageType } = values;

      let newShipment: Partial<ReplenishmentShipment> = {};

      for (let i = 0; i < Object.keys(values).length; i++) {
        const product = values[i];
        if (!product) continue;

        let totalProductQty = 0;
        if (product.expirationDate && !product.expirationDate.isAfter(moment())) {
          message.error('Expiration dates must be after today');
          return null;
        }
        const boxes: Box[] = [...(newShipment.boxes || [])];

        if (product.totalBoxesExtra) {
          // this means there are extra rows
          for (let j = 0; j < product.totalBoxesExtra.length; j++) {
            const totalBoxes = parseInt(product.totalBoxesExtra[j]);
            const expirationDate = product.expirationDate;
            const dimensions = product.dimensionsExtra[j];
            const itemsPerBox = product.itemsPerBoxExtra[j];
            const weight = product.weightExtra[j];

            for (let k = 0; k < totalBoxes; k++) {
              totalProductQty += parseInt(itemsPerBox);

              const box = {
                ...(shipmentType === 'SP' && {
                  weight: parseInt(weight),
                  dimensions: {
                    x: parseInt(dimensions.x),
                    y: parseInt(dimensions.y),
                    z: parseInt(dimensions.z),
                  },
                }),
                products: [
                  {
                    totalQty: parseInt(products[i].Quantity),
                    seller_sku: products[i].SellerSKU,
                    qty: parseInt(itemsPerBox),
                    ...((expirationDate && { expirationDate }) || {}),
                  },
                ],
              };
              boxes.push(box);
            }
          }
        }
        for (let index = 0; index < product.totalBoxes; index++) {
          totalProductQty += parseInt(product.itemsPerBox);

          const box = {
            ...(shipmentType === 'SP' && {
              weight: parseInt(product.weight),
              dimensions: {
                x: parseInt(product.dimensions.x),
                y: parseInt(product.dimensions.y),
                z: parseInt(product.dimensions.z),
              },
            }),
            products: [
              {
                totalQty: parseInt(products[i].Quantity),
                seller_sku: products[i].SellerSKU,
                qty: parseInt(product.itemsPerBox),
                ...(product.expirationDate && { expirationDate: product.expirationDate }),
              },
            ],
          };

          boxes.push(box);
        }

        if (totalProductQty !== parseInt(products[i].Quantity)) {
          message.error(
            'You must ship the same quantities that were approved by your Sunken Stone manager',
          );
          return null;
        }

        if (shipmentType === 'SP' && boxes.length > 200) {
          message.error('No more than 200 boxes are allowed');
          return null;
        }

        newShipment = {
          boxes,
          shipmentType,
          pageType,
        };

        if (getFieldValue('contact')) {
          const contactIndex = brand.contacts.findIndex(
            (contact: BolContact): boolean => contact.email === getFieldValue('contact'),
          );

          if (contactIndex !== -1) {
            newShipment.contact = brand.contacts[contactIndex];
          }
        }
      }

      submitForm(newShipment);
    });
  }

  function findProductIsConsumable(sku: string): boolean {
    const product =
      replenishment && replenishment.products.find((p): boolean => p.seller_sku === sku);
    if (!product) return false;
    return product.consumable;
  }

  return (
    <>
      <Alert
        type="error"
        message="No more than 200 boxes allowed"
        showIcon
        style={{ marginTop: 15, marginBottom: 15 }}
      />
      <Form onSubmit={submitLogistics}>
        <Form.Item label="Please select the shipment type:">
          {getFieldDecorator('shipmentType', {
            rules: [{ required: true, message: 'You must select the shipment type' }],
            initialValue: 'SP',
          })(
            <Select>
              <Select.Option value="SP">Small Parcel (SP)</Select.Option>
              <Select.Option value="LTL">Less Than Truckload/Full Truckload (LTL)</Select.Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Select the page type for your labels">
          {getFieldDecorator('pageType', {
            initialValue: 'PackageLabel_Plain_Paper',
            rules: [{ required: true, message: 'Page type is required' }],
          })(
            <Select>
              {pageTypes.map(
                (pageType): JSX.Element => (
                  <Select.Option key={pageType.value} value={pageType.value}>
                    {pageType.label}
                  </Select.Option>
                ),
              )}
            </Select>,
          )}
        </Form.Item>

        {getFieldValue('shipmentType') === 'LTL' &&
          !replenishment.shipments[shipment.ShipmentId].isHazmat && (
            <div>
              <Divider />

              <CreateBrandContactForm
                brand={brand}
                fetchBrand={fetchBrand}
                label="Add new BOL contact"
              />

              <Divider />

              <Form.Item label="Please select a BOL contact:">
                {getFieldDecorator('contact', {
                  rules: [{ required: true, message: 'You must select the BOL contact' }],
                })(
                  <Select>
                    {brand &&
                      brand.contacts.map(
                        (contact: BolContact): JSX.Element => (
                          <Select.Option value={contact.email} key={contact.email}>
                            {contact.name}
                          </Select.Option>
                        ),
                      )}
                  </Select>,
                )}
              </Form.Item>
            </div>
          )}

        <Table
          bordered
          scroll={{ x: true }}
          className="mt-4"
          dataSource={products}
          pagination={{ pageSize: products.length }}
          columns={[
            {
              key: 'img',
              dataIndex: 'SellerSKU',
              render: (text: string): JSX.Element => (
                <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={findImageBySKU(text)} />
              ),
            },
            {
              title: 'SKU',
              dataIndex: 'SellerSKU',
            },
            {
              title: 'Qty',
              dataIndex: 'Quantity',
            },
            {
              title: 'Expiration date',
              render: (text: string, record: any, index: number): JSX.Element =>
                findProductIsConsumable(record.SellerSKU) ? (
                  <Form.Item>
                    {getFieldDecorator(`${index}.expirationDate`, {
                      rules: [{ required: true, message: 'Expiration date is required' }],
                    })(
                      <DatePicker
                        style={{ width: 160 }}
                        disabledDate={isLessThan5Months}
                        placeholder="Select date"
                        defaultPickerValue={moment().add(5, 'months')}
                      />,
                    )}
                  </Form.Item>
                ) : (
                  <Text>Not needed</Text>
                ),
            },
            {
              title: '# of boxes',
              render: (text: string, record: any, index: number): JSX.Element => (
                <>
                  <Form.Item>
                    {getFieldDecorator(`${index}.totalBoxes`, {
                      rules: [{ required: true, message: ' ' }],
                    })(<Input type="number" min="1" style={{ width: 70 }} />)}
                  </Form.Item>
                  {getProductRows(record.SellerSKU).map(
                    (row: any, i: number): JSX.Element => (
                      <Form.Item key={row + index}>
                        {getFieldDecorator(`${index}.totalBoxesExtra[${i}]`, {
                          rules: [{ required: true, message: ' ' }],
                        })(<Input type="number" min="1" style={{ width: 70 }} />)}
                      </Form.Item>
                    ),
                  )}
                </>
              ),
            },
            {
              title: '# of items per box',
              render: (text: string, record: any, index: number): JSX.Element => {
                const replenishmentProduct = findProductBySKU(record.SellerSKU);
                let caseQty = 0;
                if (replenishmentProduct) {
                  caseQty = replenishmentProduct.case_quantity || 0;
                }

                return (
                  <>
                    <Form.Item>
                      {getFieldDecorator(`${index}.itemsPerBox`, {
                        initialValue: caseQty,
                        rules: [
                          { required: true, message: ' ' },
                          {
                            validator: (rule, value, callback): void => {
                              if (caseQty !== parseInt(value)) {
                                return callback(
                                  'No. of items per box must be equal to the previously sent case quantity',
                                );
                              }
                              callback();
                            },
                          },
                        ],
                      })(<Input type="number" min="1" style={{ width: 70 }} disabled />)}
                    </Form.Item>
                    {getProductRows(record.SellerSKU).map(
                      (row: any, i: number): JSX.Element => {
                        const replenishmentProduct = replenishment.products.find(
                          (product): boolean => product.seller_sku === record.SellerSKU,
                        );
                        let caseQty = 0;
                        if (replenishmentProduct) {
                          caseQty = replenishmentProduct.case_quantity || 0;
                        }
                        return (
                          <Form.Item key={row + index}>
                            {getFieldDecorator(`${index}.itemsPerBoxExtra[${i}]`, {
                              initialValue: caseQty,
                              rules: [{ required: true, message: ' ' }],
                            })(<Input type="number" min="1" style={{ width: 70 }} />)}
                          </Form.Item>
                        );
                      },
                    )}
                  </>
                );
              },
            },
            ...(getFieldValue('shipmentType') === 'LTL'
              ? []
              : [
                  {
                    title: 'Weight per box (lb)',
                    render: (text: string, record: any, index: number): JSX.Element => {
                      const replenishmentProduct = findProductBySKU(record.SellerSKU);
                      let weight = null;

                      if (replenishmentProduct && replenishmentProduct.master_carton) {
                        weight = replenishmentProduct.master_carton.weight || null;
                      }

                      return (
                        <>
                          <Form.Item>
                            {getFieldDecorator(`${index}.weight`, {
                              initialValue: weight,
                              rules: [{ required: true, message: ' ' }],
                            })(<Input type="number" style={{ width: 70 }} min="1" max="45" />)}
                          </Form.Item>
                          {getProductRows(record.SellerSKU).map(
                            (row: any, i: number): JSX.Element => (
                              <Form.Item key={row}>
                                {getFieldDecorator(`${index}.weightExtra[${i}]`, {
                                  rules: [{ required: true, message: ' ' }],
                                })(<Input type="number" style={{ width: 70 }} min="1" max="45" />)}
                              </Form.Item>
                            ),
                          )}
                        </>
                      );
                    },
                  },
                  {
                    title: 'Dimensions (in.)',
                    render: (text: string, record: any, index: number): JSX.Element => {
                      const replenishmentProduct = findProductBySKU(record.SellerSKU);
                      let dimX = null;
                      let dimY = null;
                      let dimZ = null;

                      if (
                        replenishmentProduct &&
                        replenishmentProduct.master_carton &&
                        replenishmentProduct.master_carton.dimensions
                      ) {
                        dimX = replenishmentProduct.master_carton.dimensions.x || null;
                        dimY = replenishmentProduct.master_carton.dimensions.y || null;
                        dimZ = replenishmentProduct.master_carton.dimensions.z || null;
                      }

                      return (
                        <>
                          <Row type="flex" justify="space-between" style={{ width: 200 }}>
                            <Form.Item>
                              {getFieldDecorator(`${index}.dimensions.x`, {
                                initialValue: dimX,
                                rules: [{ required: true, message: ' ' }],
                              })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
                            </Form.Item>
                            <Text>x</Text>
                            <Form.Item>
                              {getFieldDecorator(`${index}.dimensions.y`, {
                                initialValue: dimY,
                                rules: [{ required: true, message: ' ' }],
                              })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
                            </Form.Item>
                            <Text>x</Text>
                            <Form.Item>
                              {getFieldDecorator(`${index}.dimensions.z`, {
                                initialValue: dimZ,
                                rules: [{ required: true, message: ' ' }],
                              })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
                            </Form.Item>
                          </Row>
                          {getProductRows(record.SellerSKU).map(
                            (row: any, i: number): JSX.Element => (
                              <Row
                                key={row}
                                type="flex"
                                justify="space-between"
                                style={{ width: 200 }}
                              >
                                <Form.Item>
                                  {getFieldDecorator(`${index}.dimensionsExtra[${i}].x`, {
                                    rules: [{ required: true, message: ' ' }],
                                  })(
                                    <Input style={{ width: 50 }} type="number" min="1" max="108" />,
                                  )}
                                </Form.Item>
                                <Text>x</Text>
                                <Form.Item>
                                  {getFieldDecorator(`${index}.dimensionsExtra[${i}].y`, {
                                    rules: [{ required: true, message: ' ' }],
                                  })(
                                    <Input style={{ width: 50 }} type="number" min="1" max="108" />,
                                  )}
                                </Form.Item>
                                <Text>x</Text>
                                <Form.Item>
                                  {getFieldDecorator(`${index}.dimensionsExtra[${i}].z`, {
                                    rules: [{ required: true, message: ' ' }],
                                  })(
                                    <Input style={{ width: 50 }} type="number" min="1" max="108" />,
                                  )}
                                </Form.Item>
                              </Row>
                            ),
                          )}
                        </>
                      );
                    },
                  },
                ]),
            {
              title: 'Actions',
              render: (text: string, record: any): JSX.Element => (
                <>
                  <Form.Item>
                    <Button onClick={(): any => addNewRow(record.SellerSKU)}>Add new row</Button>
                  </Form.Item>
                  {showDeleteButton(record.SellerSKU) ? (
                    <Form.Item>
                      <Button onClick={(): any => deleteNewRow(record.SellerSKU)}>
                        Delete row
                      </Button>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
          ]}
        />

        <Row type="flex" justify="center">
          <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
            Submit logistics info
          </Button>
        </Row>
      </Form>
    </>
  );
}

export default Form.create<OneSKUPerBoxTableProps>({ name: 'logistics-form' })(OneSKUPerBoxTable);
