import React from 'react';
import { Breadcrumb } from 'antd';

interface LinkItem {
  label: string;
  url: string;
}

export function createLink(label: string, url: string): LinkItem {
  return {
    label,
    url: url === '' ? '' : `/app/${url}`,
  };
}

interface Props {
  items: LinkItem[];
}

export default function BreadcrumbComposer({ items }: Props): JSX.Element {
  return (
    <div
      style={{
        margin: '15px 0px',
      }}
    >
      <Breadcrumb>
        {items.map(
          (item): JSX.Element => {
            {
              return item.url !== '' ? (
                <Breadcrumb.Item key={item.label || ''} href={item.url}>
                  {item.label}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item key={item.label || ''}>{item.label}</Breadcrumb.Item>
              );
            }
          },
        )}
      </Breadcrumb>
    </div>
  );
}
