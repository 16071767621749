import React from 'react';
import { Alert, Typography } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import BoxPictureForm from './box-picture-form';

const { Text } = Typography;

interface UploadFBALabelsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: Function;
}

export default function UploadFBALabels({
  replenishment,
  fetchReplenishment,
}: UploadFBALabelsProps): JSX.Element {
  return (
    <>
      {Object.keys(replenishment.shipments).map(
        (shipmentId: any, index: number): JSX.Element | null => {
          if (replenishment.shipments[shipmentId].photosApproved) return null;

          return (
            <>
              {index === 0 && (
                <>
                  <Alert
                    style={{ marginBottom: 15, marginTop: 15 }}
                    type="error"
                    showIcon
                    message={
                      <Text strong>
                        ** IMPORTANT ** DO NOT APPLY FBA LABELS ACROSS BOX SEAMS, CAN RESULT IN
                        AMAZON LOSING YOUR INVENTORY
                      </Text>
                    }
                  />
                  <Alert
                    style={{ marginTop: 15, marginBottom: 15 }}
                    type="info"
                    showIcon
                    message="Please print and apply labels to the correct cartons then photograph and upload pictures for approval for each shipment."
                  />
                </>
              )}
              <BoxPictureForm
                key={shipmentId}
                replenishment={replenishment}
                fetchReplenishment={(): void => fetchReplenishment()}
                shipmentId={shipmentId}
              />
            </>
          );
        },
      )}
    </>
  );
}
