export enum ReplenishmentSteps {
  ConfirmQuantities = 100,
  ApproveQuantities = 200,
  ConfirmProductLabels = 250,
  SelectPackingAndAddress = 300,
  AddLogisticsInfo = 400,
  UploadTrackingNumbers = 450,
  UploadFBALabels = 500,
  ApproveOrRejectLabels = 600,
  GetUpsOrPalletLabels = 700,
  Finished = 800,
  AdditionalSteps = 805,
}

// Non Hazmat Steps
export enum ShipmentSPSteps {
  AddLogisticsInfo = 400,
  UploadFBALabels = 500,
  ApproveOrRejectLabels = 600,
  GetUPSLabels = 700,
  Finished = 800,
}

export enum ShipmentLTLSteps {
  AddLogisticsInfo = 400,
  UploadFBALabels = 500,
  ApproveOrRejectLabels = 600,
  SubmitPalletInfo = 650,
  GetPalletLabels = 700,
  UploadSignedBOL = 750,
  Finished = 800,
}

// Hazmat Steps
export enum ShipmentHazmatSPSteps {
  AddLogisticsInfo = 400,
  UploadTrackingNumbers = 450,
  UploadFBALabels = 500,
  ApproveOrRejectLabels = 600,
  Finished = 800,
  CompleteInfoInSellerCentral = 805,
}

export enum ShipmentType {
  SP = 'SP',
  LTL = 'LTL',
}
