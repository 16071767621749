import React, { useState, FormEvent } from 'react';
import {
  Button,
  Form,
  Typography,
  Divider,
  Table,
  Input,
  message,
  Row,
  DatePicker,
  Alert,
} from 'antd';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// components
import LTLAlerts from './ltl-alerts';
// utils
import { minAndMaxValidator } from '../../../../utils';
import { ApiWithToken } from '../../../../utils/api';
import { scrollToTop } from '../';

interface SubmitPalletLabelsProps extends FormComponentProps {
  replenishment: ReplenishmentRequest;
  shipmentId: any;
  fetchReplenishment: Function;
}

const { Text } = Typography;

function SubmitPalletLabels({
  replenishment,
  shipmentId,
  form,
  fetchReplenishment,
}: SubmitPalletLabelsProps): JSX.Element {
  const [pallets, setPallets] = useState(new Array(1).fill({}));
  const [loading, setLoading] = useState(false);
  const shipment = replenishment.shipments[shipmentId];

  const { getFieldDecorator, getFieldValue, validateFields } = form;

  function calculatePalletsWeight(index: number): number {
    const pallets = getFieldValue('pallets');

    if (pallets) {
      const pallet = pallets[index];
      return parseInt(pallet.weight || 0) * parseInt(pallet.numberOfPallets);
    }
    return 0;
  }

  async function submitPallets(e: FormEvent): Promise<void> {
    e.preventDefault();
    validateFields(
      async (err, values): Promise<void> => {
        if (err) return;
        if (values.freightReadyDate && values.freightReadyDate.isBefore(moment().startOf('day'))) {
          message.error('The freight ready date cannot be before today.');
          return;
        }

        setLoading(true);

        let totalPallets = 0;
        for (let i = 0; i < pallets.length; i++) {
          totalPallets += parseInt(values.pallets[i].numberOfPallets);
          const palletWeight = parseInt(values.pallets[i].weight);
          if (palletWeight > 1400) {
            message.error("Each pallet's must weigh less than 1400 lbs.");
            return;
          }
        }
        if (totalPallets > 8) {
          message.error('You can only send up to 8 pallets per order');
          return;
        }

        try {
          await ApiWithToken.post(
            `/replenishments/update-pallets/${replenishment._id}/shipment/${shipmentId}`,
            {
              ...(values.freightReadyDate
                ? { freightReadyDate: values.freightReadyDate.utc().format('YYYY-MM-DD') }
                : {}),
              pallets: values.pallets.map((pallet: any): any => ({
                height: parseInt(pallet.height),
                weight: parseInt(pallet.weight),
                numberOfPallets: parseInt(pallet.numberOfPallets),
              })),
            },
          );
          fetchReplenishment();
          scrollToTop();
        } catch (e) {
          message.error('Something went wrong submitting the pallet information');
        }
        setLoading(false);
      },
    );
  }

  return (
    <div>
      {shipment.ltlData && (
        <Alert
          type="info"
          showIcon
          message={
            <Text strong>
              Estimated Pickup Date:{' '}
              {moment.utc(shipment.ltlData.PreviewPickupDate).format('YYYY-MM-DD')}.
            </Text>
          }
          style={{ marginBottom: 20 }}
        />
      )}

      <Divider />
      <Form onSubmit={submitPallets}>
        <LTLAlerts />
        <Divider />
        <h2>Pallet Information</h2>
        {replenishment.shipments[shipmentId].isHazmat === true ? null : (
          <Form.Item label="Freight ready date">
            {getFieldDecorator('freightReadyDate', {
              rules: [{ required: true, message: 'You must select the freight ready date' }],
            })(<DatePicker />)}
          </Form.Item>
        )}
        <Table
          footer={(): JSX.Element => (
            <Button
              onClick={(): void => {
                setPallets(new Array(pallets.length + 1).fill({}));
              }}
            >
              Add another pallet
            </Button>
          )}
          columns={[
            {
              title: 'Dimensions (in.)',
              render: (text, record, index): JSX.Element => (
                <>
                  <Form.Item>
                    48x40x
                    {getFieldDecorator(`pallets[${index}].height`, {
                      rules: [
                        { required: true, message: ' ' },
                        {
                          validator: minAndMaxValidator(1, 72),
                          message: 'Please enter a value between 1 and 72',
                        },
                      ],
                    })(<Input type="number" style={{ width: 60 }} />)}
                  </Form.Item>
                </>
              ),
            },
            {
              title: 'Weight (lb.)',
              render: (text, record, index): JSX.Element => (
                <Form.Item>
                  {getFieldDecorator(`pallets[${index}].weight`, {
                    rules: [
                      { validator: minAndMaxValidator(150, 1400) },
                      { required: true, message: ' ' },
                    ],
                  })(<Input />)}
                </Form.Item>
              ),
            },
            {
              title: '# of pallets',
              render: (text, record, index): JSX.Element => (
                <Form.Item>
                  {getFieldDecorator(`pallets[${index}].numberOfPallets`, {
                    initialValue: 1,
                    rules: [{ validator: minAndMaxValidator(1, 8) }],
                  })(<Input />)}
                </Form.Item>
              ),
            },
            {
              title: 'Total weight',
              render: (text, record, index): JSX.Element => (
                <Text>{calculatePalletsWeight(index)} lbs</Text>
              ),
            },
          ]}
          dataSource={pallets}
        />

        <Row type="flex" justify="center">
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            Submit pallet information
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default Form.create<SubmitPalletLabelsProps>({ name: 'ltl-pallets' })(SubmitPalletLabels);
