import React, { useEffect, useState } from 'react';
import { Button, Table, message, Input, Icon, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { RouteComponentProps } from 'react-router';
import ReactJson from 'react-json-view';
import moment from 'moment';

// utils
import { ApiWithToken } from '../../utils/api';
import { addIdKeyToList } from '../../utils';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
// interfaces
import { Brand } from '../../interfaces/brand';
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdminLogsProps extends RouteComponentProps {}

export default function AdminLogs({ history }: AdminLogsProps): JSX.Element {
  const [brands, setData] = useState<any[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState('');

  async function getData(): Promise<any> {
    setLoading(true);
    const query = [`page=${currentPage}`, `pageSize=${pageSize}`];

    if (extraQueries.length > 0) {
      query.push(...extraQueries);
    }

    if (searchText && searchText.length > 0 && searchField.length > 0) {
      query.push(`searchBy=${searchField}`, `searchFor=${searchText}`);
    }

    try {
      const { data } = await ApiWithToken.get('/admin-logs?' + query.join('&'));
      const newData = addIdKeyToList(data.data);
      setData(newData);
      setTotalData(data.pagination.total);
    } catch (e) {
      message.error('An error occurred fetching the logs. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    getData();
  }, [currentPage, extraQueries, searchField, searchText, pageSize]);

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any): void {
    setSearchText(selectedKeys[0]);
    setSearchField(dataIndex);
    setCurrentPage(1);
    confirm();
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setCurrentPage(1);
            setSearchField('');
            setSearchText('');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const content = (
    <div>
      <Table
        scroll={{ x: 'scroll' }}
        loading={loading}
        dataSource={brands}
        onChange={handleSorter}
        pagination={{
          current: currentPage,
          size: pageSize.toString(),
          showTotal: (total: number): string => `${total} logs`,
          total: totalData,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
          onShowSizeChange: (page, size): void => setPageSize(size),
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'userName',
            key: 'userName',
            sorter: true,
            ...getColumnSearchProps('userName'),
          },
          {
            title: 'Action',
            dataIndex: 'actionName',
            key: 'actionName',
            sorter: true,
            ...getColumnSearchProps('actionName'),
          },
          {
            title: 'URL',
            dataIndex: 'actionUrl',
            key: 'actionUrl',
            sorter: true,
            ...getColumnSearchProps('actionUrl'),
          },
          {
            title: 'Payload',
            dataIndex: 'payload',
            sorter: false,
            key: 'payload',
            render: (text: object, record: any): JSX.Element => (
              <ReactJson collapsed={true} enableClipboard={false} src={text} />
            ),
          },
          {
            title: 'Replenishment',
            dataIndex: 'replenishment',
            key: 'replenishment',
            sorter: false,
            render: (text: object, record: any): JSX.Element => (
              <ReactJson collapsed={true} enableClipboard={false} src={text} />
            ),
          },
          {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (text, record): JSX.Element => (
              <Text>{moment(record.created_at).format('DD-MM-YYYY HH:mm')}</Text>
            ),
          },
        ]}
      />
    </div>
  );

  const links = [createLink('Admin Logs', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Logs</h1>
        {content}
      </div>
    </>
  );
}
