import React from 'react';
import { Layout } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
// routing
import PrivateRoute from '../../components/routing/private-route';
// components
import TextLogo from '../../components/text-logo';
import AppHeader from '../../components/app-header';
import SiderMenu from './components/sider-menu';
// pages
import ManageUserPage from '../users/manage';
import UsersPage from '../users';
import DashboardPage from '../dashboard';
import BrandsPage from '../brands';
import ProductsPage from '../products';
import ProductViewPage from '../products/view';
import UserViewPage from '../users/view';
import BrandViewPage from '../brands/view';
import ReplenishmentRequestsPage from '../replenishment-requests';
import ManageReplenishmentRequests from '../replenishment-requests/manage';
import ViewReplenishmentRequestPage from '../replenishment-requests/view';
import EmailsPage from '../emails';
import NotificationsPage from '../notifications';
import AdminLogsPge from '../admin-logs';
import ReplenishmentAdminPage from '../replenishment-admin-page';
import CreateReplenishmentForBrandPage from '../create-replenishment-for-brand';

const { Content, Footer, Sider } = Layout;

export default function AppPage(props: RouteComponentProps): JSX.Element {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible>
        <TextLogo />
        <SiderMenu {...props} />
      </Sider>

      <Layout>
        <AppHeader history={props.history} />

        <Content style={{ margin: '0 16px' }}>
          <PrivateRoute exact component={DashboardPage} path="/app/dashboard" />

          <PrivateRoute
            exact
            component={UsersPage}
            path="/app/users"
            roles={['admin', 'manager']}
          />

          <PrivateRoute
            component={ManageUserPage}
            path={['/app/users/create/:id?', '/app/users/edit/:id']}
            roles={['admin', 'manager']}
          />
          <PrivateRoute
            exact
            component={UserViewPage}
            path={['/app/users/view/:id', '/app/profile/:id']}
            roles={['admin']}
          />

          <PrivateRoute exact component={EmailsPage} path={['/app/emails']} roles={['admin']} />

          <PrivateRoute
            exact
            component={NotificationsPage}
            path={['/app/notifications']}
            roles={['admin']}
          />

          <PrivateRoute
            exact
            component={BrandsPage}
            path="/app/brands"
            roles={['admin', 'manager']}
          />
          <PrivateRoute
            exact
            component={BrandViewPage}
            path="/app/brands/view/:id"
            roles={['admin', 'manager']}
          />

          <PrivateRoute
            exact
            component={ReplenishmentRequestsPage}
            path="/app/replenishment-requests"
            roles={['admin', 'brand', 'manager']}
          />

          <PrivateRoute
            component={ManageReplenishmentRequests}
            path={'/app/replenishment-requests/create'}
            exact
            roles={['admin', 'manager']}
          />

          <PrivateRoute
            component={CreateReplenishmentForBrandPage}
            path={'/app/replenishment-requests/create/:brandId'}
            exact
            roles={['admin', 'manager']}
          />

          <PrivateRoute
            component={ReplenishmentAdminPage}
            path="/app/replenishment-requests/:id/admin"
            roles={['admin', 'manager']}
          />

          <PrivateRoute
            component={ViewReplenishmentRequestPage}
            path="/app/replenishment-requests/view/:id"
            roles={['admin', 'brand', 'manager']}
          />

          <PrivateRoute
            exact
            component={ProductsPage}
            path="/app/products"
            roles={['admin', 'manager']}
          />

          <PrivateRoute
            component={ProductViewPage}
            path="/app/products/view/:id"
            roles={['admin', 'manager']}
          />

          <PrivateRoute exact component={AdminLogsPge} path="/app/admin-logs" roles={['admin']} />
        </Content>

        <Footer style={{ textAlign: 'center' }}>
          PO Portal &copy;2020 Created by Sunken Stone&apos;s dev team
        </Footer>
      </Layout>
    </Layout>
  );
}
