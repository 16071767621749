import React, { useState, useEffect } from 'react';
import { Table, Tag, message } from 'antd';
import moment from 'moment';
// interfaces
import { Notification } from '../../interfaces/notification';
// utils
import { ApiWithToken } from '../../utils/api';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';
import { addIdKeyToList } from '../../utils';

export default function NotificationsPage(): JSX.Element {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);

  useEffect((): void => {
    async function fetchNotifications(): Promise<void> {
      setLoading(true);
      const query = [`page=${currentPage}`, `pageSize=${pageSize}`];

      if (extraQueries.length > 0) {
        query.push(...extraQueries);
      }

      try {
        const { data } = await ApiWithToken.get('/notifications/all?' + query.join('&'));
        const newNotifications = addIdKeyToList(data.data);
        setNotifications(newNotifications);
        setTotalNotifications(data.pagination.total);
      } catch (e) {
        message.error('An error occurred fetching the notifications. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    fetchNotifications();
  }, [currentPage, pageSize, extraQueries]);

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const links = [createLink('Notifications', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Notifications</h1>
        <Table
          onChange={handleSorter}
          loading={loading}
          dataSource={notifications}
          pagination={{
            current: currentPage,
            size: pageSize.toString(),
            showTotal: (total: number): string => `${total} notifications`,
            total: totalNotifications,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
            onShowSizeChange: (page, size): void => setPageSize(size),
          }}
          columns={[
            {
              title: 'For',
              dataIndex: 'user.email',
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'Type',
              dataIndex: 'type',
              render: (type: string): JSX.Element =>
                type === 'success' ? (
                  <Tag color="green">Success</Tag>
                ) : type === 'warning' ? (
                  <Tag color="yellow">Warning</Tag>
                ) : (
                  <Tag color="red">Error</Tag>
                ),
              sorter: true,
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'Created at',
              dataIndex: 'created_at',
              sorter: true,
              render: (createdAt: number): string =>
                moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
            },
          ]}
        />
      </div>
    </>
  );
}
