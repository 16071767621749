import React from 'react';
import { Collapse, Typography, Alert } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// utils
import { ShipmentLTLSteps, ShipmentType } from '../../../../enums/replenishment-request.enums';
// components
import GetUpsLabels from './get-ups-labels';
import SubmitPalletLabels from './submit-pallet-labels';
import GetPalletLabels from './get-pallet-labels';
import UploadSignedBol from './upload-signed-bol';

const { Text } = Typography;
const { Panel } = Collapse;

interface GetUpsOrPalletLabelsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: Function;
}

export default function GetUpsOrPalletLabels({
  replenishment,
  fetchReplenishment,
}: GetUpsOrPalletLabelsProps): JSX.Element | null {
  const shipmentIds: any[] = Object.keys(replenishment.shipments);

  function handleLTLShipment(shipmentId: any): JSX.Element | null {
    const shipment = replenishment.shipments[shipmentId];

    switch (shipment.step) {
      case ShipmentLTLSteps.SubmitPalletInfo:
        return (
          <SubmitPalletLabels
            replenishment={replenishment}
            shipmentId={shipmentId}
            fetchReplenishment={fetchReplenishment}
          />
        );

      case ShipmentLTLSteps.GetPalletLabels:
        return (
          <GetPalletLabels
            replenishment={replenishment}
            shipmentId={shipmentId}
            fetchReplenishment={fetchReplenishment}
          />
        );

      case ShipmentLTLSteps.UploadSignedBOL:
        return <UploadSignedBol replenishment={replenishment} shipmentId={shipmentId} />;
    }

    return null;
  }

  function handleHazmatShipment(shipmentId: any): JSX.Element | null {
    const shipment = replenishment.shipments[shipmentId];

    if (shipment.shipmentType === ShipmentType.LTL) {
      switch (shipment.step) {
        case ShipmentLTLSteps.SubmitPalletInfo:
          return (
            <SubmitPalletLabels
              replenishment={replenishment}
              shipmentId={shipmentId}
              fetchReplenishment={fetchReplenishment}
            />
          );
        case ShipmentLTLSteps.GetPalletLabels:
          return (
            <GetPalletLabels
              replenishment={replenishment}
              shipmentId={shipmentId}
              fetchReplenishment={fetchReplenishment}
            />
          );
      }
    }
    return <Alert showIcon type="success" message={<Text>This shipment is complete.</Text>} />;
  }

  function handleInformationForShipment(shipmentId: any): JSX.Element | null {
    const shipment = replenishment.shipments[shipmentId];

    let shipmentType;

    if (shipment.isHazmat) {
      shipmentType = 'hazmat';
    } else if (shipment.shipmentType === 'LTL') {
      shipmentType = 'LTL';
    } else if (shipment.shipmentType === 'SP') {
      shipmentType = 'SP';
    }

    switch (shipmentType) {
      case 'LTL':
        return handleLTLShipment(shipmentId);
      case 'SP':
        return (
          <GetUpsLabels
            replenishment={replenishment}
            shipmentId={shipmentId}
            fetchReplenishment={fetchReplenishment}
          />
        );
      case 'hazmat':
        return handleHazmatShipment(shipmentId);
    }

    return null;
  }

  return (
    <>
      {shipmentIds.map(
        (shipmentId: any): JSX.Element => (
          <Collapse bordered={false} key={shipmentId}>
            <Panel header={`Shipment ${shipmentId}`} key={shipmentId + 1}>
              {handleInformationForShipment(shipmentId)}
            </Panel>
          </Collapse>
        ),
      )}
    </>
  );
}
