import React, { useState } from 'react';
import { Alert, Button, Icon, Tree, Typography } from 'antd';
import { AntTreeNode } from 'antd/lib/tree';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
// components
import EditShipmentPropertyModal from './edit-shipment-property-modal';
import Spacer from '../../../components/spacer';
import AddShipmentPlanModal from './add-shipment-modal';
import RemoveShipmentsModal from './remove-shipment-modal';

const { Paragraph } = Typography;
const { TreeNode } = Tree;

interface EditShipmentsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
}

function EditShipments({ replenishment, fetchReplenishment }: EditShipmentsProps): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false);
  const [addShipmentModalVisible, setAddShipmentPlanModalVisible] = useState(false);
  const [removeShipmentsModalVisible, setRemoveShipmentsModalVisible] = useState(false);
  const [propertyToEdit, setPropertyToEdit] = useState('');
  const [shipmentToEdit, setShipmentToEdit] = useState('');
  const [typeToEdit, setTypeToEdit] = useState<'string' | 'boolean'>('string');
  const [labelToEdit, setLabelToEdit] = useState('');

  const shipmentIds = (replenishment.shipments && Object.keys(replenishment.shipments)) || [];
  const editableProps = { key: 'editable', switcherIcon: <Icon type="edit" />, isLeaf: true };

  if (shipmentIds && shipmentIds.length === 0) {
    return (
      <>
        <AddShipmentPlanModal
          visible={addShipmentModalVisible}
          onSuccess={fetchReplenishment}
          onClose={(): void => setAddShipmentPlanModalVisible(false)}
          replenishment={replenishment}
        />

        <Alert
          type="info"
          showIcon
          message={
            <>
              <Paragraph>There are no shipments in this replenishment request.</Paragraph>
              <Button onClick={(): void => setAddShipmentPlanModalVisible(true)}>
                Add a new shipment
              </Button>
            </>
          }
        />
      </>
    );
  }

  function handleNodeClick(node: AntTreeNode): void {
    if (node.props.eventKey === editableProps.key && node.props.className) {
      const [property, shipmentId, label, propertyType]: any[] = node.props.className.split('|'); // I had to use className to pass values from the render to this functionality

      setPropertyToEdit(property);
      setShipmentToEdit(shipmentId);
      setLabelToEdit(label);
      setTypeToEdit(propertyType);
      setModalVisible(true);
    }
  }

  const shipmentsContent = shipmentIds.map(
    (shipmentId): JSX.Element => {
      const shipment = replenishment.shipments[shipmentId];

      const verificationPhotosContent = shipment.verificationPhotos ? (
        shipment.verificationPhotos.map(
          (vp, index): JSX.Element => (
            <TreeNode title={`Photo ${index + 1}`} key={`photo-${index}`}>
              <TreeNode title={vp} />
            </TreeNode>
          ),
        )
      ) : (
        <TreeNode title="No verification photos submitted yet" />
      );

      return (
        <TreeNode title={`Shipment ${shipmentId}`} key={`shipment-edit-${shipmentId}`}>
          <TreeNode title="Shipment Name">
            <TreeNode
              {...editableProps}
              title={shipment.name}
              className={`name|${shipmentId}|Shipment Name`}
            />
          </TreeNode>

          <TreeNode title="Labels">
            <TreeNode title="FBA Labels URL">
              <TreeNode
                {...editableProps}
                title={shipment.fbaLabels}
                className={`fbaLabels|${shipmentId}|FBA Labels URL|string`}
              />
            </TreeNode>
            <TreeNode title="Thermal FBA Labels URL">
              <TreeNode
                {...editableProps}
                title={shipment.fbaThermalLabels}
                className={`fbaThermalLabels|${shipmentId}|Thermal FBA Labels URL|string`}
              />
            </TreeNode>
            <TreeNode title="UPS Labels URL">
              <TreeNode
                {...editableProps}
                title={shipment.upsLabels}
                className={`upsLabels|${shipmentId}|UPS Labels URL|string`}
              />
            </TreeNode>
            <TreeNode title="Thermal UPS Labels URL">
              <TreeNode
                {...editableProps}
                title={shipment.upsThermalLabels}
                className={`upsThermalLabels|${shipmentId}|Thermal UPS Labels URL|string`}
              />
            </TreeNode>
            <TreeNode title="Pallet Labels URL">
              <TreeNode
                {...editableProps}
                title={shipment.palletLabels}
                className={`palletLabels|${shipmentId}|Pallet Labels URL|string`}
              />
            </TreeNode>
            <TreeNode title="Thermal Pallet Labels URL">
              <TreeNode
                {...editableProps}
                title={shipment.palletThermalLabels}
                className={`palletThermalLabels|${shipmentId}|Thermal Pallet Labels URL|string`}
              />
            </TreeNode>
            <TreeNode title="Bill Of Lading URL">
              <TreeNode
                {...editableProps}
                title={shipment.billOfLading}
                className={`billOfLading|${shipmentId}|Bill Of Lading URL|string`}
              />
            </TreeNode>
            <TreeNode title="Stamped BOL URL">
              <TreeNode
                {...editableProps}
                title={shipment.stampedBillOfLading}
                className={`stampedBillOfLading|${shipmentId}|Stamped BOL URL|string`}
              />
            </TreeNode>
          </TreeNode>

          <TreeNode title="Validation values">
            <TreeNode title="Finished">
              <TreeNode
                {...editableProps}
                title={(shipment.finished === true).toString()}
                className={`finished|${shipmentId}|Finished|boolean`}
              />
            </TreeNode>
          </TreeNode>

          <TreeNode title="Verification Photos">{verificationPhotosContent}</TreeNode>
        </TreeNode>
      );
    },
  );

  return (
    <div>
      <EditShipmentPropertyModal
        replenishment={replenishment}
        property={propertyToEdit}
        shipmentId={shipmentToEdit}
        propertyType={typeToEdit}
        label={labelToEdit}
        visible={modalVisible}
        onSuccess={fetchReplenishment}
        onClose={(): void => setModalVisible(false)}
      />

      <AddShipmentPlanModal
        visible={addShipmentModalVisible}
        onSuccess={fetchReplenishment}
        onClose={(): void => setAddShipmentPlanModalVisible(false)}
        replenishment={replenishment}
      />

      <RemoveShipmentsModal
        visible={removeShipmentsModalVisible}
        onSuccess={fetchReplenishment}
        onClose={(): void => setRemoveShipmentsModalVisible(false)}
        replenishment={replenishment}
      />

      <Spacer height={15} />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          icon="plus"
          size="small"
          onClick={(): void => setAddShipmentPlanModalVisible(true)}
        >
          Add a shipment
        </Button>

        <Spacer width={10} />

        <Button
          type="danger"
          icon="delete"
          size="small"
          onClick={(): void => setRemoveShipmentsModalVisible(true)}
        >
          Remove a shipment
        </Button>
      </div>

      <Spacer height={15} />

      <Alert
        type="info"
        showIcon
        message={
          <>
            <h4>Note:</h4> <p>You can click on some of the values of a shipment to edit them</p>
          </>
        }
      />

      <Spacer height={10} />

      <Tree showLine autoExpandParent onClick={(e, node): void => handleNodeClick(node)}>
        {shipmentsContent}
      </Tree>
    </div>
  );
}

export default EditShipments;
