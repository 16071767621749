import React from 'react';
import { Button, Typography, Spin, Alert, message, Divider } from 'antd';
import moment from 'moment';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// assets
import PalletLabelsImg from '../../../../assets/pallet-labels-img.png';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { scrollToTop } from '../index';

interface GetPalletLabelsProps {
  replenishment: ReplenishmentRequest;
  shipmentId: any;
  fetchReplenishment: Function;
}

const { Text, Paragraph } = Typography;

const Generating = (): JSX.Element => (
  <>
    <Spin style={{ marginLeft: 6, marginRight: 6 }} /> <Text>Generating, please wait...</Text>
  </>
);

export default function GetPalletLabels({
  replenishment,
  shipmentId,
  fetchReplenishment,
}: GetPalletLabelsProps): JSX.Element {
  const shipment = replenishment.shipments[shipmentId];

  async function downloadPalletLabels(): Promise<void> {
    const shipment = replenishment.shipments[shipmentId];

    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment &&
          replenishment._id}/mark-shipment-finished/shipment/${shipmentId}`,
      );

      let a = document.createElement('a');
      a.href = `${shipment.palletLabels}`;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('Something went wrong downloading the pallet labels');
    }
  }

  return (
    <>
      {shipment.ltlData && (
        <Alert
          type="info"
          showIcon
          message={
            <Text strong>
              Estimated Pickup Date:{' '}
              {moment.utc(shipment.ltlData.PreviewPickupDate).format('YYYY-MM-DD')}.
            </Text>
          }
          style={{ marginBottom: 20 }}
        />
      )}

      <Divider />

      <Alert
        type="info"
        showIcon
        style={{ marginBottom: 30 }}
        message={
          <>
            <Paragraph style={{ fontSize: 18 }} strong>
              Instructions on pallet label application
            </Paragraph>
            <Paragraph>
              One (1) label is required to be applied on four (4) sides of each pallet.
            </Paragraph>
            <div style={{ marginTop: 15 }}>
              <img src={PalletLabelsImg} />
              <Text>FBA Pallet Label</Text>
            </div>
          </>
        }
      />
      <Paragraph strong style={{ marginTop: 25 }}>
        {shipment.palletLabels ? (
          <>
            <Paragraph>Please apply the pallet labels:</Paragraph>
            <Button
              style={{ display: 'block', marginTop: 20 }}
              onClick={(): Promise<void> => downloadPalletLabels()}
              type="link"
            >
              {shipment.palletLabels}
            </Button>
          </>
        ) : (
          <>
            <Text strong>
              Pallet Labels: <Generating />
            </Text>
          </>
        )}
      </Paragraph>
    </>
  );
}
