import React from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
// interfaces
import { BolContact, StonehengeBrand } from '../../../../../interfaces/brand';
// utils
import { checkUserBelongsToRoles } from '../../../../../utils';
import { ApiWithToken } from '../../../../../utils/api';

interface BrandContactsTableProps {
  brand: StonehengeBrand;
  fetchBrand: () => Promise<void>;
}

const checkUserRoles = checkUserBelongsToRoles();

export default function BrandContactsTable(props: BrandContactsTableProps): JSX.Element | null {
  const { brand, fetchBrand } = props;

  async function deleteContact(contact: BolContact): Promise<void> {
    try {
      const newContacts = [...brand.contacts];
      const contactIndex = newContacts.findIndex(
        (a: BolContact): boolean =>
          a.name === contact.name && a.phone === contact.phone && a.email === contact.email,
      );
      newContacts.splice(contactIndex, 1);
      await ApiWithToken.put(`/brands/${brand.brand_code}`, {
        contacts: newContacts,
      });
      fetchBrand();
      message.success('The contact was successfully deleted.');
    } catch (e) {
      message.error('An error occurred deleting the contact');
    }
  }

  return (
    <Table
      columns={[
        { title: 'Name', dataIndex: 'name' },
        { title: 'Phone', dataIndex: 'phone' },
        { title: 'Email', dataIndex: 'email' },
        {
          title: 'Actions',
          render: (contact: BolContact): JSX.Element => (
            <span>
              {checkUserRoles(['admin']) && (
                <Popconfirm
                  placement="left"
                  title="Are you sure you want to delete this contact?"
                  onConfirm={(): Promise<any> => deleteContact(contact)}
                >
                  <Button size="small" icon="delete" type="danger">
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </span>
          ),
        },
      ]}
      dataSource={brand.contacts}
      rowKey="name"
    />
  );
}
