import React, { useEffect, useState } from 'react';
import { Typography, Alert, Spin, message, Button } from 'antd';
// interfaces
import {
  ReplenishmentRequest,
  ReplenishmentShipment,
} from '../../../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { scrollToTop } from '../';

const { Text } = Typography;

interface GetUpsLabelsProps {
  replenishment: ReplenishmentRequest;
  shipmentId: string;
  fetchReplenishment: Function;
}

export default function GetUpsLabels({
  replenishment,
  shipmentId,
  fetchReplenishment,
}: GetUpsLabelsProps): JSX.Element | null {
  const [loadingThermalLabel, setLoadingThermalLabel] = useState(false);
  const [displayGenerateButton, setDisplayGenerateButton] = useState(false);
  const [generating, setGenerating] = useState(false);

  useEffect((): any => {
    const timer = setTimeout((): void => {
      if (!replenishment.shipments[shipmentId].upsLabels) {
        setDisplayGenerateButton(true);
      }
    }, 60 * 1000);
    return (): void => clearTimeout(timer);
  }, []);

  async function downloadUpsLabels(shipmentId: string): Promise<void> {
    const shipment = replenishment.shipments[shipmentId];

    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment &&
          replenishment._id}/mark-shipment-finished/shipment/${shipmentId}`,
      );

      let a = document.createElement('a');
      a.href = `${shipment.upsLabels}`;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('Something went wrong downloading the ups labels');
    }
  }

  async function generateThermalLabel(shipmentId: string): Promise<void> {
    setLoadingThermalLabel(true);
    const inboundShipment: ReplenishmentShipment =
      replenishment && replenishment.shipments[shipmentId];

    try {
      await ApiWithToken.post(
        `/replenishments/generate-thermal-labels/${replenishment &&
          replenishment._id}/shipment/${shipmentId}`,
        {
          labelUrl: inboundShipment.upsLabels,
          labelType: 'upsThermalLabels',
          pageType: inboundShipment.pageType || '',
        },
      );
    } catch (e) {
      message.error('Something went wrong generating the thermal label');
    } finally {
      setLoadingThermalLabel(false);
    }
  }

  async function getUpsLabels(): Promise<void> {
    setGenerating(true);
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment._id}/shipment/${shipmentId}/get-ups-labels`,
      );
    } catch (e) {
      message.error('Error refreshing FBA labels');
    }
  }

  function handleInformationForShipment(shipmentId: any): JSX.Element {
    return (
      <>
        <Alert
          showIcon
          type="info"
          message={<>Please apply the UPS labels to the corresponding boxes</>}
          style={{ marginTop: 20, marginBottom: 20 }}
        />
        Amazon Carton UPS Labels:{' '}
        {replenishment.shipments[shipmentId].upsLabels ? (
          <Button
            style={{ display: 'block', marginTop: 10 }}
            loading={loadingThermalLabel}
            type="link"
            onClick={(): Promise<void> => downloadUpsLabels(shipmentId)}
          >
            {replenishment.shipments[shipmentId].upsLabels}
          </Button>
        ) : displayGenerateButton && !generating ? (
          <Button style={{ marginLeft: 5 }} onClick={getUpsLabels}>
            Generate UPS labels
          </Button>
        ) : (
          <>
            <Spin style={{ marginLeft: 6, marginRight: 6 }} />{' '}
            <Text>Generating, please wait...</Text>
          </>
        )}
      </>
    );
  }

  return <>{handleInformationForShipment(shipmentId)}</>;
}
