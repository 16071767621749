import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Typography,
  Collapse,
  Upload,
  Icon,
  Button,
  message,
  Result,
  Spin,
} from 'antd';
import QRCode from 'qrcode.react';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// config
import { env } from '../../../../config/client';
import { ApiWithToken } from '../../../../utils/api';
import { scrollToTop } from '../';

interface BoxPictureFormProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
  shipmentId: any;
}

const { Panel } = Collapse;

const { Paragraph, Text } = Typography;

function BoxPictureForm({
  replenishment,
  fetchReplenishment,
  shipmentId,
}: BoxPictureFormProps): JSX.Element {
  const [displayGenerateButton, setDisplayGenerateButton] = useState(false);
  const [generating, setGenerating] = useState(false);
  const uploadPictureURL = `${env.appUrl}/upload-labels-picture/${replenishment._id}/shipment/${shipmentId}`;

  useEffect((): any => {
    const timer = setTimeout((): void => {
      if (!replenishment.shipments[shipmentId].fbaLabels) {
        setDisplayGenerateButton(true);
      }
    }, 60 * 1000);
    return (): void => clearTimeout(timer);
  }, []);

  if (replenishment.shipments[shipmentId].awaitingPicturesApproval) {
    return (
      <Result status="success" subTitle={`Verification photos for shipment ${shipmentId} sent!`} />
    );
  }

  async function submitForApproval(): Promise<void> {
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment._id}/request-photos-approval/shipment/${shipmentId}`,
      );
      message.success('Photos received successfully!');
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error(
        e.response.data.message ||
          'There was an error submitting your photos. Please try again later.',
      );
    }
  }

  async function markShipmentFinished(): Promise<void> {
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment._id}/shipments/${shipmentId}/finish`,
      );
      message.success('Shipment marked as finished');
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('There was an error marking the shipment as finished. Please try again later.');
    }
  }

  async function getFbaLabels(): Promise<void> {
    setGenerating(true);
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment._id}/shipment/${shipmentId}/get-fba-labels`,
      );
    } catch (e) {
      message.error('Error refreshing FBA labels');
    }
  }

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }): JSX.Element => (
          <Icon type="caret-right" rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={`Shipment ${shipmentId}`} key="1">
          <Paragraph strong>
            Amazon Carton FBA Labels:{' '}
            {replenishment.shipments[shipmentId].fbaLabels ? (
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={replenishment.shipments[shipmentId].fbaLabels}
                >
                  {replenishment.shipments[shipmentId].fbaLabels}
                </a>
              </>
            ) : displayGenerateButton && !generating ? (
              <Button style={{ marginLeft: 5 }} onClick={getFbaLabels}>
                Generate FBA labels
              </Button>
            ) : (
              <>
                <Spin style={{ marginLeft: 6, marginRight: 6 }} />{' '}
                <Text>Generating, please wait...</Text>
              </>
            )}
          </Paragraph>

          {replenishment.brand.requiresVerificationPhotos && (
            <Row
              style={{ marginTop: 15, minHeight: 200 }}
              type="flex"
              align="middle"
              justify="center"
            >
              <Col xs={24} md={8}>
                <Form style={{ marginTop: 15 }}>
                  <Form.Item
                    label={
                      replenishment.shipments[shipmentId].shipmentType === 'LTL'
                        ? 'Please upload a photo of the FBA labels attached to the boxes on the pallet:'
                        : 'Please upload a photo of the FBA labels attached to the boxes:'
                    }
                  >
                    <div className="dropbox">
                      <Upload.Dragger
                        name="file"
                        action={`${env.apiUrl}/replenishments/upload-labels-picture/${replenishment._id}/shipment/${shipmentId}`}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Click this area to upload photos</p>
                      </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Form>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                OR
              </Col>
              <Col
                xs={24}
                md={8}
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Paragraph>Scan this QR code from your phone and upload the photos</Paragraph>
                <QRCode value={uploadPictureURL} size={160} />
                <a style={{ marginTop: 15, marginBottom: 10 }} href={uploadPictureURL}>
                  {uploadPictureURL}
                </a>
              </Col>
            </Row>
          )}

          {replenishment.brand.requiresVerificationPhotos && (
            <Row type="flex" justify="center">
              <Button type="primary" size="large" onClick={submitForApproval}>
                Submit for approval
              </Button>
            </Row>
          )}

          {replenishment.shipments[shipmentId].isHazmat &&
            !replenishment.brand.requiresVerificationPhotos && (
              <Row type="flex" justify="center">
                <Button type="primary" size="large" onClick={markShipmentFinished}>
                  Mark shipment as finished
                </Button>
              </Row>
            )}
        </Panel>
      </Collapse>
    </>
  );
}

export default BoxPictureForm;
