import React from 'react';
import { Alert, Typography } from 'antd';

const { Text } = Typography;

export default function LTLAlerts(): JSX.Element {
  return (
    <Alert
      showIcon
      type="warning"
      message={
        <div>
          <Text strong>Please note:</Text>
          <ol>
            <li>Each pallet must weigh over 150 lbs and under 1400 lbs</li>
            <li>Each pallet&apos;s height must be under 72&quot;</li>
            <li>The pallets must not be stacked</li>
            <li>Each box on the pallet must weigh less than 45 lbs</li>
          </ol>
        </div>
      }
    />
  );
}
