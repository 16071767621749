import React, { useEffect, useState } from 'react';
import { Table, message, Select, Button, Input, Icon } from 'antd';
import { RouteComponentProps } from 'react-router';
import Highlighter from 'react-highlight-words';
// utils
import { ApiWithToken } from '../../utils/api';
import { addIdKeyToList, checkUserBelongsToRoles } from '../../utils';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
// interfaces
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';
import { StonehengeProduct } from '../../interfaces/product';

const checkUserRole = checkUserBelongsToRoles();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProductsPageProps extends RouteComponentProps<{ id: string }> {}

export default function ProductsPage({ history }: ProductsPageProps): JSX.Element {
  const searchParam = new URLSearchParams(history.location.search).get('q');

  const [products, setProducts] = useState<StonehengeProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [searchText, setSearchText] = useState(searchParam || '');
  const [searchField, setSearchField] = useState(searchParam || '');

  async function getAllProducts(): Promise<any> {
    setLoading(true);
    const query = [`page=${currentPage}`, `pageSize=${pageSize}`];

    if (extraQueries.length > 0) {
      query.push(...extraQueries);
    }

    if (searchText && searchText.length > 0 && searchField.length > 0) {
      query.push(`sku=${encodeURIComponent(searchText)}`);
    }

    try {
      const { data } = await ApiWithToken.get('/products?' + query.join('&'));
      const newUsers = addIdKeyToList(data.data);
      setProducts(newUsers);
      setTotalProducts(data.pagination.total);
    } catch (e) {
      message.error('An error occurred fetching the products. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  async function updateConsumableFlag(value: string, productId: string): Promise<void> {
    try {
      await ApiWithToken.put(`/products/${encodeURIComponent(productId)}/consumable`, {
        consumable: value === 'true',
      });
      message.success('The consumable status was successfully updated');
      getAllProducts();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  async function updateIgnoreFlag(value: string, sku: string): Promise<void> {
    try {
      await ApiWithToken.put(`/products/${encodeURIComponent(sku)}/ignore`, {
        ignore: value === 'true',
      });

      message.success('The ignore status was successfully updated');
      getAllProducts();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  async function updateHazmatFlag(value: string, sku: string): Promise<void> {
    try {
      await ApiWithToken.put(`/products/${encodeURIComponent(sku)}/hazmat`, {
        hazmat: value === 'true',
      });

      message.success('The hazmat status was successfully updated');
      getAllProducts();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  useEffect((): void => {
    getAllProducts();
  }, [currentPage, pageSize, extraQueries, searchText]);

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any): void {
    setSearchText(selectedKeys);
    setCurrentPage(1);
    history.replace(`/app/products?q=${selectedKeys}`);
    confirm();
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={searchField}
          onChange={(e): void => setSearchField(e.target.value || '')}
          onPressEnter={(): void => handleSearch(searchField, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={(): void => handleSearch(searchField, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setCurrentPage(1);
            setSearchField('');
            setSearchText('');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const links = [createLink('Products', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Products</h1>
        <Table
          dataSource={products}
          loading={loading}
          onChange={handleSorter}
          pagination={{
            current: currentPage,
            size: pageSize.toString(),
            showTotal: (total: number): string => `${total} products`,
            total: totalProducts,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
            onShowSizeChange: (page, size): void => setPageSize(size),
          }}
          columns={[
            {
              title: 'SKU',
              dataIndex: 'seller_sku',
              key: 'seller_sku',
              ...getColumnSearchProps('seller_sku'),
              render: (text: string, product: StonehengeProduct): JSX.Element => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(): void =>
                    history.push(`/app/products/view/${encodeURIComponent(product.seller_sku)}`)
                  }
                >
                  {text}
                </a>
              ),
              sorter: true,
            },
            {
              title: 'Ignore',
              dataIndex: 'ignore',
              key: 'ignore',
              render: (text: any, record: StonehengeProduct): JSX.Element => (
                <Select
                  defaultValue={record.ignore ? record.ignore.toString() : 'false'}
                  style={{ width: 80 }}
                  onChange={(newValue: string): any =>
                    updateIgnoreFlag(newValue, record.seller_sku)
                  }
                  value={record.ignore.toString()}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              ),
            },
            {
              title: 'Consumable',
              dataIndex: 'consumable',
              key: 'consumable',
              render: (text: any, record: StonehengeProduct): JSX.Element => (
                <Select
                  defaultValue={record.consumable ? record.consumable.toString() : 'false'}
                  style={{ width: 80 }}
                  onChange={(newValue: string): any =>
                    updateConsumableFlag(newValue, record.seller_sku)
                  }
                  value={record.consumable.toString()}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              ),
            },
            {
              title: 'Hazmat',
              dataIndex: 'hazmat',
              key: 'hazmat',
              render: (text: any, record: StonehengeProduct): JSX.Element => (
                <Select
                  defaultValue={record.hazmat ? record.hazmat.toString() : 'false'}
                  style={{ width: 80 }}
                  onChange={(newValue: string): any =>
                    updateHazmatFlag(newValue, record.seller_sku)
                  }
                  value={(record.hazmat || false).toString()}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              ),
            },
            ...((checkUserRole(['admin', 'manager']) && [
              {
                title: 'Actions',
                render: (record: StonehengeProduct): JSX.Element => (
                  <Button
                    size="small"
                    icon="eye"
                    onClick={(): void =>
                      history.push(`/app/products/view/${encodeURIComponent(record.seller_sku)}`)
                    }
                  >
                    View
                  </Button>
                ),
              },
            ]) ||
              []),
          ]}
        />
      </div>
    </>
  );
}
