import React from 'react';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export default function TextLogo(): JSX.Element {
  return (
    <Paragraph
      strong
      style={{
        fontSize: 18,
        marginTop: 15,
        color: 'white',
        textAlign: 'center',
      }}
    >
      PO Portal
    </Paragraph>
  );
}
