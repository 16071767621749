import React from 'react';
import { Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { checkUserBelongsToRoles } from '../../../utils';

const { SubMenu } = Menu;

const checkUserRole = checkUserBelongsToRoles();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SiderMenuProps extends RouteComponentProps {}

export default function SiderMenu({ match, history }: SiderMenuProps): JSX.Element {
  function goToLink(path: string): void {
    if (path === history.location.pathname) {
      window.location.reload();
    }
  }

  return (
    <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline">
      <Menu.Item key="0">
        <Icon type="pie-chart" />
        <span>Dashboard</span>
        <Link to="/app/dashboard" onClick={(): void => goToLink('/app/dashboard')} />
      </Menu.Item>
      {checkUserRole(['admin', 'manager']) && (
        <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="team" />
              <span>Users</span>
            </span>
          }
        >
          <Menu.Item key="3">
            All Users
            <Link to={`${match.url}/users`} onClick={(): void => goToLink(`${match.url}/users`)} />
          </Menu.Item>
          <Menu.Item key="4">
            Create new user
            <Link
              to={`${match.url}/users/create`}
              onClick={(): void => goToLink(`${match.url}/users/create`)}
            />
          </Menu.Item>
        </SubMenu>
      )}

      {checkUserRole(['admin', 'manager']) && (
        <SubMenu
          key="sub2"
          title={
            <span>
              <Icon type="shop" />
              <span>Brands</span>
            </span>
          }
        >
          <Menu.Item key="6">
            All Brands
            <Link
              to={`${match.url}/brands`}
              onClick={(): void => goToLink(`${match.url}/brands`)}
            />
          </Menu.Item>
        </SubMenu>
      )}

      {checkUserRole(['admin', 'manager']) && (
        <SubMenu
          key="sub3"
          title={
            <span>
              <Icon type="profile" />
              <span>Products</span>
            </span>
          }
        >
          <Menu.Item key="9">
            All Products
            <Link
              to={`${match.url}/products`}
              onClick={(): void => goToLink(`${match.url}/products`)}
            />
          </Menu.Item>
        </SubMenu>
      )}

      {checkUserRole(['admin', 'manager']) && (
        <SubMenu
          key="sub4"
          title={
            <span>
              <Icon type="shopping-cart" />
              <span>Replenishment Requests</span>
            </span>
          }
        >
          <Menu.Item key="10">
            All Replenishment Requests
            <Link
              to={`${match.url}/replenishment-requests`}
              onClick={(): void => goToLink(`${match.url}/replenishment-requests`)}
            />
          </Menu.Item>
          <Menu.Item key="11">
            Create Replenishment Requests
            <Link
              to={`${match.url}/replenishment-requests/create`}
              onClick={(): void => goToLink(`${match.url}/replenishment-requests/create`)}
            />
          </Menu.Item>
        </SubMenu>
      )}

      {checkUserRole(['brand']) && (
        <Menu.Item key="sub4">
          <Icon type="shopping-cart" />
          Replenishment Requests
          <Link
            to={`${match.url}/replenishment-requests`}
            onClick={(): void => goToLink(`${match.url}/replenishment-requests`)}
          />
        </Menu.Item>
      )}

      {checkUserRole(['admin']) && (
        <SubMenu
          key="sub5"
          title={
            <span>
              <Icon type="mail" />
              <span>Emails</span>
            </span>
          }
        >
          <Menu.Item key="12">
            All Emails
            <Link
              to={`${match.url}/emails`}
              onClick={(): void => goToLink(`${match.url}/emails`)}
            />
          </Menu.Item>
        </SubMenu>
      )}
      {checkUserRole(['admin']) && (
        <SubMenu
          key="sub6"
          title={
            <span>
              <Icon type="bell" />
              <span>Notifications</span>
            </span>
          }
        >
          <Menu.Item key="13">
            All Notifications
            <Link
              to={`${match.url}/notifications`}
              onClick={(): void => goToLink(`${match.url}/notifications`)}
            />
          </Menu.Item>
        </SubMenu>
      )}
      {checkUserRole(['admin']) && (
        <SubMenu
          key="sub7"
          title={
            <span>
              <Icon type="star" />
              <span>Admin Panel</span>
            </span>
          }
        >
          <Menu.Item key="12">
            Admin Logs
            <Link
              to={`${match.url}/admin-logs`}
              onClick={(): void => goToLink(`${match.url}/admin-logs`)}
            />
          </Menu.Item>
        </SubMenu>
      )}
    </Menu>
  );
}
