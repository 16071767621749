import React, { useState, useEffect } from 'react';
import { Button, Badge, Dropdown, Menu, message, notification } from 'antd';
import io from 'socket.io-client';
// utils
import { ApiWithToken } from '../../utils/api';
import { env } from '../../config/client';
// interfaces
import { Notification } from '../../interfaces/notification';
import { getDataDecrypt } from '../../utils/encryption';

interface NotificationsProps {
  history: any;
}

export default function Notifications(props: NotificationsProps): JSX.Element {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [alreadyFetched, setAlreadyFetched] = useState(false);

  const user = getDataDecrypt('user');

  async function markAsRead(notif: Notification): Promise<void> {
    try {
      await ApiWithToken.post(`/notifications/${notif._id}`);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchNotifications();
    } catch (e) {
      message.error('Error marking notification as read.');
    }
  }

  function handleRedirect({ action }: Notification): void {
    if (!action) return;

    let url = '';

    switch (action.module) {
      case 'replenishments':
        url = `/app/replenishment-requests/view/${action.id}`;
        break;

      case 'replenishments/comments':
        url = `/app/replenishment-requests/view/${action.id}#comments`;
        break;
    }

    if (url) {
      props.history.push('/');
      props.history.push(url);
    }
  }

  function onNotificationClick(notification: Notification): void {
    markAsRead(notification);
    handleRedirect(notification);
  }

  async function fetchNotifications(isAlreadyFetched?: boolean): Promise<void> {
    try {
      const { data } = await ApiWithToken.get('/notifications');
      setNotifications(data);
      // the next conditional is 100% necessary to make this run as expected
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!isAlreadyFetched) {
        const firstUnread: Notification = data.find((notif: Notification): boolean => !notif.read);

        if (firstUnread) {
          notification.info({
            top: 60,
            description: firstUnread.message,
            message: 'Latest unread notification',
            onClick: (): void => onNotificationClick(firstUnread),
          });
        }
      }
      setAlreadyFetched(true);
    } catch (e) {
      message.error('Error fetching your notifications.');
    }
  }

  useEffect((): void => {
    fetchNotifications(alreadyFetched);
  }, [alreadyFetched]);

  useEffect((): void => {
    function connectToSocket(): void {
      const socket = io(`${env.socketUrl}/notifications`);
      socket.on(user._id, (notif: Notification): void => {
        fetchNotifications();
        notification.info({
          top: 60,
          message: 'New notification',
          description: notif.message,
          onClick: (): void => onNotificationClick(notif),
        });
      });
    }
    connectToSocket();
  }, []);

  const menu = (
    <Menu>
      {notifications.length > 0 ? (
        notifications.map(
          (notif: Notification): JSX.Element => (
            <Menu.Item key={notif._id} onClick={(): void => onNotificationClick(notif)}>
              <Badge dot status={notif.read ? 'default' : notif.type} />
              <span>{notif.message}</span>
            </Menu.Item>
          ),
        )
      ) : (
        <Menu.Item key="not-new">
          <span>You don&apos;t have new notifications.</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Badge count={notifications.filter((n: Notification): boolean => !n.read).length}>
        <Button icon="bell" type="dashed" />
      </Badge>
    </Dropdown>
  );
}
