import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Icon,
  Input,
  InputNumber,
  List,
  message,
  Row,
  Select,
  Table,
} from 'antd';
import { RouteComponentProps } from 'react-router';
import Highlighter from 'react-highlight-words';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
import Spacer from '../../components/spacer';
import NoDataText from '../../components/no-data-text';
import HandleProductsErrorsModal from '../replenishment-requests/manage/components/handle-product-errors-modal';
// interfaces
import { StonehengeProduct } from '../../interfaces/product';
import { StonehengeBrand } from '../../interfaces/brand';
import { User } from '../../interfaces/user';
import { ReplenishmentProduct } from '../../interfaces/replenishment-request';
import { ProductError } from '../../interfaces/product-error';
// utils
import { ApiWithToken } from '../../utils/api';
import { addSkuKeyToList } from '../../utils';
import { getDataDecrypt } from '../../utils/encryption';
import moment from 'moment';
import './create-replenishment-for-brand.css';
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateReplenishmentForBrandProps extends RouteComponentProps<{ brandId: string }> {}

export default function CreateReplenishmentForBrandPage({
  match,
  history,
}: CreateReplenishmentForBrandProps): JSX.Element {
  const [brand, setBrand] = useState<StonehengeBrand>();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<StonehengeProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[] | number[]>([]);
  const [productQties, setProductQties] = useState<{ [key: string]: number }>({});
  const [productCountries, setProductCountries] = useState<{ [key: string]: number }>({});
  const [showModal, setShowModal] = useState(false);
  const [erroredProducts, setErroredProducts] = useState<ProductError[]>([]);
  const [submittedProducts, setSubmittedProducts] = useState<ReplenishmentProduct[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [displayProducts, setDisplayProducts] = useState<StonehengeProduct[]>([]);
  const [whoLabels, setWhoLabels] = useState('SELLER_LABEL');
  const [tableSortData, setTableSortData] = useState<SorterData>({
    field: 'doi',
    order: 'ascend',
    column: '',
    columnKey: '',
  });
  const [subBrands, setSubBrands] = useState<StonehengeBrand[]>([]);

  const url = new URL(window.location.href);
  const subBrandQuery = url.searchParams.get('subBrand');

  const [selectedSubBrand, setSelectedSubBrand] = useState<string | undefined>(
    subBrandQuery ? subBrandQuery : undefined,
  );

  useEffect((): void => {
    async function fetchBrand(): Promise<void> {
      try {
        const { data } = await ApiWithToken.get(`/brands/${match.params.brandId}`);
        setBrand(data);
      } catch (e) {
        message.error('Brand not found');
      }
    }

    async function fetchSubBrands(): Promise<void> {
      try {
        const { data } = await ApiWithToken.get(`/brands/${match.params.brandId}/sub-brands`);
        setSubBrands(data);
      } catch (e) {
        message.error('Sub brands not found');
      }
    }

    fetchBrand();
    fetchSubBrands();
  }, []);

  useEffect((): void => {
    async function fetchProducts(): Promise<void> {
      setLoading(true);

      try {
        const { data } = await ApiWithToken.get(
          `/brands/${selectedSubBrand ||
            match.params.brandId}/replenishment-recommendation?sortBy=${
            tableSortData.field
          }&sortOrder=${tableSortData.order}`,
        );
        const newProducts = addSkuKeyToList(data);
        setProducts(newProducts);
        setDisplayProducts(newProducts);

        const newQties: { [key: string]: number } = {};
        for (const product of newProducts) {
          newQties[product.seller_sku] = productQties[product.seller_sku]
            ? productQties[product.seller_sku]
            : 0;
        }
        setProductQties(newQties);
      } catch (e) {
        message.error('An error occurred fetching the products. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    fetchProducts();
  }, [tableSortData, selectedSubBrand]);

  async function submitRequest(): Promise<void> {
    const firstProduct = products.find((p): boolean => p.seller_sku === selectedProducts[0]);

    const matchingCountryProducts = [...selectedProducts].filter(
      (sku: string | number): boolean => {
        const product = products.find((p): boolean => p.seller_sku === sku);

        if (product && firstProduct) {
          const country1 = productCountries[firstProduct.seller_sku] || 'US';
          const country2 = productCountries[product.seller_sku] || 'US';

          return country1 === country2;
        }

        return false;
      },
    );

    const productsAreInSameCountry = matchingCountryProducts.length === selectedProducts.length;

    if (!productsAreInSameCountry) {
      setSubmitting(false);
      return message.error('All the products must belong to the same country.');
    }

    setSubmitting(true);
    // @ts-ignore
    const mappedProducts = selectedProducts
      .map((sku: string): any => {
        const product = products.find((p): boolean => p.seller_sku === sku);
        if (product) {
          return {
            asin: product.asin,
            seller_sku: product.seller_sku,
            consumable: product.consumable,
            requestedQty: productQties[sku],
            case_quantity: product.master_carton && product.master_carton.case_quantity,
            master_carton: product.master_carton,
            product_image_url: product.product_image_url,
            hazmat: product.hazmat,
          };
        }
      })
      .filter((product: ReplenishmentProduct): boolean => product.requestedQty > 0);

    if (mappedProducts.length === 0) {
      setSubmitting(false);
      return message.error(
        'You must select at least one product with more than zero units to request',
      );
    }

    const user: User = getDataDecrypt('user');

    try {
      const response = await ApiWithToken.post('replenishments', {
        brandCode: selectedSubBrand ? selectedSubBrand : brand && brand.brand_code,
        products: mappedProducts,
        user,
        packageLabels: whoLabels,
        country: (firstProduct && productCountries[firstProduct.seller_sku]) || 'US',
      });
      if (response.data.errors) {
        setErroredProducts(response.data.errors);
        setShowModal(true);
        setSubmittedProducts(mappedProducts);
      } else {
        message.success(
          `A replenishment request with ID ${response.data.id} was successfully created!`,
        );
        history.push(`/app/replenishment-requests/view/${response.data._id}`);
      }
    } catch (e) {
      //
    } finally {
      setSubmitting(false);
    }
  }

  function renderProductError(product: StonehengeProduct): JSX.Element | null {
    const productError = erroredProducts.find(
      (ep: ProductError): boolean =>
        ep.seller_sku === product.seller_sku || ep.asin === product.asin,
    );

    if (productError) {
      return <span>{productError.description}</span>;
    }
    return null;
  }

  function displaySelectedProducts(): JSX.Element {
    if (selectedProducts.length === 0) {
      return <List.Item>You have not selected any product yet</List.Item>;
    }
    // @ts-ignore
    return selectedProducts.map(
      (sku: string): JSX.Element => {
        const product = products.find((p): boolean => p.seller_sku === sku);

        return (
          <List.Item key={`${sku}-selected`}>
            <b>{sku}</b>: <span>{productQties[sku]} units</span>
            {product && <> {renderProductError(product)}</>}
          </List.Item>
        );
      },
    );
  }

  function handleSearch(selectedKeys: any, dataIndex: string): void {
    setSearchText(selectedKeys[0]);

    if (selectedKeys.length === 0) {
      setDisplayProducts(products);
    } else {
      // @ts-ignore
      const filtered = products.filter((p): boolean => p[dataIndex].includes(selectedKeys[0]));
      setDisplayProducts(filtered);
    }
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => {
            const value = e.target.value ? [e.target.value] : [];
            setSelectedKeys(value);
            handleSearch(value, dataIndex);
          }}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            handleSearch('', '');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  const links = [
    createLink('Replenishment Requests', ''),
    createLink('Create Replenishment Request', ''),
  ];

  async function handleSort(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    if (sorter.field !== tableSortData.field || sorter.order !== tableSortData.order) {
      setTableSortData(sorter);
    }
  }
  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>Select the products you want to replenish</h1>

        <Spacer height={15} />

        <Select
          style={{ width: 200 }}
          placeholder="Filter by sub brand"
          allowClear={true}
          onChange={(event: any): void => {
            setSelectedSubBrand(event);
          }}
          value={selectedSubBrand}
        >
          {subBrands.map(
            (subBrand: any): JSX.Element => (
              <Select.Option key={subBrand.brand_code} value={subBrand.brand_code}>
                {subBrand.name}
              </Select.Option>
            ),
          )}
        </Select>

        <Spacer height={15} />

        <div className="create-replenishment-table">
          <Table
            bordered
            scroll={{ x: 'max-content', y: 700 }}
            useFixedHeader={true}
            rowSelection={{
              onChange: (selectedRowKeys): void => setSelectedProducts(selectedRowKeys),
            }}
            onChange={handleSort}
            dataSource={displayProducts}
            pagination={{
              showTotal: (total: number): string => `${total} products`,
              total: displayProducts.length,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
            }}
            loading={loading}
            rowKey={(record: any): string => record.seller_sku}
            columns={[
              {
                width: 110,
                title: 'Country',
                key: 'country',
                render: (product): JSX.Element => (
                  <Select
                    style={{ width: 80 }}
                    defaultValue={'US'}
                    onChange={(newCountry: string): void => {
                      setProductCountries({
                        ...productCountries,
                        [product.seller_sku]: newCountry,
                      });
                    }}
                  >
                    <Select.Option key="US" value="US">
                      US
                    </Select.Option>
                  </Select>
                ),
              },

              {
                title: 'Date',
                width: 110,
                dataIndex: 'amazon_product',
                render: (value): JSX.Element => {
                  return <span>{moment(value.date).format('YYYY-MM-DD')}</span>;
                },
              },

              {
                title: 'Hazmat',
                width: 110,
                dataIndex: 'hazmat',
                sorter: true,
                sortOrder: tableSortData.field === 'hazmat' ? tableSortData.order : undefined,
                sortDirections: ['descend'],
                render: (isHazmat): JSX.Element => <span>{isHazmat === true ? 'Yes' : 'No'}</span>,
              },
              {
                width: 310,
                title: 'SKU',
                dataIndex: 'seller_sku',
                key: 'sku',
                render: (text: string): JSX.Element => <span>{text}</span>,
                ...getColumnSearchProps('sku'),
              },

              {
                title: 'DOI',
                width: 90,
                sorter: true,
                sortOrder: tableSortData.field === 'doi' ? tableSortData.order : undefined,
                sortDirections: ['ascend', 'descend'],
                dataIndex: 'doi',
                render: (text): JSX.Element =>
                  <span>{parseFloat(text).toFixed(2)}</span> || NoDataText(),
              },
              {
                title: 'DUR',
                width: 90,
                render: (product): JSX.Element =>
                  product.recommended_ship_date ? (
                    <span style={{ textAlign: 'start' }}>
                      {moment().diff(moment(product.recommended_ship_date), 'days')}
                    </span>
                  ) : (
                    NoDataText()
                  ),
              },
              {
                width: 140,
                title: 'Suggestion',
                dataIndex: 'recommended_replenishment_qty',
                sorter: true,
                sortOrder:
                  tableSortData.field === 'recommended_replenishment_qty'
                    ? tableSortData.order
                    : undefined,
                sortDirections: ['ascend', 'descend'],
                render: (value): JSX.Element => (value ? <span>{value}</span> : NoDataText()),
              },
              {
                title: 'Case quantity',
                width: 120,
                dataIndex: 'master_carton.case_quantity',
                render: (value): JSX.Element => (value ? <span>{value}</span> : NoDataText()),
              },
              {
                title: 'Quantity to request',
                width: 120,
                render: (product): JSX.Element => (
                  <InputNumber
                    type="number"
                    min={0}
                    style={{ width: 80 }}
                    value={productQties[product.seller_sku]}
                    onChange={(value): void =>
                      setProductQties({
                        ...productQties,
                        [product.seller_sku]: value,
                      })
                    }
                  />
                ),
              },
              {
                title: 'Current',
                width: 90,
                dataIndex: 'available',
                render: (text): JSX.Element => text || NoDataText(),
              },
              {
                title: 'Inbound',
                width: 120,
                sorter: true,
                sortOrder: tableSortData.field === 'inbound' ? tableSortData.order : undefined,
                sortDirections: ['ascend', 'descend'],
                dataIndex: 'inbound',
                render: (text): JSX.Element => text || NoDataText(),
              },
              {
                title: 'FC Transfer',
                width: 90,
                dataIndex: 'fc_transfer',
                render: (text): JSX.Element => text || NoDataText(),
              },
              {
                title: 'Customer Order',
                width: 110,
                dataIndex: 'customer_order',
                render: (text): JSX.Element => text || NoDataText(),
              },
              {
                title: 'FC Processing',
                width: 110,
                dataIndex: 'fc_processing',
                render: (text): JSX.Element => text || NoDataText(),
              },
              {
                title: 'Sales',
                width: 90,
                dataIndex: 'units_sold_last_30_days',
                render: (text): JSX.Element => text || NoDataText(),
              },
            ]}
          />
        </div>

        <Spacer height={20} />

        <Alert
          showIcon
          type="warning"
          message={
            <>
              <h3>Please note:</h3>
              <p>
                All products selected with zero units will not be added to the replenishment request
              </p>
            </>
          }
        />

        <Spacer height={10} />

        <List header={<h3>Selected products overview</h3>} bordered={true}>
          {displaySelectedProducts()}
        </List>

        <Spacer height={10} />

        <Form.Item label="Who is going to label the packages?">
          <Select
            style={{ width: 300 }}
            defaultValue={whoLabels}
            onChange={(value: string): void => setWhoLabels(value)}
          >
            <Select.Option value="SELLER_LABEL">Seller</Select.Option>
            <Select.Option value="AMAZON_LABEL_PREFERRED">Amazon</Select.Option>
          </Select>
        </Form.Item>

        <Spacer height={30} />

        <Row type="flex" justify="center">
          <p>
            Use this button to test the selected SKUs and quantities against the daily products
            errors list. The replenishment request will only be submitted to brand if this internal
            test passes. If any problem with this replenishment request&apos;s SKUs is found, the
            replenishment request will not be submitted to the brand and you would need to make
            edits.
          </p>
          <Button
            type="primary"
            onClick={submitRequest}
            disabled={selectedProducts.length === 0 || submitting}
            loading={submitting}
          >
            Submit if internal tests pass
          </Button>
        </Row>
      </div>
      <HandleProductsErrorsModal
        showModal={showModal}
        setShowModal={setShowModal}
        erroredProducts={erroredProducts}
        products={submittedProducts}
        retryCallback={submitRequest}
        brandCode={(brand && brand.brand_code) || ''}
        history={history}
        whoLabels={whoLabels}
      />
    </>
  );
}
