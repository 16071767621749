import React from 'react';
import { message, Table, Popconfirm, Button } from 'antd';
import {
  ReplenishmentRequest,
  ReplenishmentProduct,
} from '../../../../interfaces/replenishment-request';
import { ApiWithToken } from '../../../../utils/api';

interface RemoveReplenishmentProductsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
}

export default function RemoveReplenishmentProducts({
  replenishment,
  fetchReplenishment,
}: RemoveReplenishmentProductsProps): JSX.Element {
  async function removeProduct(product: ReplenishmentProduct): Promise<void> {
    try {
      const newProducts = [...replenishment.products];
      const productIndex = newProducts.indexOf(product);
      newProducts.splice(productIndex, 1);
      await ApiWithToken.put(`/replenishments/${replenishment._id}`, {
        products: newProducts,
      });
      message.success('The product was successfully removed');
      fetchReplenishment();
    } catch (e) {
      message.error('Something went wrong deleting the product');
      fetchReplenishment();
    }
  }

  return (
    <Table
      dataSource={replenishment.products}
      columns={[
        { title: 'SKU', dataIndex: 'seller_sku' },
        {
          title: 'Actions',
          render: (record: ReplenishmentProduct): JSX.Element => (
            <Popconfirm
              title="Are you sure you want to remove this product from this replenishment?"
              onConfirm={(): Promise<void> => removeProduct(record)}
            >
              <Button type="danger">Remove</Button>
            </Popconfirm>
          ),
        },
      ]}
    />
  );
}
