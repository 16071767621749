import React, { useState, useEffect } from 'react';
import { Typography, Input, Table, Row, Button, Form, message, DatePicker, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
// interfaces
import {
  ReplenishmentRequest,
  ReplenishmentShipment,
  ShipmentPlan,
} from '../../../interfaces/replenishment-request';
import { Brand } from '../../../interfaces/brand';
// utils
import { ApiWithToken } from '../../../utils/api';
import { isLessThan5Months } from './tables-utils';
import { labelOptions, PageType, marketPlaceOptions } from '../../../utils/marketplace';
// components
import DisplayShipmentProducts from '../../display-shipment-products';
import { scrollToTop } from '../../../pages/replenishment-requests/view';
import { MarketplacesEnum } from '../../../enums/marketplaces.enum';

const { Text } = Typography;

interface MoreThanOneSKUPerBoxTableProps extends FormComponentProps {
  replenishment?: ReplenishmentRequest | null;
  setSuccess: (newValue: boolean) => void;
  shipment: ShipmentPlan;
  fetchReplenishment: () => void;
}

export function getCleanSku(sku: string): string {
  return sku.replace(/\./, '');
}

function MoreThanOneSKUPerBoxTable({
  replenishment,
  form,
  fetchReplenishment,
  setSuccess,
  shipment,
}: MoreThanOneSKUPerBoxTableProps): JSX.Element | null {
  const [products, setProducts] = useState<any[]>([]);
  const [boxes, setBoxes] = useState<number>(0);
  const [boxesInput, setBoxesInput] = useState(0);
  const [brand, setBrand] = useState<any | Brand>({});
  const [pageTypes, setPageTypes] = useState<PageType[]>([]);
  const [shipmentType, setShipmentType] = useState('SP');
  const [boxesError, setBoxesError] = useState();

  async function fetchBrand(): Promise<void> {
    try {
      const { data: newBrand } = await ApiWithToken.get(
        `/brands/${replenishment && replenishment.brand.code}`,
      );
      setBrand(newBrand);
    } catch (e) {
      message.error('An error occurred fetching the brand.');
    }
  }

  useEffect((): void => {
    if (shipment) {
      const shipmentProductsWithKey = shipment.Items.map((product: any, index: number): object => ({
        key: product.SellerSKU + index,
        ...product,
      }));

      setProducts(shipmentProductsWithKey);
      fetchBrand();
    }
  }, []);

  useEffect((): void => {
    if (brand) {
      const brandMarketplace = brand.marketplace
        ? brand.marketplace.marketplaceId
        : MarketplacesEnum.US;
      const marketplace = marketPlaceOptions.find(
        (marketplace): boolean =>
          (replenishment && replenishment.marketplaceId ? true : false) &&
          marketplace.marketplaceId ===
            ((replenishment && replenishment.marketplaceId) || brandMarketplace),
      );

      if (marketplace) {
        const validPageTypes = labelOptions.filter((labelOption): boolean => {
          return (
            labelOption.allowedCountries.length === 0 ||
            labelOption.allowedCountries.includes(marketplace.countryCode)
          );
        });

        setPageTypes(validPageTypes);
      } else {
        const validPageTypes = labelOptions.filter((labelOption): boolean => {
          return (
            labelOption.allowedCountries.length === 0 || labelOption.allowedCountries.includes('US')
          );
        });

        setPageTypes(validPageTypes);
      }
    }
  }, [brand]);

  if (!replenishment) return null;

  const { getFieldDecorator, validateFields } = form;

  async function confirmBoxes(e: any): Promise<void> {
    e.preventDefault();
    setBoxesError(null);

    if (!shipmentType) {
      setBoxesError('Please select a shipment type.');
      return;
    }

    if (!boxesInput || boxesInput === 0) {
      setBoxesError('Please enter a valid number of boxes.');
      return;
    }

    if (boxesInput > 15) {
      if (shipmentType === 'SP') {
        setBoxesError('SP shipments can only have up to 15 boxes.');
        setBoxes(15);
        setBoxesInput(15);
        return;
      }

      if (boxesInput > 5000) {
        setBoxesError('LTL shipments can only have up to 5000 boxes.');
        return;
      }
    }
    setBoxes(boxesInput);
  }

  async function submitForm(shipmentData: any): Promise<void> {
    try {
      await ApiWithToken.post(
        `/replenishments/${replenishment && replenishment._id}/shipments/${
          shipment.ShipmentId
        }/logistics`,
        shipmentData,
      );
      message.success('Shipment info was successfully submitted!');
      setSuccess(true);
      fetchReplenishment();
      scrollToTop();
    } catch (e) {
      message.error('There was an error updating the logistics info. Please try again');
    }
  }

  function productBoxedQty(product: any): JSX.Element {
    let color: 'danger' | 'warning' | undefined = 'warning';
    let sum = 0;
    let style = {};

    const currentBoxes = form.getFieldValue('boxes');
    const sellerSKU = getCleanSku(product.SellerSKU);

    currentBoxes.forEach((box: any): void => {
      if (!box || !box.products) return;
      if (box.products[sellerSKU]) {
        sum += parseInt(box.products[sellerSKU]);
      }
    });

    if (product.Quantity && sum > parseInt(product.Quantity)) {
      color = 'danger';
    }

    if (sum === parseInt(product.Quantity)) {
      color = undefined;
      style = { color: 'lightgreen' };
    }

    return (
      <div style={{ height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Text strong type={color} style={style}>
          {sum}
        </Text>
      </div>
    );
  }

  function boxesMeasuresColumns(): any[] {
    return [
      {
        title: 'Box #',
        render: (text: string, record: any, index: number): JSX.Element => (
          <Text>Box #{index + 1}</Text>
        ),
      },
      {
        title: 'Weight (lb)',
        render: (text: string, record: any, index: number): JSX.Element => (
          <Form.Item>
            {getFieldDecorator(`boxes[${index}].weight`, {
              rules: [{ required: true, message: ' ' }],
            })(<Input type="number" min="1" max="45" style={{ width: 120 }} />)}
          </Form.Item>
        ),
      },
      {
        title: 'Dimensions',
        render: (text: string, record: any, index: number): JSX.Element => (
          <Row type="flex" justify="space-between" style={{ width: 200 }}>
            <Form.Item>
              {getFieldDecorator(`boxes[${index}].dimensions.x`, {
                rules: [{ required: true, message: ' ' }],
              })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
            </Form.Item>
            <Text>x</Text>
            <Form.Item>
              {getFieldDecorator(`boxes[${index}].dimensions.y`, {
                rules: [{ required: true, message: ' ' }],
              })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
            </Form.Item>
            <Text>x</Text>
            <Form.Item>
              {getFieldDecorator(`boxes[${index}].dimensions.z`, {
                rules: [{ required: true, message: ' ' }],
              })(<Input style={{ width: 50 }} type="number" min="1" max="108" />)}
            </Form.Item>
          </Row>
        ),
      },
    ];
  }

  function hasRightQuantities(): boolean {
    const currentBoxes = form.getFieldValue('boxes');

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      let sum = 0;

      const sellerSKU = getCleanSku(product.SellerSKU);

      currentBoxes.forEach((box: any): void => {
        if (!box || !box.products) return;
        if (box.products[sellerSKU]) {
          sum += parseInt(box.products[sellerSKU]);
        }
      });
      if (sum !== parseInt(product.Quantity)) {
        message.error(
          `Total quantity entered for ${product.SellerSKU} doesn't match the shipment quantity.`,
        );
        return false;
      }
    }

    return true;
  }

  function findImageBySKU(sku: string): string {
    if (!replenishment) return '';

    const product = replenishment.products.find((product): boolean => product.seller_sku === sku);
    return product ? product.product_image_url : '';
  }

  async function submitLogistics(e: any): Promise<void> {
    e.preventDefault();
    validateFields((err, values): void | null => {
      const { boxes, pageType, products: productsExtra } = values;

      if (err || !replenishment) return null;
      if (!hasRightQuantities()) return null;

      const newBoxes: any[] = [];

      for (const box of boxes) {
        const newBox: any = {
          ...(shipmentType === 'SP' && {
            weight: parseInt(box.weight),
            dimensions: {
              x: parseInt(box.dimensions.x),
              y: parseInt(box.dimensions.y),
              z: parseInt(box.dimensions.z),
            },
          }),
          products: [],
        };
        for (let [sku, qty] of Object.entries(box.products)) {
          const product = products.find((p): boolean => {
            return getCleanSku(p.SellerSKU) === sku;
          }) || { availableQty: 0 };
          if (
            productsExtra &&
            productsExtra[sku] &&
            productsExtra[sku].expirationDate &&
            !productsExtra[sku].expirationDate.isAfter(moment())
          ) {
            message.error('Expiration date must be after today');
            return;
          }
          newBox.products.push({
            seller_sku: sku,
            qty: typeof qty === 'string' && parseInt(qty),
            ...(productsExtra &&
              productsExtra[sku] &&
              productsExtra[sku].expirationDate && {
                expirationDate: productsExtra[sku].expirationDate,
              }),
            totalQty: parseInt(product.Quantity),
          });
        }
        newBoxes.push({
          ...newBox,
          products: newBox.products.filter((p: any): boolean => p.qty > 0),
        });
      }

      if (shipmentType === 'SP' && newBoxes.length > 15) {
        message.error('No more than 15 boxes are allowed for SP shipments');
        return null;
      }

      if (shipmentType === 'LTL' && newBoxes.length > 5000) {
        message.error('No more than 5000 boxes are allowed for LTL shipments');
        return null;
      }

      const shipment: Partial<ReplenishmentShipment> = {
        boxes: newBoxes,
        shipmentType,
        pageType,
      };

      submitForm(shipment);
    });
  }

  function boxesColumns(): any[] {
    const columns = [];

    for (let i = 0; i < boxes; i++) {
      columns.push({
        title: `Box ${i + 1}`,
        render: (text: string, record: any): JSX.Element => {
          const sellerSku = getCleanSku(record.SellerSKU);

          return (
            <Form.Item>
              {getFieldDecorator(`boxes[${i}].products[${sellerSku}]`, {
                initialValue: '0',
                rules: [{ required: true, message: ' ' }],
              })(<Input style={{ width: 50 }} type="number" min="0" />)}
            </Form.Item>
          );
        },
      });
    }
    return columns;
  }

  function findProductIsConsumable(sku: string): boolean {
    const product =
      replenishment && replenishment.products.find((p): boolean => p.seller_sku === sku);
    if (!product) return false;
    return product.consumable;
  }

  return (
    <div>
      <DisplayShipmentProducts shipment={shipment} />
      <Form onSubmit={submitLogistics}>
        <Form.Item label="Please select the shipment type:" required={true}>
          <Select defaultValue={shipmentType} onChange={(value: string) => setShipmentType(value)}>
            <Select.Option value="SP">Small Parcel (SP)</Select.Option>
            <Select.Option value="LTL">Less Than Truckload/Full Truckload (LTL)</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item required label="How many boxes are in the shipment?">
          <Input
            style={{ width: 80, marginRight: 10 }}
            type="number"
            min="0"
            max={shipmentType === 'SP' ? '15' : '5000'}
            onChange={(e: any): void =>
              setBoxesInput(e.target.value ? parseInt(e.target.value) : 0)
            }
            value={boxesInput.toString()}
          />
          <Button type="primary" onClick={confirmBoxes}>
            Confirm
          </Button>
          {boxesError && <div style={{ color: 'red' }}>{boxesError}</div>}
        </Form.Item>

        {boxes > 0 && (
          <>
            <Form.Item label="Select the page type for your labels">
              {getFieldDecorator('pageType', {
                initialValue: 'PackageLabel_Plain_Paper',
                rules: [{ required: true, message: 'Page type is required' }],
              })(
                <Select>
                  {pageTypes.map(
                    (pageType): JSX.Element => (
                      <Select.Option key={pageType.value} value={pageType.value}>
                        {pageType.label}
                      </Select.Option>
                    ),
                  )}
                </Select>,
              )}
            </Form.Item>
            <Table
              bordered
              scroll={{ x: true }}
              className="mt-4"
              dataSource={products}
              pagination={{ pageSize: products.length }}
              columns={[
                {
                  key: 'image',
                  dataIndex: 'SellerSKU',
                  render: (text: string): JSX.Element => (
                    <img
                      style={{ maxWidth: '75px', maxHeight: '75px' }}
                      src={findImageBySKU(text)}
                    />
                  ),
                },
                {
                  title: 'SKU',
                  dataIndex: 'SellerSKU',
                },
                {
                  title: 'Qty',
                  dataIndex: 'Quantity',
                },
                {
                  title: 'Expiration date',
                  render: (record: any): JSX.Element =>
                    findProductIsConsumable(record.SellerSKU) ? (
                      <Form.Item>
                        {getFieldDecorator(`products[${record.SellerSKU}].expirationDate`, {
                          rules: [{ required: true, message: 'Expiration date is required' }],
                        })(
                          <DatePicker
                            disabledDate={isLessThan5Months}
                            style={{ width: 160 }}
                            placeholder="Select date"
                            defaultPickerValue={moment().add(5, 'months')}
                          />,
                        )}
                      </Form.Item>
                    ) : (
                      <Text>Not needed</Text>
                    ),
                },
                ...boxesColumns(),
                {
                  title: 'Total Qty',
                  fixed: 'right',
                  render: (record: any): JSX.Element => productBoxedQty(record),
                },
              ]}
            />

            {shipmentType === 'SP' && (
              <Table
                className="mt-4"
                columns={[...boxesMeasuresColumns()]}
                dataSource={new Array(boxes).fill({})}
                pagination={{ pageSize: boxes }}
              />
            )}

            <Row type="flex" justify="center" className="mt-4">
              <Button type="primary" htmlType="submit" disabled={!!boxesError}>
                Submit shipment info
              </Button>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
}

export default Form.create<MoreThanOneSKUPerBoxTableProps>({ name: 'logistics-form' })(
  MoreThanOneSKUPerBoxTable,
);
