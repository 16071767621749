import React, { useState, useEffect } from 'react';
import { Typography, Button, Checkbox, message } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
import { StonehengeBrand } from '../../../../interfaces/brand';
// utils
import { ApiWithToken } from '../../../../utils/api';

const { Paragraph } = Typography;

interface ReplenishmentItemLabelsProps {
  replenishment?: ReplenishmentRequest | null;
  fetchReplenishment: () => void;
}

function ReplenishmentItemLabels({
  replenishment,
  fetchReplenishment,
}: ReplenishmentItemLabelsProps): JSX.Element | null {
  const [checked, setChecked] = useState(false);
  const [brand, setBrand] = useState<StonehengeBrand | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect((): void => {
    async function fetchBrand(): Promise<void> {
      try {
        const { data } = await ApiWithToken.get(
          `brands/${replenishment && replenishment.brand.code}`,
        );
        setBrand(data);
      } catch (e) {
        message.error('Something went wrong getting the labels. Please try again later');
      }
    }
    if (replenishment) fetchBrand();
  }, []);

  if (!replenishment || replenishment.hasLabels) return null;

  async function confirmGotLabels(): Promise<void> {
    if (!checked) return setError(true);
    setError(false);
    setLoading(true);
    try {
      await ApiWithToken.put(`/replenishments/${replenishment && replenishment._id}`, {
        hasLabels: true,
      });
      setButtonDisabled(true);
      message.success('Labels confirmed!');
      fetchReplenishment();
    } catch (e) {
      message.error('Something went wrong! Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Paragraph style={{ fontSize: 20 }}>Please find your product labels below.</Paragraph>
      <a target="_blank" rel="noopener noreferrer" href={(brand && brand.item_label_url) || ''}>
        {brand && brand.item_label_url}
      </a>
      <div className="mt-4">
        <div>
          <Checkbox onChange={(e: any): void => setChecked(e.target.checked)}>
            I confirm that I have printed and applied the labels to each item (if necessary)
          </Checkbox>
          {error && (
            <Paragraph type="danger" className="mt-4">
              Please confirm you printed and applied the labels
            </Paragraph>
          )}
        </div>
        <Button
          disabled={buttonDisabled}
          className="mt-4"
          onClick={confirmGotLabels}
          loading={loading}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}

export default ReplenishmentItemLabels;
