import React, { useEffect, useState } from 'react';
import { Table, Button, message, Select, Typography, Row, Col, Modal, Divider, Upload } from 'antd';
// interfaces
import { StonehengeProduct } from '../../../../../interfaces/product';
import { StonehengeBrand } from '../../../../../interfaces/brand';
// utils
import { checkUserBelongsToRoles, addIdKeyToList } from '../../../../../utils';
import { ApiWithToken } from '../../../../../utils/api';
import {
  PaginationData,
  FilterData,
  SorterData,
} from '../../../../../interfaces/table-filters.interface';
import Spacer from '../../../../../components/spacer';
import { env } from '../../../../../config/client';
import { getDataDecrypt } from '../../../../../utils/encryption';

interface BrandProductsTableProps {
  brand: StonehengeBrand;
  subBrands: StonehengeBrand[];
  history: any;
}

const { Text } = Typography;
const checkUserRole = checkUserBelongsToRoles();

export default function BrandProductsTable(props: BrandProductsTableProps): JSX.Element | null {
  const { brand, history, subBrands } = props;
  const [products, setProducts] = useState<StonehengeProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [allConsumableFlag, setAllConsumableFlag] = useState('');
  const [allIgnoreFlag, setAllIgnoreFlag] = useState('');
  const [allHazmatFlag, setAllHazmatFlag] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSubBrand, setSelectedSubBrand] = useState<string>();

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  async function getAllProducts(): Promise<any> {
    if (!brand.brand_code) {
      return;
    }

    setLoading(true);

    let brandCode = brand.brand_code;

    if (selectedSubBrand) {
      brandCode = selectedSubBrand;
    }

    try {
      const { data } = await ApiWithToken.get(`/products?brand_code=${brandCode}`);
      const newProducts = addIdKeyToList(data.data);
      setProducts(newProducts);
      setTotalProducts(data.pagination.total);
    } catch (e) {
      message.error('An error occurred fetching the products. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  async function updateConsumableFlag(value: string, sku: string): Promise<void> {
    try {
      await ApiWithToken.put(`/products/${encodeURIComponent(sku)}/consumable`, {
        consumable: value === 'true',
      });

      message.success('The consumable status was successfully updated');
      getAllProducts();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  async function updateIgnoreFlag(value: string, sku: string): Promise<void> {
    try {
      await ApiWithToken.put(`/products/${encodeURIComponent(sku)}/ignore`, {
        ignore: value === 'true',
      });

      message.success('The ignore status was successfully updated');
      getAllProducts();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  async function updateHazmatFlag(value: string, sku: string): Promise<void> {
    try {
      await ApiWithToken.put(`/products/${encodeURIComponent(sku)}/hazmat`, {
        hazmat: value === 'true',
      });

      message.success('The hazmat status was successfully updated');
      getAllProducts();
    } catch (e) {
      message.error('An error occurred updating the product. Please try again later.');
    }
  }

  async function updateConsumableFlagForAllProducts(value: string): Promise<void> {
    if (value === '') {
      setAllConsumableFlag(value);
      return;
    }

    if (products.length === 0) {
      return;
    }

    try {
      await ApiWithToken.put(`/brands/${brand.brand_code}/products/consumable`, {
        consumable: value === 'true',
      });
      setAllConsumableFlag(value);
      message.success('All the products were updated');
      getAllProducts();
    } catch (error) {
      message.error('An error occurred updating the products. Please try again later.');
    }
  }

  async function updateIgnoreFlagForAllProducts(value: string): Promise<void> {
    if (value === '') {
      setAllIgnoreFlag(value);
      return;
    }

    if (products.length === 0) {
      return;
    }

    try {
      await ApiWithToken.put(`/brands/${brand.brand_code}/products/ignore`, {
        ignore: value === 'true',
      });
      setAllIgnoreFlag(value);
      message.success('All the products were updated');
      getAllProducts();
    } catch (error) {
      message.error('An error occurred updating the products. Please try again later.');
    }
  }

  async function updateHazmatFlagForAllProducts(value: string): Promise<void> {
    if (value === '') {
      setAllHazmatFlag(value);
      return;
    }

    if (products.length === 0) {
      return;
    }

    try {
      await ApiWithToken.put(`/brands/${brand.brand_code}/products/hazmat`, {
        hazmat: value === 'true',
      });
      setAllHazmatFlag(value);
      message.success('All the products were updated');
      getAllProducts();
    } catch (error) {
      message.error('An error occurred updating the products. Please try again later.');
    }
  }

  useEffect((): void => {
    getAllProducts();
  }, [currentPage, pageSize, extraQueries, brand, selectedSubBrand]);

  useEffect((): void => {
    const access = getDataDecrypt('access');
    setAccessToken(access.accessToken);
  }, []);

  return (
    <>
      <Modal
        visible={modalVisible}
        destroyOnClose={true}
        title="Bulk Update Products"
        onOk={(): void => setModalVisible(false)}
        onCancel={(): void => setModalVisible(false)}
        okText="Done"
      >
        <p>
          Here you can update many products&apos; master carton information, consumable status, and
          ignore status at once. You just have to download the .csv file, fill it out, and upload
          it.
        </p>
        <p>Download the example .csv file by clicking the button below:</p>
        <Button download href="/bulk-update-products.csv">
          Download example .csv file
        </Button>

        <Divider />

        <p>
          Once your .csv file is ready, click on the button below to upload it and we&apos;ll take
          care of the rest.
        </p>

        <p>
          <b>Note:</b> Make sure to fill out all the columns. If you don&apos;t know the case
          quantity for a SKU, just enter 1.
        </p>

        <Upload
          name="file"
          action={`${env.apiUrl}/brands/${brand.brand_code}/products/bulk`}
          headers={{
            Authorization: `Bearer ${accessToken}`,
          }}
          showUploadList={false}
          accept=".csv"
          onChange={(info): void => {
            if (info.file.status === 'done') {
              setModalVisible(false);
              getAllProducts();
              message.success('The products were successfully updated.');
            }
          }}
        >
          <Button>Click here to upload your .csv file</Button>
        </Upload>
      </Modal>

      <Row type="flex" justify="space-between">
        <Text strong>Brand Products</Text>

        <Col>
          <Row type="flex" justify="end">
            <Text strong>Consumable (update all)</Text> &nbsp;
            <Select
              defaultValue={allConsumableFlag}
              style={{ width: 140 }}
              onChange={(newValue: string): any => updateConsumableFlagForAllProducts(newValue)}
            >
              <Select.Option value="">Select an option</Select.Option>
              <Select.Option value="true">Yes</Select.Option>
              <Select.Option value="false">No</Select.Option>
            </Select>
          </Row>
          <Spacer height={20} />
          <Row type="flex" justify="end">
            <Text strong>Ignore (update all)</Text> &nbsp;
            <Select
              defaultValue={allIgnoreFlag}
              style={{ width: 140 }}
              onChange={(newValue: string): any => updateIgnoreFlagForAllProducts(newValue)}
            >
              <Select.Option value="">Select an option</Select.Option>
              <Select.Option value="true">Yes</Select.Option>
              <Select.Option value="false">No</Select.Option>
            </Select>
          </Row>
          <Spacer height={20} />
          <Row type="flex" justify="end">
            <Text strong>Hazmat (update all)</Text> &nbsp;
            <Select
              defaultValue={allIgnoreFlag}
              style={{ width: 140 }}
              onChange={(newValue: string): any => updateHazmatFlagForAllProducts(newValue)}
            >
              <Select.Option value="">Select an option</Select.Option>
              <Select.Option value="true">Yes</Select.Option>
              <Select.Option value="false">No</Select.Option>
            </Select>
          </Row>
        </Col>
      </Row>

      <Spacer height={15} />

      <Row type="flex" justify="end">
        <Button onClick={(): void => setModalVisible(true)}>Bulk update products</Button>
      </Row>

      <Spacer height={20} />

      <Row>
        <Col>
          <Text strong>Filter by sub brand</Text>
          <Spacer width={20} />
          <Select
            style={{ width: 200 }}
            onChange={(newValue: string): void => setSelectedSubBrand(newValue)}
            allowClear={true}
          >
            {subBrands.map(
              (subBrand: StonehengeBrand): JSX.Element => (
                <Select.Option key={subBrand.brand_code} value={subBrand.brand_code}>
                  {subBrand.name} ({subBrand.brand_code})
                </Select.Option>
              ),
            )}
          </Select>
        </Col>
      </Row>

      <Spacer height={20} />

      <Table
        dataSource={products}
        loading={loading}
        onChange={handleSorter}
        pagination={{
          current: currentPage,
          size: pageSize.toString(),
          showTotal: (total: number): string => `${total} products`,
          total: totalProducts,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
          onShowSizeChange: (page, size): void => setPageSize(size),
        }}
        columns={[
          {
            title: 'SKU',
            dataIndex: 'seller_sku',
            key: 'seller_sku',
            render: (text: string, product: StonehengeProduct): JSX.Element => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={(): void =>
                  history.push(`/app/products/view/${encodeURIComponent(product.seller_sku)}`)
                }
              >
                {text}
              </a>
            ),
            sorter: true,
          },
          {
            title: 'Ignore',
            dataIndex: 'ignore',
            key: 'ignore',
            render: (text: any, record: StonehengeProduct): JSX.Element => (
              <Select
                defaultValue={record.ignore ? record.ignore.toString() : 'false'}
                style={{ width: 80 }}
                onChange={(newValue: string): any => updateIgnoreFlag(newValue, record.seller_sku)}
                value={record.ignore.toString()}
              >
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            ),
          },
          {
            title: 'Consumable',
            dataIndex: 'consumable',
            key: 'consumable',
            render: (text: any, record: StonehengeProduct): JSX.Element => (
              <Select
                defaultValue={record.consumable ? record.consumable.toString() : 'false'}
                style={{ width: 80 }}
                onChange={(newValue: string): any =>
                  updateConsumableFlag(newValue, record.seller_sku)
                }
                value={record.consumable.toString()}
              >
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            ),
          },
          {
            title: 'Hazmat',
            dataIndex: 'hazmat',
            key: 'hazmat',
            render: (text: any, record: StonehengeProduct): JSX.Element => (
              <Select
                defaultValue={record.hazmat ? record.hazmat.toString() : 'false'}
                style={{ width: 80 }}
                onChange={(newValue: string): any => updateHazmatFlag(newValue, record.seller_sku)}
                value={(record.hazmat || false).toString()}
              >
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            ),
          },
          ...((checkUserRole(['admin', 'manager']) && [
            {
              title: 'Actions',
              render: (record: StonehengeProduct): JSX.Element => (
                <Button
                  size="small"
                  icon="eye"
                  onClick={(): void =>
                    history.push(`/app/products/view/${encodeURIComponent(record.seller_sku)}`)
                  }
                >
                  View
                </Button>
              ),
            },
          ]) ||
            []),
        ]}
      />
    </>
  );
}
