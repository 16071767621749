/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { Input, Button, Icon, Form, message, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// interfaces
import { StonehengeBrand } from '../../interfaces/brand';
// utils
import { ApiWithToken } from '../../utils/api';

interface CreateBrandContactFormProps extends FormComponentProps {
  brand: StonehengeBrand;
  label?: string;
  fetchBrand?: () => Promise<void>;
}

function CreateBrandContactForm({
  brand,
  label,
  fetchBrand,
  form,
}: CreateBrandContactFormProps): JSX.Element {
  const [creating, setCreating] = useState(false);
  const [contact, setContact] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, validateFields } = form;

  async function updateContacts(): Promise<void> {
    setLoading(true);
    try {
      const newContacts = [...brand.contacts];
      newContacts.push(contact);
      await ApiWithToken.put(`/brands/${brand.brand_code}`, {
        contacts: newContacts,
      });
      message.success('The contact was successfully added');
      fetchBrand && fetchBrand();
      setCreating(false);
    } catch (e) {
      message.error('An error occurred saving the contact. Please try again later');
    } finally {
      setLoading(false);
    }
  }

  function saveContact(): void {
    validateFields((err: any): any => {
      if (err) return;
      updateContacts();
    });
  }

  function handleForm(property: string, value: string): void {
    setContact({
      ...contact,
      [property]: value,
    });
  }

  if (!creating) {
    return (
      <Button type="link" onClick={(): void => setCreating(true)}>
        <Icon type="solution" />
        {label || 'Add new contact'}
      </Button>
    );
  }

  return (
    <div>
      <Form>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'You must enter a name' }],
          })(
            <Input disabled={loading} onChange={(e): void => handleForm('name', e.target.value)} />,
          )}
        </Form.Item>
        <Form.Item label="Phone">
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'You must enter a phone number' }],
          })(
            <Input
              disabled={loading}
              onChange={(e): void => handleForm('phone', e.target.value)}
            />,
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [{ required: true, type: 'email', message: 'You must enter a valid email' }],
          })(
            <Input
              disabled={loading}
              onChange={(e): void => handleForm('email', e.target.value)}
            />,
          )}
        </Form.Item>
        <Row type="flex" justify="space-around">
          <Button loading={loading} onClick={saveContact} type="primary">
            Save
          </Button>
          <Button disabled={loading} onClick={(): void => setCreating(false)}>
            Cancel
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default Form.create<CreateBrandContactFormProps>({ name: 'brand-contact-form' })(
  CreateBrandContactForm,
);
