import { getDataDecrypt } from './encryption';

export function isArray(a: any): boolean {
  return !!a && a.constructor === Array;
}

export function addIdKeyToList(data: any[]): any[] {
  if (data.length === 0) return [];

  const newArray = [...data];
  return newArray.map((item): any => ({
    ...item,
    key: item._id,
  }));
}

export function addSkuKeyToList(data: any[]): any[] {
  if (data.length === 0) return [];

  const newArray = [...data];
  return newArray.map((item): any => ({
    ...item,
    key: item.seller_sku,
  }));
}

export function addProductSkuKeyToList(data: any[]): any[] {
  if (data.length === 0) return [];

  const newArray = [...data];
  return newArray.map((item): any => ({
    ...item,
    key: item.product.seller_sku,
  }));
}

export function checkUserBelongsToRoles(): Function {
  const user = getDataDecrypt('user');

  return function(roles: string[]): boolean {
    if (roles.length === 0) return true;
    if (!user) return false;
    for (let role of user.roles) {
      if (roles.includes(role)) {
        return true;
      }
    }
    return false;
  };
}

export function capitalize(str: string): string {
  const strArr = str.split('');
  const rightStr = strArr.slice(1);
  return [strArr[0].toUpperCase(), ...rightStr].join('');
}

export function minAndMaxValidator(min = 0, max = 0): any {
  return function(rule: any, value: string, callback: Function): void {
    if (value) {
      const val = parseInt(value);
      if (val < min) {
        callback(`Minimum value is ${min}`, false);
      } else if (val > max) {
        callback(`Maximum value is ${max}`);
      }
    }
    callback();
  };
}

export function parseBrandType(brandType: string): string {
  switch (brandType) {
    case 'MAX_RESULT':
      return 'Max Result';
    case 'MAX_CONTROL':
      return 'Max Control';
    default:
      return brandType;
  }
}
