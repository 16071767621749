import React, { useEffect, useState } from 'react';
import * as H from 'history';
import {
  Typography,
  message,
  Skeleton,
  Table,
  Button,
  Row,
  Spin,
  Alert,
  Popconfirm,
  Collapse,
  Divider,
} from 'antd';
// components
import BreadcrumbComposer, { createLink } from '../../../../components/breadcrumb-composer';
import ReplenishmentStatusTag from '../components/status-tag';
import ReplenishmentItemLabels from '../components/item-labels';
import ShippingPlanForm from '../components/shipping-plan-form';
import DisplayShipments from '../components/display-shipments';
import BoxPictureForm from '../components/box-picture-form';
import DisplayShipmentsPhotos from '../components/display-shipments-photos';
import ReplenishmentComments from '../components/replenishment-comments';
import BoxesDisplayTable from '../../../../components/boxes-display-table';
import DisplayLastSteps from '../components/display-shipments-last-steps';
import PausedReplenishmentContent from '../components/paused-replenishment-content';
import HazmatTrackingNumbers from '../components/hazmat-tracking-numbers';
import DisplayManagersLastStep from '../components/display-managers-last-steps';
import ConfirmQuantities from '../components/confirm-quantities';
import ApproveQuantities from '../components/approve-quantities';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { getDataDecrypt } from '../../../../utils/encryption';
import { checkUserBelongsToRoles } from '../../../../utils';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ViewReplenishmentRequestPageV1Props {
  replenishment: ReplenishmentRequest | null;
  fetchReplenishment: Function;
  loading: boolean;
  setLoading: Function;
  history: H.History;
}

const { Title, Text } = Typography;
const { Panel } = Collapse;

const checkUserRoles = checkUserBelongsToRoles();

export default function ViewReplenishmentRequestPageV1({
  replenishment,
  fetchReplenishment,
  loading,
  setLoading,
  history,
}: ViewReplenishmentRequestPageV1Props): JSX.Element {
  const user = getDataDecrypt('user');

  const [products, setProducts] = useState<any>([]);
  const [shipmentsSent, setShipmentsSent] = useState<boolean>(false);
  const [allPhotosWereApproved, setAllPhotosWereApproved] = useState(false);
  const [allShipmentsAreHazmat, setAllShipmentsAreHazmat] = useState(false);

  const [isConfirmed, setConfirmed] = useState(false);
  const [belongsToBrand, setBelongsToBrand] = useState(false);
  const [submittedAllShipmentPhotos, setSubmittedAllShipmentPhotos] = useState(false);
  const [submittedOneShipmentPhotos, setSubmittedOneShipmentPhotos] = useState(false);

  function parseShipments(newReplenishment: ReplenishmentRequest): any {
    if (!newReplenishment.shipmentPlan) return;
    if (newReplenishment.shipments) {
      let shipmentsWithLogistics = 0;
      for (const shipmentId in newReplenishment.shipments) {
        if (newReplenishment.shipments[shipmentId].boxes) {
          shipmentsWithLogistics++;
        }
      }

      if (newReplenishment.shipmentPlan.length === shipmentsWithLogistics) {
        setShipmentsSent(true);
      }
    }
  }

  useEffect((): void => {
    const newReplenishment = replenishment;

    if (!newReplenishment) {
      return;
    }

    setProducts(
      newReplenishment.products.map((product): object => ({
        ...product,
        ...(!product.availableQty &&
          product.availableQty !== 0 && { availableQty: product.requestedQty }),
      })),
    );
    setConfirmed(
      newReplenishment.products.some((product: any): boolean =>
        product.hasOwnProperty('availableQty'),
      ),
    );
    setBelongsToBrand(user.brand ? user.brand.brandCode === newReplenishment.brand.code : false);
    if (newReplenishment.shipmentPlan) {
      parseShipments(newReplenishment);
    }
    if (newReplenishment.shipments) {
      const shipmentIds = Object.keys(newReplenishment.shipments);
      if (shipmentIds.length > 0) {
        const shipmentsWithUploadedPhotos = shipmentIds.filter(
          (shipmentId: any): any =>
            newReplenishment.shipments[shipmentId].awaitingPicturesApproval ||
            newReplenishment.shipments[shipmentId].photosApproved,
        );

        const shipmentsWithApprovedPhotos = shipmentIds.filter(
          (shipmentId: any): boolean => newReplenishment.shipments[shipmentId].photosApproved,
        );

        const hazmatShipments = shipmentIds.filter(
          (shipmentId: any): boolean => newReplenishment.shipments[shipmentId].isHazmat,
        );

        if (hazmatShipments.length === shipmentIds.length) {
          setAllShipmentsAreHazmat(true);
        }

        if (shipmentsWithApprovedPhotos.length === shipmentIds.length) {
          setAllPhotosWereApproved(true);
        }

        if (shipmentsWithUploadedPhotos.length === shipmentIds.length) {
          setSubmittedAllShipmentPhotos(true);
        }

        if (shipmentsWithUploadedPhotos.length > 0) {
          setSubmittedOneShipmentPhotos(true);
        }
      }
    }
  }, [replenishment]);

  async function deleteReplenishment(): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.delete(`/replenishments/${replenishment && replenishment._id}`);
      message.success('Replenishment request deleted successfully!');
      history.push('/app/replenishment-requests');
    } catch (e) {
      message.error(
        'There was an error deleting the replenishment request. Please try again later',
      );
    } finally {
      setLoading(false);
    }
  }

  const columns: any = [
    {
      title: 'Image',
      dataIndex: 'product_image_url',
      key: 'product_image_url',
      render: (text: string): JSX.Element => (
        <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={text} />
      ),
    },
    { title: 'SKU', dataIndex: 'seller_sku', key: 'sku' },
    {
      title: 'Requested Qty',
      dataIndex: 'requestedQty',
      key: 'requestedQty',
      render: (text: string): JSX.Element => <Text>{text}</Text>,
    },
    {
      title: 'Available Qty',
      key: 'availableQty',
      render: (record: any): JSX.Element => <Text>{record.availableQty}</Text>,
    },
  ];

  let content;

  if (loading && !replenishment) {
    content = <Skeleton active paragraph={{ rows: 6 }} />;
  } else {
    content = (
      <>
        <Row type="flex" justify="space-between">
          <Text style={{ fontSize: 20 }}>
            Replenishment Request {replenishment && replenishment.id}
          </Text>
          {checkUserRoles(['admin', 'manager']) && (
            <div>
              <Button
                type="primary"
                icon="monitor"
                onClick={(): void =>
                  history.push(
                    `/app/replenishment-requests/${replenishment && replenishment._id}/admin`,
                  )
                }
              >
                Go to replenishment admin
              </Button>
            </div>
          )}
          {checkUserRoles(['admin']) && (
            <Popconfirm
              title="Are you sure you want to delete this replenishment request?"
              onConfirm={(): Promise<void> => deleteReplenishment()}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          )}
        </Row>
        <br />

        {replenishment && isConfirmed && checkUserRoles(['brand']) && products && (
          <>
            <Collapse accordion style={{ marginBottom: 16 }}>
              <Panel header="See product's info" key="1">
                <Table className="mt-4" dataSource={products} columns={columns} />
              </Panel>
            </Collapse>
          </>
        )}

        {replenishment && !isConfirmed && products && (
          <ConfirmQuantities
            replenishment={replenishment}
            fetchReplenishment={fetchReplenishment}
            loading={loading}
            setLoading={setLoading}
            history={history}
          />
        )}

        {replenishment &&
          isConfirmed &&
          products &&
          !replenishment.approved &&
          checkUserRoles(['brand']) && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <ReplenishmentStatusTag replenishment={replenishment} />
            </div>
          )}

        {replenishment && replenishment.inLastStep && (
          <>
            <Title style={{ fontSize: 24 }}>Shipment boxes information</Title>
            <BoxesDisplayTable replenishment={replenishment} />
            {checkUserRoles(['admin', 'manager']) && (
              <DisplayManagersLastStep replenishment={replenishment} />
            )}
            {belongsToBrand && allShipmentsAreHazmat && (
              <Alert
                type="success"
                style={{ marginTop: 20 }}
                showIcon
                message="This replenishment request was finished successfully."
              />
            )}
            {belongsToBrand &&
              (allShipmentsAreHazmat ? null : (
                <DisplayLastSteps
                  replenishment={replenishment}
                  fetchReplenishment={fetchReplenishment}
                />
              ))}
          </>
        )}

        {replenishment &&
          isConfirmed &&
          !replenishment.approved &&
          checkUserRoles(['admin', 'manager']) && (
            <ApproveQuantities
              fetchReplenishment={(): Promise<void> => fetchReplenishment()}
              setLoading={setLoading}
              replenishment={replenishment}
            />
          )}

        {replenishment &&
          !replenishment.hasLabels &&
          belongsToBrand &&
          isConfirmed &&
          replenishment.approved && (
            <ReplenishmentItemLabels
              replenishment={replenishment}
              fetchReplenishment={(): Promise<void> => fetchReplenishment()}
            />
          )}

        {replenishment &&
          isConfirmed &&
          replenishment.approved &&
          !replenishment.hasLabels &&
          !replenishment.inLastStep &&
          checkUserRoles(['admin', 'manager']) && (
            <>
              <Spin style={{ marginRight: 10 }} />
              <Text>
                Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to confirm
                labels
              </Text>
            </>
          )}

        {replenishment &&
          replenishment.approved &&
          replenishment.hasLabels &&
          belongsToBrand &&
          !replenishment.shipmentPlan && (
            <ShippingPlanForm
              fetchReplenishment={(): Promise<void> => fetchReplenishment()}
              replenishment={replenishment}
              history={history}
            />
          )}

        {replenishment &&
          replenishment.approved &&
          replenishment.hasLabels &&
          belongsToBrand &&
          replenishment.shipmentPlan &&
          !shipmentsSent && (
            <>
              <DisplayShipments
                replenishment={replenishment}
                fetchReplenishment={(): Promise<void> => fetchReplenishment()}
              />
            </>
          )}

        {replenishment && shipmentsSent && belongsToBrand && !submittedAllShipmentPhotos && (
          <>
            <BoxesDisplayTable replenishment={replenishment} />
            <Divider />
            {Object.keys(replenishment.shipments).map(
              (shipmentId: any, index: number): JSX.Element | null => {
                if (replenishment.shipments[shipmentId].photosApproved) return null;
                if (
                  replenishment.shipments[shipmentId].isHazmat &&
                  replenishment.shipments[shipmentId].submittedTrackingNumbers !== true
                ) {
                  return (
                    <HazmatTrackingNumbers
                      replenishment={replenishment}
                      fetchReplenishment={(): Promise<void> => fetchReplenishment()}
                      shipmentId={shipmentId}
                    />
                  );
                }
                return (
                  <>
                    {index === 0 && (
                      <>
                        <Alert
                          style={{ marginBottom: 15, marginTop: 15 }}
                          type="error"
                          showIcon
                          message={
                            <Text strong>
                              ** IMPORTANT ** DO NOT APPLY FBA LABELS ACROSS BOX SEAMS, CAN RESULT
                              IN AMAZON LOSING YOUR INVENTORY
                            </Text>
                          }
                        />
                        <Alert
                          style={{ marginTop: 15, marginBottom: 15 }}
                          type="info"
                          showIcon
                          message="Please print and apply labels to the correct cartons then photograph and upload pictures for approval for each shipment."
                        />
                      </>
                    )}
                    <BoxPictureForm
                      key={shipmentId}
                      replenishment={replenishment}
                      fetchReplenishment={(): Promise<void> => fetchReplenishment()}
                      shipmentId={shipmentId}
                    />
                  </>
                );
              },
            )}
          </>
        )}

        {replenishment &&
          replenishment.hasLabels &&
          replenishment.approved &&
          !submittedAllShipmentPhotos &&
          !replenishment.inLastStep &&
          checkUserRoles(['admin', 'manager']) && (
            <>
              <Spin style={{ marginRight: 10 }} />
              <Text>
                Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to give
                logistics information
              </Text>
            </>
          )}
        {replenishment &&
          !belongsToBrand &&
          shipmentsSent &&
          submittedOneShipmentPhotos &&
          !allPhotosWereApproved && (
            <>
              <Title style={{ fontSize: 24 }}>Shipment boxes information</Title>
              <BoxesDisplayTable replenishment={replenishment} />
              <DisplayShipmentsPhotos
                replenishment={replenishment}
                fetchReplenishment={(): Promise<void> => fetchReplenishment()}
              />
            </>
          )}

        {replenishment && submittedAllShipmentPhotos && !allPhotosWereApproved && belongsToBrand && (
          <>
            <Title style={{ fontSize: 24 }}>Shipment boxes information</Title>
            <BoxesDisplayTable replenishment={replenishment} />
            <div style={{ marginTop: 20 }}>
              <Spin style={{ marginRight: 10 }} /> <Text>Waiting for Sunken Stone approval</Text>
            </div>
          </>
        )}
      </>
    );
  }

  const links = [
    createLink('Replenishment Requests', ''),
    createLink('List', 'replenishment-requests'),
    createLink(replenishment ? replenishment.id : '', ''),
  ];

  return (
    <>
      <BreadcrumbComposer items={links} />

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        {replenishment && replenishment.paused ? (
          <PausedReplenishmentContent
            fetchReplenishment={(): Promise<void> => fetchReplenishment()}
            replenishment={replenishment}
            history={history}
          />
        ) : (
          content
        )}
      </div>

      {replenishment && (
        <div style={{ padding: 24, background: '#fff', minHeight: 360, marginTop: 40 }}>
          <ReplenishmentComments
            replenishment={replenishment}
            fetchReplenishment={(): Promise<void> => fetchReplenishment()}
          />
        </div>
      )}
    </>
  );
}
