import React from 'react';
import { Input, Typography } from 'antd';

const { Text } = Typography;

interface EditableTextProps {
  editable: boolean;
  value?: string;
  onChangeText: (text: string) => void;
}

export default function EditableText(props: EditableTextProps): JSX.Element {
  const { editable, value, onChangeText } = props;
  let content;
  if (editable) {
    content = <Input value={value} onChange={(e): void => onChangeText(e.target.value)} />;
  } else if (!value) {
    content = (
      <Text type="secondary" style={{ fontStyle: 'italic', fontSize: 12 }}>
        Empty
      </Text>
    );
  } else {
    content = <Text>{value}</Text>;
  }
  return <div style={{ minWidth: 150 }}>{content}</div>;
}
