import React, { useState, FormEvent } from 'react';
import { Alert, Card, Button, Input, Icon, Tooltip, Form, Row, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
// utils
import Api from '../../../utils/api';
import { setDataEncrypt, getDataDecrypt } from '../../../utils/encryption';
// assets
import Logo from '../../../assets/logo.png';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginPageProps extends FormComponentProps {}

function LoginPage(props: LoginPageProps): JSX.Element {
  const { validateFields, getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (getDataDecrypt('access')) {
    return <Redirect to="/app/dashboard" />;
  }

  function submitLogin(e: FormEvent): void {
    e.preventDefault();

    validateFields(
      async (err: any, values: { email: string; password: string }): Promise<any> => {
        if (!err) {
          setLoading(true);
          setError(null);

          try {
            const {
              data: { accessToken, user },
            } = await Api.post('/auth/login', {
              email: values.email.toLowerCase(),
              password: values.password,
            });
            setDataEncrypt('access', accessToken);
            setDataEncrypt('user', user);
            window.location.reload();
          } catch (e) {
            if (e.response && e.response.data) {
              setError(e.response.data.message);
            } else {
              setError('Something went wrong. Please try again!');
            }
            setLoading(false);
          }
        }
      },
    );
  }
  return (
    <div className="p-4">
      <Card title="Login">
        <Row type="flex" justify="center" className="mb-4">
          <img src={Logo} alt="Logo" />
        </Row>
        {error ? (
          <Alert
            message="Oops!"
            description={error}
            type="error"
            closable
            onClose={(): void => setError(null)}
          />
        ) : null}
        {loading ? (
          <Row type="flex" justify="center" className="py-4">
            <Spin size="large" />
          </Row>
        ) : (
          <Form onSubmit={submitLogin}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder="Enter your email"
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  suffix={
                    <Tooltip title="Remember to use your work email">
                      <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  }
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true }],
              })(
                <Input.Password
                  placeholder="Enter your password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />,
              )}
            </Form.Item>
            <Row type="flex" justify="center">
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Row>
          </Form>
        )}
      </Card>
    </div>
  );
}

const WrappedNormalLoginForm = Form.create({ name: 'login' })(LoginPage);
export default WrappedNormalLoginForm;
