import React, { useState } from 'react';
import { message, Switch, Typography } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../../utils/api';
// components
import Spacer from '../../../components/spacer';

const { Text } = Typography;

interface ReplenishmentReminderSwitchProps {
  replenishment: ReplenishmentRequest;
}

function ReplenishmentReminderSwitch({
  replenishment,
}: ReplenishmentReminderSwitchProps): JSX.Element {
  const [checked, setChecked] = useState(replenishment.shouldRemind === true);
  const [loading, setLoading] = useState(false);

  async function toggleShouldRemind(checked: boolean): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.put(`/replenishments/${replenishment && replenishment._id}`, {
        shouldRemind: checked,
      });
      setChecked(checked);
      message.success(`Replenishment Request email reminders turned ${checked ? 'on' : 'off'}!`);
    } catch (e) {
      message.error(
        'There was an error updating the replenishment request. Please try again later',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <Switch
        defaultChecked
        checked={checked}
        onChange={(checked): Promise<void> => toggleShouldRemind(checked)}
        loading={loading}
      />
      <Spacer width={10} />
      <Text>Send reminder emails every 2 days</Text>
    </div>
  );
}

export default ReplenishmentReminderSwitch;
