import React, { useEffect, useState } from 'react';
import { Comment, Tooltip, List, Typography, Tag } from 'antd';
import moment from 'moment';
// interface
import {
  ReplenishmentRequest,
  ReplenishmentComment,
} from '../../../../interfaces/replenishment-request';
// components
import AddComment from './add-comment';

const { Title, Paragraph, Text } = Typography;

interface ReplenishmentCommentsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: () => void;
}

export default function ReplenishmentComments({
  replenishment,
  fetchReplenishment,
}: ReplenishmentCommentsProps): JSX.Element {
  const [comments, setComments] = useState<ReplenishmentComment[]>([]);

  useEffect((): void => {
    if (replenishment.comments) setComments(replenishment.comments.reverse());
  }, [replenishment]);

  return (
    <div id="comments">
      <Title style={{ fontSize: 24 }}>Comments section</Title>
      <List
        className="comment-list"
        header={`${comments.length} comments`}
        itemLayout="horizontal"
        locale={{ emptyText: 'No Comments' }}
        dataSource={comments}
        renderItem={(comment): JSX.Element => (
          <li>
            <Comment
              avatar={
                comment.escalation && (
                  <Tag color="red">
                    <Text strong>ESCALATED</Text>
                  </Tag>
                )
              }
              author={
                <>
                  <Text strong style={{ marginRight: 7, color: 'black' }}>
                    {comment.user.name}
                  </Text>
                  {comment.user.brand ? (
                    <Tag color="blue">Brand Manager</Tag>
                  ) : (
                    <Tag color="gold">Sunken Stone Manager</Tag>
                  )}
                </>
              }
              content={<Paragraph>{comment.message}</Paragraph>}
              datetime={
                <Tooltip title={moment(comment.datetime).format('YYYY-MM-DD HH:mm:ss')}>
                  <Text>{moment(comment.datetime).fromNow()}</Text>
                </Tooltip>
              }
            />
          </li>
        )}
      />
      <Comment
        content={
          <AddComment fetchReplenishment={fetchReplenishment} replenishment={replenishment} />
        }
      />
    </div>
  );
}
