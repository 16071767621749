import CryptoJS from 'crypto-js';

// these are helper functions to encrypt/decrypt and store/retrieve
// from localStorage
export function setDataEncrypt(key: string, data: any): void {
  const dataSecret = CryptoJS.AES.encrypt(JSON.stringify(data), 'ss-priv').toString();
  localStorage.setItem(key, dataSecret);
}

export function getDataDecrypt(key: string): any {
  const dataEncode = localStorage.getItem(key);
  if (!dataEncode) {
    return false;
  }
  const bytesProfile = CryptoJS.AES.decrypt(dataEncode, 'ss-priv');
  return JSON.parse(bytesProfile.toString(CryptoJS.enc.Utf8));
}
