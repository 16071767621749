import React, { useEffect, useState } from 'react';
import {
  Table,
  message,
  Typography,
  Button,
  Popconfirm,
  Input,
  Icon,
  Switch,
  Tag,
  Row,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { ApiWithToken } from '../../utils/api';
import { addIdKeyToList, checkUserBelongsToRoles } from '../../utils';
// components
import BreadcrumbComposer, { createLink } from '../../components/breadcrumb-composer';
// interfaces
import { ReplenishmentRequest } from '../../interfaces/replenishment-request';
import { FilterData, PaginationData, SorterData } from '../../interfaces/table-filters.interface';

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// interface ReplenishmentRequestsPageProps extends RouteComponentProps {}

const checkUserRole = checkUserBelongsToRoles();

export default function ReplenishmentRequestsPage(): JSX.Element {
  const [replenishments, setReplenishmentRequests] = useState<ReplenishmentRequest[]>([]);
  const [showFinished, setShowFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalReplenishments, setTotalReplenishments] = useState(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState('');

  async function getAllReplenishmentRequests(): Promise<any> {
    setLoading(true);
    const query = [`page=${currentPage}`, `pageSize=${pageSize}`];

    if (extraQueries.length > 0) {
      query.push(...extraQueries);
    }

    if (searchText && searchText.length > 0 && searchField.length > 0) {
      query.push(`searchBy=${searchField}`, `searchFor=${searchText}`);
    }

    if (showFinished) {
      query.push('finished=true');
    }

    try {
      const { data } = await ApiWithToken.get('/replenishments?' + query.join('&'));
      const replenishments = addIdKeyToList(data.data);
      setReplenishmentRequests(replenishments);
      setTotalReplenishments(data.pagination.total);
    } catch (e) {
      message.error(
        'An error occurred fetching the replenishment requests. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }

  async function deleteReplenishment(replenishmentId: string): Promise<void> {
    setLoading(true);
    try {
      await ApiWithToken.delete(`/replenishments/${replenishmentId}`);
      message.success('Replenishment request deleted successfully!');
      getAllReplenishmentRequests();
    } catch (e) {
      message.error(
        'There was an error deleting the replenishment request. Please try again later',
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect((): void => {
    getAllReplenishmentRequests();
  }, [currentPage, pageSize, extraQueries, searchText, searchField, showFinished]);

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any): void {
    setSearchText(selectedKeys[0]);
    setSearchField(dataIndex);
    setCurrentPage(1);
    confirm();
  }

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any): JSX.Element => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder="Search here"
          value={selectedKeys[0]}
          onChange={(e): void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          size="small"
          style={{ width: 90 }}
          onClick={(): void => {
            clearFilters();
            setCurrentPage(1);
            setSearchField('');
            setSearchText('');
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any): boolean =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: (text: string): JSX.Element => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  async function handleSorter(
    pagination: PaginationData,
    filters: FilterData,
    sorter: SorterData,
  ): Promise<void> {
    const newQuery = [];

    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

    // Set the sort query
    if (sorter.field && sorter.order) {
      newQuery.push(`sortBy=${sorter.field}`, `sortOrder=${sorter.order}`);
    }

    setExtraQueries(newQuery);
  }

  const links = [createLink('Replenishment Requests', ''), createLink('List', '')];

  return (
    <>
      <BreadcrumbComposer items={links} />
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <h1>All Replenishment Requests</h1>
        <Row>
          <Switch
            style={{ marginRight: 10 }}
            checked={showFinished}
            onChange={(newVal): void => {
              setCurrentPage(1);
              setShowFinished(newVal);
            }}
          />
          <Text>Show finished replenishments</Text>
        </Row>
        <>
          <Table
            scroll={{ x: 'scroll' }}
            onChange={handleSorter}
            loading={loading}
            pagination={{
              current: currentPage,
              size: pageSize.toString(),
              showTotal: (total: number): string => `${total} replenishment requests`,
              total: totalReplenishments,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
              onShowSizeChange: (page, size): void => setPageSize(size),
            }}
            dataSource={replenishments}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                ...getColumnSearchProps('id'),
                render: (text, record): JSX.Element => (
                  <Link to={`/app/replenishment-requests/view/${record._id}`}>
                    <span>{text}</span>
                  </Link>
                ),
              },
              {
                title: 'Requested by',
                dataIndex: 'user.name',
                key: 'userName',
                sorter: true,
                render: (text, record): JSX.Element =>
                  checkUserRole(['admin', 'manager']) ? (
                    <Link to={`/app/users/view/${record._id}`}>
                      <span>{record.user.name}</span>
                    </Link>
                  ) : (
                    <span>{record.user.name}</span>
                  ),
              },
              (checkUserRole(['manager', 'admin']) && {
                title: 'Brand',
                dataIndex: 'brand.name',
                key: 'brandName',
                sorter: true,
                render: (text, record): JSX.Element => (
                  <Link to={`/app/brands/view/${record.brand.code}`}>
                    <span>{record.brand.name}</span>
                  </Link>
                ),
              }) ||
                {},
              {
                title: 'Status',
                render: (record): JSX.Element => (
                  <Tag color={record.finished && 'green'}>
                    {record.finished ? 'Finished' : 'In Progress'}
                  </Tag>
                ),
              },
              {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                sorter: true,
                render: (text, record): JSX.Element => (
                  <Text>{moment(record.created_at).format('DD-MM-YYYY')}</Text>
                ),
              },
              ...((checkUserRole(['admin']) && [
                {
                  title: 'Actions',
                  render: (record: ReplenishmentRequest): JSX.Element => (
                    <Popconfirm
                      title="Are you sure you want to delete this replenishment request?"
                      onConfirm={(): Promise<void> => deleteReplenishment(record._id)}
                    >
                      <Button size="small" type="danger">
                        Delete
                      </Button>
                    </Popconfirm>
                  ),
                },
              ]) ||
                []),
            ]}
          />
        </>
      </div>
    </>
  );
}
