import React, { useState, FormEvent } from 'react';
import { Typography, Form, message, Input, Row, Button, Alert, Collapse, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// utils
import { ApiWithToken } from '../../../../utils/api';
import { scrollToTop } from '../';

const { Text } = Typography;
const { Panel } = Collapse;

interface HazmatProNumberProps extends FormComponentProps {
  replenishment: ReplenishmentRequest;
  shipmentId: any;
  fetchReplenishment: () => Promise<void>;
}

function HazmatProNumber({
  replenishment,
  shipmentId,
  form,
  fetchReplenishment,
}: HazmatProNumberProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const { getFieldDecorator } = form;

  async function updateProNumbers(e: FormEvent): Promise<void> {
    e.preventDefault();
    setProcessing(true);
    const { validateFields } = form;
    validateFields(
      async (err, values): Promise<void> => {
        if (err) return;

        const { shipmentProNumber } = values;

        try {
          await ApiWithToken.post(
            `/replenishments/${replenishment._id}/shipments/${shipmentId}/pro-number`,
            {
              proNumber: shipmentProNumber,
            },
          );

          fetchReplenishment();
          scrollToTop();
          message.success('The Pro Number was successfully saved!');
        } catch (e) {
          message.error(
            (e.response && e.response.data.message) || 'Something went wrong. Please try again.',
          );
        } finally {
          setProcessing(false);
        }
      },
    );
  }

  return (
    <Collapse
      key={shipmentId}
      expandIcon={({ isActive }): JSX.Element => (
        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      )}
      style={{ marginTop: 20 }}
    >
      <Panel header={`Pro Number for shipment ${shipmentId}`} key={shipmentId}>
        <Form onSubmit={updateProNumbers}>
          <Alert
            type="warning"
            showIcon
            message={<Text strong>Please enter the Pro Number for your shipment</Text>}
            style={{ marginBottom: 20, marginTop: 20 }}
          />

          <Form.Item label={`Pro Number for shipment ${shipmentId}`}>
            {getFieldDecorator(`shipmentProNumber`, {
              rules: [{ required: true, message: 'The Pro Number is required' }],
            })(<Input style={{ width: 200 }} />)}
          </Form.Item>

          <Row type="flex" justify="center">
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginTop: 20 }}
              disabled={processing}
              loading={processing}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

export default Form.create<HazmatProNumberProps>({ name: 'hazmat-pro-form' })(HazmatProNumber);
