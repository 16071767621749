import React, { useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
// interfaces
import { ReplenishmentRequest } from '../../../interfaces/replenishment-request';
import { ApiWithToken } from '../../../utils/api';

interface EditShipmentPropertyModalProps {
  visible: boolean;
  onSuccess: () => void;
  onClose: () => void;
  shipmentId: string;
  property: string;
  replenishment: ReplenishmentRequest;
  label: string;
  propertyType: 'boolean' | 'string';
}

function EditShipmentPropertyModal({
  visible,
  onClose,
  shipmentId,
  property,
  replenishment,
  label,
  onSuccess,
  propertyType,
}: EditShipmentPropertyModalProps): JSX.Element | null {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<any>('');

  if (!shipmentId || !property) return null;

  const shipmentData = replenishment.shipments[shipmentId];
  const currentValue = shipmentData ? shipmentData[property] : '';

  function labelLinkConverter(labelUrl: string): string {
    const id = labelUrl.split('/file/d/')[1].split('/')[0];
    return `https://drive.google.com/u/0/uc?id=${id}&export=download`;
  }

  async function handleSave(): Promise<void> {
    setLoading(true);

    let valueToSend = value;

    if (propertyType === 'boolean') {
      valueToSend = value === 'true';
    }

    if (typeof valueToSend === 'string' && valueToSend.length > 0) {
      switch (property) {
        case 'fbaLabels':
          valueToSend = labelLinkConverter(value);
          break;
        case 'upsLabels':
          valueToSend = labelLinkConverter(value);
          break;
        case 'palletLabels':
          valueToSend = labelLinkConverter(value);
          break;
      }
    }

    try {
      await ApiWithToken.patch(`/replenishments/${replenishment._id}/shipments/${shipmentId}`, {
        [property]: valueToSend,
      });
      message.success('Shipment updated successfully');
      onSuccess && onSuccess();
      onClose();
    } catch (e) {
      message.error('Something went wrong updating the shipment. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  function buildInput(): JSX.Element {
    switch (propertyType) {
      case 'boolean':
        return (
          <Form.Item>
            <Select
              style={{ width: 200 }}
              defaultValue={(currentValue === true).toString()}
              onChange={(value: string): void => setValue(value)}
            >
              <Select.Option value="true">true</Select.Option>
              <Select.Option value="false">false</Select.Option>
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={label}>
            <Input
              defaultValue={currentValue || ''}
              onChange={(e): void => setValue(e.target.value)}
            />
          </Form.Item>
        );
    }
  }

  return (
    <Modal
      title="Edit shipment"
      visible={visible}
      onCancel={onClose}
      destroyOnClose={true}
      confirmLoading={loading}
      onOk={handleSave}
    >
      {buildInput()}
    </Modal>
  );
}

export default EditShipmentPropertyModal;
