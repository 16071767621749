import React from 'react';
import { Tag } from 'antd';

interface RolesTagsProps {
  roles: string[];
}

export default function RolesTags({ roles }: RolesTagsProps): JSX.Element {
  return (
    <React.Fragment>
      {roles.map(
        (role): JSX.Element => (
          <Tag key={role}>{role}</Tag>
        ),
      )}
    </React.Fragment>
  );
}
