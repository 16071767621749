import React from 'react';
import ReactDOM from 'react-dom';
import App from './Main';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://3c3291c2c2df4c6fad4407f2894b8e35@o526149.ingest.sentry.io/5677583',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: 'po-portal-frontend',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
